import React, { MouseEventHandler } from "react";

type ButtonType = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  classes?: string;
  icon?: any;
  label: string;
};

export const Button = ({ onClick, type, icon, classes, label }: ButtonType) => {
  return (
    <button
      type={type ? type : "button"}
      className={`bg-gray-800 hover:bg-gray-200 text-white hover:text-gray-600 transition duration-150 rounded-[12px] px-4 py-2 font-medium flex items-center gap-1 ${classes}`}
      onClick={onClick ? onClick : undefined}
    >
      <span className="inline-flex">{icon}</span>
      {label}
    </button>
  );
};
