export const inputTypes = [
  {
    id: "input",
    name: "Tekst",
  },
  {
    id: "select",
    name: "Pojedynczy wybór",
  },
  {
    id: "Multiselect",
    name: "Wielokrotny wybór",
  },
];
