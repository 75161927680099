import { useEffect, useState } from "react";
import { getTextiles } from "../../../api";

export const useTextiles = () => {
  const [textiles, setTextiles] = useState<any[]>([]);

  const fetch = () =>
    !textiles.length &&
    getTextiles()
      .then((res: any[]) => {
        setTextiles(res);
      })
      .catch((e: any) => console.error(e));
  useEffect(() => {
    fetch();
  }, []);

  return { textiles, refetch: fetch() };
};
