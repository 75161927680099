import React, { useState } from "react";
import { HiOutlineArchiveBoxXMark } from "react-icons/hi2";
import { DeleteModal } from "../../DeleteModal/DeleteProductVariant";

type ProductVariantRowProps = {
  productVariant: any;
  refetchProduct: () => void;
};

export const ProductVariantRow = ({
  productVariant,
  refetchProduct,
}: ProductVariantRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="h-14 rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-8 gap-2 leading-8 text-gray-400">
      <div className="col-span-4 py-2">{productVariant.sku}</div>

      <div className="col-span-7 py-2 hidden group-hover:flex justify-end items-end">
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleModal}
        />
      </div>
      {productVariant.id && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          name={productVariant.sku}
          id={productVariant.id}
          refetchProduct={refetchProduct}
        />
      )}
    </div>
  );
};
