import { Order } from "../../types/Order";
import moment from "moment";
import { Package } from "../../types/Package";

export const printUaLabel = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package,
  textileName: string,
  paramValue: string,
  orderIndex: string
) => {
  const product = order.product;
  const kw = moment(order.createdAt, "YYYYMMDD").isoWeek();
  const deliveryYear = moment(order.createdAt, "YYYYMMDD").year();

  // Header
  // Nazwa produktu
  doc.setFont("PTSans", "normal", "normal");
  doc.setFontSize(12);
  doc.text(`Наименование изделия: ${product?.name}`, 10, 15);
  doc.text(`Модель: ${product?.name}`, 10, 20);
  doc.text(textileName, 10, 25);

  // Body
  // Material
  doc.setFontSize(10);
  doc.text("Товарный знак: «SAGAN»", 10, 45);
  doc.text("Наименование страны изготовителя: ПОЛЬША ", 10, 50);
  doc.text("Изготовитель: «SAGAN FM» Sp. K.", 10, 55);
  doc.text("Изготовитель: «SAGAN FM» Sp. K.", 10, 60);
  doc.text("Адрес: 63-600, POLAND, Кępno, ul. Poznańska 6, ПОЛЬША", 10, 65);
  // data produkcji
  doc.text(`Дата изготовления: ${kw}/${deliveryYear}`, 10, 70);
  doc.text("Гарантийный срок: 1 год.", 10, 75);
  doc.text("Срок службы, установленный изготовителем: 2 года.", 10, 80);

  // Footer
  // Paczka
  doc.setFont("IBMPlexSans-Bold", "normal", "normal");
  doc.setFontSize(24);
  doc.text(`PART: ${orderIndex}`, 10, 100);
  doc.text(`/ ${parcelIndex + 1}/${packagesQty}`, 40, 100);
  // Tydzien dostawy
  doc.text(`KW ${kw}/${deliveryYear}`, 110, 100);

  doc.addPage();
};
