import React, { FC } from "react";
import { Param as ParamType } from "../../types/Param";

type ParamRowType = {
  index: string;
  id: number;
  onChange: any;
  checkedParams: boolean;
  param: ParamType;
};

export const ParamRow: FC<ParamRowType> = ({
  index,
  id,
  onChange,
  checkedParams,
  param,
}) => {
  return (
    <div>
      <input
        className="h-5 w-5 rounded border-gray-200 mr-2 checked:bg-gray-900 checked:hover:bg-gray-500 checked:focus:bg-gray-500"
        type="checkbox"
        name="checkedParams"
        checked={checkedParams}
        id={`row${index}`}
        value={id}
        onChange={onChange}
      />
      <label
        htmlFor={`row${index}`}
        className={
          checkedParams
            ? "font-semibold text-gray-900"
            : "font-normal text-gray-500"
        }
      >
        {param.name}
      </label>
    </div>
  );
};
