import React, { useContext } from "react";
import moment from "moment";
import { Order } from "../../../../types/Order";
import { OrdersContext } from "../../contexts/OrdersContext";
import { OrderOverview } from "../OrderOverview";
import { StatusBadge } from "./StatusBadge";
import { Link } from "react-router-dom";
import { useStatusOptionsStore } from "../../../../stores/useStatusOptionsStore";
import { useUsersStore } from "../../../../stores/useUsersStore";
import { UserRoleNames } from "../../../../types/User";
import { useUserStore } from "../../../../stores/useUserStore";

type OrderRowProps = {
  order: Order;
};

export const OrdersRow = ({ order }: OrderRowProps) => {
  const { selectedOrders, setSelectedOrders } = useContext(OrdersContext);
  const isChecked = !!selectedOrders.find(({ id }) => id === order.id);
  const kw = moment(order.deliveryAt, "YYYMMDD").isoWeek() || "-";
  const { selectedOption } = useStatusOptionsStore();
  const { role } = useUserStore();
  const { users } = useUsersStore();

  const handleClick = () => {
    if (isChecked) {
      const updateOrders = selectedOrders.filter(
        (selectedOrder) => selectedOrder.id !== order.id
      );
      setSelectedOrders(updateOrders);
    } else {
      setSelectedOrders([
        ...selectedOrders,
        {
          ...order,
          labelTemplate: users.find((user) => user.id === order.userId)
            ?.labelTemplate,
        },
      ]);
    }
  };

  return (
    <div
      className={`h-14 rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-cols-${
        !role.includes(UserRoleNames.CUSTOMER) ? 10 : 6
      } gap-2 leading-8 text-gray-400`}
    >
      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-1 py-2 font-bold text-black">
          <div className="flex items-center h-full align-middle">
            {selectedOption !== "deleted" && (
              <input
                id="vue-checkbox"
                type="checkbox"
                value=""
                onChange={() => {}}
                onClick={handleClick}
                checked={isChecked}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
            )}
          </div>
        </div>
      )}
      <div className="col-span-2 py-2 font-bold text-black">
        {selectedOption !== "deleted" ? (
          <Link className="hover:text-gray-800" to={`/orders/${order.id}`}>
            {order.poNumber}
          </Link>
        ) : (
          <span>{order.poNumber}</span>
        )}
      </div>
      <div className="col-span-1 py-2">
        {moment(order.createdAt).format("DD-MM-YYYY")}
      </div>
      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-2 py-2">{kw}</div>
      )}
      <div className="col-span-1 py-2">
        {order.quantity} {order.unit}
      </div>
      <StatusBadge id={order.statusId} />
      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-1 py-2">{order.countPrints}</div>
      )}
      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-1 my-auto hidden group-hover:flex justify-end items-end">
          {selectedOption !== "deleted" && <OrderOverview id={order.id} />}
        </div>
      )}
    </div>
  );
};
