import { Layout } from "../../layout/Layout";
import { UserForm } from "../users/components/UserSidebar/UserForm";
import React, { useEffect } from "react";
import { useUser } from "../orders/api/useUser";
import { useUserStore } from "../../stores/useUserStore";
import { useNavigate } from "react-router-dom";
import { UserRoleNames } from "../../types/User";

export const EditProfile = () => {
  const { fetch, user } = useUser();
  const { id } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    id && fetch(id);
  }, []);

  const isCustomerUpdatingOwnProfile = user?.roles
    ?.map((role) => role.name)
    .includes(UserRoleNames.CUSTOMER);

  if (!user) {
    return (
      <Layout>
        <div>Wczytywanie...</div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="max-w-2xl">
        <UserForm
          isCustomerUpdatingOwnProfile={isCustomerUpdatingOwnProfile}
          user={user}
          refetch={() => {
            navigate(0);
          }}
        />
      </div>
    </Layout>
  );
};
