import { ReactComponent as Xmark } from "./icons/xmark.svg";
import { Error } from "./icons/Error";
import React, { ReactNode, useContext } from "react";
import { ToastMessageContext } from "../index";
import { ToastType } from "../../../types";
import { Success } from "./icons/Success";
import { Warning } from "./icons/Warning";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const icons: Record<ToastType, ReactNode> = {
  success: <Success />,
  error: <Error />,
  warning: <Warning />,
};

export const ToastMessage = () => {
  const {
    toastMessage: { message, type, data },
    handleClose,
  } = useContext(ToastMessageContext);

  if (!message) {
    return <div />;
  }

  return (
    <div
      id={`toast-${type}`}
      className="fixed z-[9999] top-5 right-5 flex gap-1 w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      {icons[type]}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="ml-3 text-sm font-bold">{message}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="ml-3 text-sm font-normal">
            {data?.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>

      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target={`#toast-${type}`}
        onClick={handleClose}
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <Xmark />
      </button>
    </div>
  );
};
