import React from "react";
import { Product } from "../../types/Product";
import { Link } from "react-router-dom";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";

type ProductRowProps = {
  product: Product;
  handleEditInSidebar: (product: Product) => void;
  setModalObject: (product: Product) => void;
  setIsModalOpen: (isOpen: boolean) => void;
};

export const ProductRow = ({
  product,
  handleEditInSidebar,
  setModalObject,
  setIsModalOpen,
}: ProductRowProps) => {
  const handleModal = () => {
    setIsModalOpen(true);
    setModalObject(product);
  };
  return (
    <div className="h-[52px] rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-7 gap-2 leading-8 text-gray-400">
      <div className="col-span-2 my-auto">
        <Link
          to={`/products/${product.id}`}
          className="font-medium text-gray-900"
        >
          {product.name}
        </Link>
      </div>
      <div className="col-span-1 my-auto">{product.sku}</div>
      <div className="col-span-1 my-auto">{product.textileQty}</div>
      <div className="col-span-3 my-auto hidden group-hover:flex justify-end items-end">
        <HiOutlinePencil
          className="h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={() => handleEditInSidebar(product)}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleModal}
        />
      </div>
    </div>
  );
};
