import { deleteUser as deleteItem } from "../../../api";

export const useDeleteUser = (
  refetch: any
): {
  deleteUser: (id: number) => void;
} => {
  const deleteUser = (id: number) => {
    deleteItem(id)
      .then(() => {
        refetch();
      })
      .catch((e: any) => console.error(e));
  };

  return { deleteUser };
};
