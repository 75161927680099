import { useContext } from "react";
import { updateUser as updateItem } from "../../../api";

import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

type UseUpdateUserType = {
  isReload?: boolean;
};
export const useUpdateUser = ({
  isReload,
}: UseUpdateUserType): {
  updateUser: (user: any, id: number) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(UserContext);
  const navigate = useNavigate();
  const updateUser = (user: any, id: number) => {
    updateItem({ ...user, id })
      .then(() => {
        setIsSidebarOpen(false);
      })
      .then(() => {
        refetch();
      })
      .then(() => {
        isReload && navigate(0);
      })
      .catch((e: any) => console.error(e));
  };

  return { updateUser };
};
