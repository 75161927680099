import { useEffect, useState } from "react";
import { getCollections } from "../../../api";

export const useCollections = () => {
  const [collections, setCollections] = useState<any[]>([]);
  const fetch = () =>
    getCollections()
      .then((res) =>
        setCollections(
          res &&
            res.map((item) => {
              const { shippingAt, ...rest } = item;
              return {
                ...rest,
                shipping_at: shippingAt,
              };
            })
        )
      )
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { collections, refetch: fetch };
};
