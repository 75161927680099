import { useState } from "react";
import { getOrder } from "../../../api";
import { Order } from "../../../types/Order";

export const useOrder = () => {
  const [order, setOrder] = useState<Order>();
  const fetch = (id: number) =>
    getOrder(id)
      .then((res: Order) => {
        setOrder(res);
      })
      .catch((e: any) => console.error(e));

  return { order, fetch };
};
