import React, { useContext } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { CollectionContext } from "../../contexts/CollectionContext";
import { CollectionForm } from "./CollectionForm";
import { ProductVariantTable } from "../ProductVariantTable/CollectionTable";
import moment from "moment";

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen, editCollection, setEditCollection } =
    useContext(CollectionContext);

  const handleCloseBtn = () => {
    setEditCollection({
      sku: "",
      shipping_at: moment().format("YYYY-MM-DD"),
      id: undefined,
    });
    setIsSidebarOpen(false);
  };
  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div className="p-10 pl-9">
          <div>
            <HiOutlineXMark
              className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
              onClick={handleCloseBtn}
            />
          </div>
          <CollectionForm collection={editCollection} />
          <ProductVariantTable />
        </div>
      </div>
    </div>
  );
};
