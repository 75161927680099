import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { ImportDatFile } from "./ImportDatFile";
import { useMercuryUploadStore } from "../../../../stores/useMercuryUploadStore";
import { CollectionOrdersValidationForm } from "./CollectionOrdersValidationForm";
import { useFormik } from "formik";
import { useUserOption } from "../../api/useUserOptions";
import { CollectionOrderDetailsForm } from "./CollectionOrderDetailsForm";
import { useUserStore } from "../../../../stores/useUserStore";
import { useCollectionOrderStore } from "../../../../stores/useCollectionOrderStore";
import { useCollection } from "../../../collections/api/useCollection";
import { CollectionOrdersFormik } from "./types";
import { useCreateOrders } from "../../api/useCreateOrders";

const steps = ["Kolekcja", "Zwaliduj dane", "Dodatkowe informacje"];

export const CollectionStepper = () => {
  const store = useCollectionOrderStore();
  const { userOptions } = useUserOption();
  const { id } = useUserStore();
  const { createOrders } = useCreateOrders();
  const { fetch, collection } = useCollection();
  useEffect(() => {
    fetch(store.collectionId);
    console.log(collection);
  }, [store.collectionId]);

  const formik = useFormik<CollectionOrdersFormik>({
    initialValues: {
      orders:
        collection?.productVariants.map((product) => {
          return {
            product_id: product.product.id,
            sku: product.sku,
            quantity: product.product.productionMinimum,
          };
        }) || [],
      po_number: collection?.sku || "",
      user: userOptions.find((user) => user.value == id),
    },
    onSubmit: (values) => {
      const newOrders = values.orders.map((order) => {
        const product = collection?.productVariants.find(
          (variant) => variant.productId === order.product_id
        );
        return {
          user_id: values.user?.value,
          po_number: values.po_number,
          product_id: order.product_id,
          quantity: order.quantity,
          textile_id: product.textile.id,
          order_type_id: 1,
          param_values: product.paramValues.reduce(
            (a: any, v: any) => ({ ...a, [v.id]: v.value }),
            {}
          ),
        };
      });
      createOrders(newOrders);
    },
    enableReinitialize: true,
  });
  const setActiveStep = (step: number) =>
    useMercuryUploadStore.setState({ ...store, currentStep: step });

  const handleNext = () => {
    if (store.currentStep === 2) {
      formik.submitForm();
    }
    useCollectionOrderStore.setState({
      ...store,
      isNextStepBlocked: true,
      currentStep: store.currentStep + 1,
    });
  };

  const handleBack = () => {
    setActiveStep(store.currentStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={store.currentStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {store.currentStep < steps.length && (
        <React.Fragment>
          <Box>
            {store.currentStep === 0 && <ImportDatFile />}
            {store.currentStep === 1 && (
              <CollectionOrdersValidationForm formik={formik} />
            )}
            {store.currentStep === 2 && (
              <CollectionOrderDetailsForm formik={formik} />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={store.currentStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              onClick={handleNext}
              disabled={store.isNextStepBlocked && store.currentStep === 0}
            >
              {store.currentStep === steps.length - 1 ? "Zapisz" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
