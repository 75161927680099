import { Form, Formik } from "formik";
import React, { useState } from "react";
import { MercuryOrder } from "../../../../types";
import { useCollectionOptions } from "../../api/useCollectionOptions";
import { InputSelect } from "../../../../components/InputSelect";
import { Option } from "../../types";
import { useCollectionOrderStore } from "../../../../stores/useCollectionOrderStore";

// TODO: Po wybraniu pliku odblokuje sie przucisk dalej w steperze

export const ImportDatFile = () => {
  const { collectionOptions } = useCollectionOptions();

  const collectionOrderStore = useCollectionOrderStore();

  const unblockNextStep = (option: Option) => {
    useCollectionOrderStore.setState({
      ...collectionOrderStore,
      collectionId: option.value as number,
      isNextStepBlocked: false,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        file: undefined,
      }}
      onSubmit={(values, { resetForm }) => {
        resetForm();
      }}
    >
      <Form>
        <InputSelect
          label="Kolekcja"
          name="collection"
          options={collectionOptions}
          onChange={(option) => unblockNextStep(option as Option)}
        />
      </Form>
    </Formik>
  );
};
