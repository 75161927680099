import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import React, { useEffect, useState } from "react";
import { useOrders } from "../../api/useOrders";
import { useUser } from "../../api/useUser";
import { TextareaField } from "../../../../components/TextareaField";
import moment from "moment";
import { useTextiles } from "../../api/useTextiles";
import { useProduct } from "../../api/useProduct";
import { useCollection } from "../../../collections/api/useCollection";
import { Textile } from "../../../../types/Textile";
import { useParams } from "../../api/useParams";
import { PageHeader } from "../../../../components/PageHeader";
import { StatusBadge } from "../OrdersTable/StatusBadge";

type OrderDetailFormProps = {
  id: number;
};
export const CustomerOrderDetailView = ({ id }: OrderDetailFormProps) => {
  const { refetch, orders } = useOrders();
  const { fetch, user } = useUser();
  const { textiles } = useTextiles();
  const { product, productFetch } = useProduct();
  const { collection, fetch: fetchCollection } = useCollection();
  const [textile, setTextile] = useState<Textile>();
  const { params } = useParams();
  useEffect(() => {
    refetch();
  }, []);
  const order = orders.find((order) => order.id === id);

  useEffect(() => {
    order && fetch(order?.userId);
  }, [order?.userId]);

  useEffect(() => {
    order && productFetch(order.productId);
  }, [order?.productId]);

  useEffect(() => {
    order && fetchCollection(order.collectionId);
  }, [order?.collectionId]);

  useEffect(() => {
    order && setTextile(textiles.find((item) => item.id === 1));
  }, [order?.textileId]);

  return (
    <div className="max-w-4xl">
      {order && (
        <>
          <div className="flex justify-between">
            <PageHeader
              title={`Zamówienie ${order.poNumber}`}
              subtitle={
                <div className={"ml-4"}>
                  <StatusBadge id={order.statusId} />
                </div>
              }
            />
          </div>

          <Formik
            enableReinitialize
            initialValues={{
              ...order,
              user: user?.name,
              product,
              collection,
              textile,
            }}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <Form>
                {values.zdNumber && (
                  <InputField
                    label="Numer ZD"
                    name="zdNumber"
                    value={values.zdNumber}
                    disabled
                  />
                )}
                {values.productDescription && (
                  <TextareaField
                    label="Opis produktu"
                    value={values.productDescription}
                    disabled
                  />
                )}
                <div className="grid grid-cols-2 gap-1">
                  {values.quantity && (
                    <InputField
                      label="Ilość"
                      value={values.quantity}
                      disabled
                      type="number"
                    />
                  )}
                  {values.unit && (
                    <InputField
                      label="Jednostka"
                      value={values.unit}
                      disabled
                    />
                  )}

                  {values.createdAt && (
                    <InputField
                      label="Data utworzenia"
                      value={moment(values.createdAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}
                  {values.confirmedAt && (
                    <InputField
                      label="Data potwiedzenia"
                      value={moment(values.confirmedAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}
                  {values.deliveryAt && (
                    <InputField
                      label="Data dostawy"
                      value={moment(values.deliveryAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}

                  {values.buyerCode && (
                    <InputField
                      label="Kod kupującego"
                      value={values.buyerCode}
                      disabled
                    />
                  )}
                  {values.buyerAddress && (
                    <InputField
                      label="Miasto kupującego"
                      value={values.buyerAddress}
                      disabled
                    />
                  )}
                  {values.batchNumber && (
                    <InputField
                      label="Numer batch"
                      value={values.batchNumber}
                      disabled
                    />
                  )}
                  {values.collectionId && (
                    <InputField
                      label="Kolekcja"
                      value={values.collection?.sku}
                      disabled
                    />
                  )}
                </div>
                <div className="grid grid-cols-2 gap-1">
                  {values.params &&
                    Object.entries(values.params).map(([key, value]) => {
                      return (
                        <InputField
                          key={key}
                          label={
                            params
                              .find((item) => item.id.toString() === key)
                              ?.name.toString() || ""
                          }
                          value={(value as string) || ""}
                          disabled
                        />
                      );
                    })}
                </div>
                <div className="grid grid-cols-2 gap-1">
                  {values.productId && (
                    <div className="col-span-1">
                      <InputField
                        label="Produkt"
                        value={product?.name}
                        disabled
                      />

                      {product?.image && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/storage/images/products/${product?.image}`}
                          alt={product?.name}
                          className="rounded-lg w-full mt-2 aspect-square object-cover"
                        />
                      )}
                    </div>
                  )}
                  {values.textile?.name && (
                    <div className="col-span-1">
                      <InputField
                        label="Tkanina"
                        value={values.textile?.name}
                        disabled
                      />
                      {textile?.image && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/storage/images/textiles/${textile?.image}`}
                          alt={product?.name}
                          className="rounded-lg w-full mt-2 aspect-square object-cover"
                        />
                      )}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
