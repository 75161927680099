import React from "react";

export const UserTableHeaderRow = () => {
  return (
    <div className="px-4 mb-2 grid grid-flow-col grid-cols-10 gap-2">
      <div className="col-span-3">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Indeks / SKU
        </span>
      </div>
      <div className="col-span-4">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Email
        </span>
      </div>
      <div className="col-span-1">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Rola
        </span>
      </div>
      <div className="col-span-2" />
    </div>
  );
};
