import { useContext } from "react";
import { storeProductVariant } from "../../../api";
import { CollectionContext } from "../contexts/CollectionContext";
import { ProductVariantContext } from "../contexts/ProductVariantContext";
import { useNavigate } from "react-router-dom";

export const useCreateProductVariant = (): {
  createProductVariant: (productVariant: any) => void;
} => {
  const navigate = useNavigate();
  const { refetch, editCollection } = useContext(CollectionContext);
  const { setIsCreateProductVariantModalOpen } = useContext(
    ProductVariantContext
  );

  const createProductVariant = (productVariant: any) => {
    const payload = {
      collection_id: editCollection.id,
      sku: productVariant.sku,
      product_id: productVariant.product.value,
      textile_id: productVariant.textile.value,
      param_values: productVariant.param_values
        .filter((item: any) => !!item)
        .map((param: any) => ({
          param_id: param.id,
          param_value:
            typeof param.value === "string"
              ? param.value
              : typeof param.value !== "undefined" &&
                Object.entries(param.value).map(([key, value]) => {
                  return !isNaN(parseInt(key)) && value;
                }),
        })),
    };
    storeProductVariant(payload)
      .then(() => {
        refetch();
        setIsCreateProductVariantModalOpen(false);
        navigate(0);
      })
      .catch((e: any) => console.error(e));
  };

  return { createProductVariant };
};
