import React, { useContext } from "react";
import { HiPlus } from "react-icons/hi2";
import { Button } from "../../../../components/Button";
import { UserContext } from "../../contexts/UserContext";

export const AddUserButton = () => {
  const { setIsSidebarOpen } = useContext(UserContext);

  const handleOpenBtn = () => setIsSidebarOpen(true);

  return (
    <div className="grid grid-cols-2 gap-4 mb-6">
      <div className="col-start-2 ml-auto">
        <Button
          onClick={handleOpenBtn}
          icon={<HiPlus />}
          label="Dodaj użytkownika"
        />
      </div>
    </div>
  );
};
