import { Layout } from "../../layout/Layout";
import React from "react";
import { StoresContextProvider } from "./contexts/StoresContext";
import { AddStoreButton } from "./StoreSidebar/AddStoreButton";
import { Sidebar } from "./StoreSidebar/Sidebar";
import { StoreTable } from "./StoreTable";

export const AgatasStores = () => {
  return (
    <StoresContextProvider>
      <Layout>
        <Sidebar />
        <div className="flex justify-between	">
          <div>
            <h1 className="text-4xl font-semibold">Sklepy Agata Meble</h1>
            <h2 className="pt-2 text-md text-gray-500 mb-6">
              Lista sklepów Agata Meble
            </h2>
          </div>
          <AddStoreButton />
        </div>
        <StoreTable />
      </Layout>
    </StoresContextProvider>
  );
};
