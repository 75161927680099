import React, { useContext, useEffect, useState } from "react";
import { OrdersRow } from "./OrdersRow";
import { OrdersTableHeaderRow } from "./OrdersTableHeaderRow";
import { OrdersContext } from "../../contexts/OrdersContext";
import { useStatusOptionsStore } from "../../../../stores/useStatusOptionsStore";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import { request } from "../../../../api";
import { Order } from "../../../../types/Order";
import { useUserStore } from "../../../../stores/useUserStore";
import { UserRoleNames } from "../../../../types/User";

export const OrdersTable = () => {
  const { setSelectedOrders } = useContext(OrdersContext);
  const { options, selectedOption } = useStatusOptionsStore();
  const { role, id } = useUserStore();

  const STATUS_FILTERS = [
    { label: "Wszystkie", value: undefined },
    ...options,
    { label: "Usunięte", value: "deleted" },
  ];

  const [page, setPage] = useState(0);

  const [isMore, setIsMore] = useState(0);

  const [fetchedOrders, setFetchedOrders] = useState<Order[]>([]);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [selectedOption]);

  const fetchData = () => {
    request({
      url: !!selectedOption
        ? selectedOption == "deleted"
          ? "/orders/deleted"
          : `/orders/filter/${selectedOption}?page=${page + 1}`
        : `/orders?page=${page + 1}`,
    }).then((res) => {
      setPage(page + 1);
      if (role.includes(UserRoleNames.CUSTOMER)) {
        const customerOrders = res.data.filter(
          (order: Order) => order.userId === id
        );
        setFetchedOrders(fetchedOrders.concat(...customerOrders));
      } else {
        setFetchedOrders(fetchedOrders.concat(...res.data));
      }

      setIsMore(res.total);
    });
  };

  return (
    <>
      <div className="my-4 max-w-lg">
        <span className="inline-block mr-2">Pokaż:</span>
        <Select
          className="inline-block w-[200px]"
          placeholder="Status"
          name="status"
          value={STATUS_FILTERS.find(
            (status) => status.value === selectedOption
          )}
          options={STATUS_FILTERS}
          onChange={(option: any) => {
            setFetchedOrders([]);
            setPage(0);
            setIsMore(0);
            useStatusOptionsStore.setState({
              options,
              selectedOption: option.value,
            });
            setSelectedOrders([]);
          }}
        />
      </div>

      <OrdersTableHeaderRow />
      <div id="scrollableDiv">
        {!!fetchedOrders.length && (
          <InfiniteScroll
            next={() => {
              fetchData();
            }}
            hasMore={isMore > fetchedOrders.length}
            loader={
              <div className="w-full flex justify-center text-gray-400 uppercase text-xs">
                Loading...
              </div>
            }
            scrollableTarget="scrollableDiv"
            dataLength={fetchedOrders.length}
            endMessage={
              <div className="w-full flex justify-center text-gray-400 uppercase text-xs">
                Koniec wynikow
              </div>
            }
            height={window.innerHeight - 260}
          >
            {fetchedOrders &&
              fetchedOrders.map((order) => (
                <OrdersRow order={order} key={order.id} />
              ))}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};
