import { Modal } from "../../../../components/Modal";
import React, { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { MercuryStepper } from "./MercuryStepper";
import { useMercuryUploadStore } from "../../../../stores/useMercuryUploadStore";

export const MercuryOrder = () => {
  const { setIsMercuryModalOpen, isMercuryModalOpen } =
    useContext(OrdersContext);
  const { clearUpload } = useMercuryUploadStore();

  return (
    <Modal
      isModalOpen={isMercuryModalOpen}
      setIsModalOpen={setIsMercuryModalOpen}
      handleClose={clearUpload}
      size="lg"
      content={
        <div>
          <MercuryStepper />
        </div>
      }
      title="Import zamowien - Mercury"
    />
  );
};
