import { deleteCollection as deleteItem } from "../../../api";

export const useDeleteCollection = (
  refetch: any
): {
  deleteCollection: (id: number) => void;
} => {
  const deleteCollection = (id: number) => {
    deleteItem(id)
      .then(() => {
        refetch();
      })
      .catch((e: any) => console.error(e));
  };

  return { deleteCollection };
};
