import { Order } from "../../types/Order";
import moment from "moment";
import { Package } from "../../types/Package";
import autoTable from "jspdf-autotable";
import JsBarcode from "jsbarcode";

const MIN_TABLE_WIDTH = 80;
const COLOR_BLACK = 0;
const COLOR_WHITE = 255;
const TABLE_LINE_WIDTH = 0.65;
const DEFAULT_MIN_CELL_HEIGHT = 8.5;
const HUGE_MIN_CELL_HEIGHT = DEFAULT_MIN_CELL_HEIGHT * 2;
const MERGE_COLUMN_SPAN = 2;
const MERGE_ROWS_STYLES = {
  lineWidth: { top: 0.9, left: 0 },
  lineColor: COLOR_WHITE,
};
export const printMercuryLabel = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package,
  textileName: string,
  paramValue: string,
  orderIndex: string
) => {
  autoTable(doc, {
    theme: "plain",
    head: [[{ content: "        ", colSpan: MERGE_COLUMN_SPAN }]],
    tableWidth: MIN_TABLE_WIDTH,
    startY: 1,
    margin: { left: 30, bottom: 0 },
    tableLineColor: COLOR_BLACK,
    tableLineWidth: TABLE_LINE_WIDTH,
    styles: {
      lineColor: 0,
      lineWidth: TABLE_LINE_WIDTH,
      minCellHeight: DEFAULT_MIN_CELL_HEIGHT,
    },
    body: [
      [
        {
          colSpan: MERGE_COLUMN_SPAN,
          content: "",
          styles: { minCellHeight: HUGE_MIN_CELL_HEIGHT },
        },
      ],
      [{ colSpan: MERGE_COLUMN_SPAN, content: "" }],
      [
        {
          content: "        ",
          styles: { minCellHeight: HUGE_MIN_CELL_HEIGHT },
        },
        { content: "     ", styles: { minCellHeight: HUGE_MIN_CELL_HEIGHT } },
      ],
      [
        { content: "", styles: { minCellHeight: HUGE_MIN_CELL_HEIGHT } },
        { content: "", styles: { minCellHeight: HUGE_MIN_CELL_HEIGHT } },
      ],
      ["", { content: "", styles: MERGE_ROWS_STYLES }],
      ["", { content: "" }],
      ["", { content: "", styles: MERGE_ROWS_STYLES }],
      ["", { content: "", styles: MERGE_ROWS_STYLES }],
    ],
  });

  const product = order.product;
  const kw = moment(order.deliveryAt, "YYYYMMDD").isoWeek();
  const deliveryYear = moment(order.deliveryAt, "YYYYMMDD").year();
  const encodeIndex = btoa(order.zdNumber);
  const canvas = document.createElement("canvas");
  canvas.setAttribute("width", "10px");
  // Scaled rectangle
  // @ts-ignore
  JsBarcode(canvas, encodeIndex, {
    displayValue: false,
  });
  doc.addImage(canvas, "PNG", 78, 60, 30, 15);

  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(12.5);
  doc.text("Kolekcja/Kolecia/Kolekce/Kollekció", 32, 8);

  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(12);
  doc.text(product?.name, 32, 13.5);
  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.setFontSize(10);
  doc.text(product?.name, 32, 17);
  doc.text(product?.name, 32, 21);
  doc.text(product?.name, 32, 25);

  doc.setFont("IBMPlexSans-Bold", "normal", "normal");

  doc.setFontSize(10);
  doc.text("INDEX: ", 78, 58);
  doc.text("INDEX: ", 32, 84);
  doc.setFontSize(10);
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");

  doc.text(order.zdNumber || "Brak", 91, 58);
  doc.text(order.zdNumber || "Brak", 45, 84);
  doc.setFontSize(30);
  doc.text(`${parcelIndex + 1}/${packagesQty}`, 86, 48);
  doc.setFontSize(10);
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.text("SAGAN FM", 32, 39);
  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.text("Gabriela Wąsala Sp.K.", 32, 43);
  doc.text("ul. Poznańska 6", 32, 47);
  doc.text("63-600 Kępno", 32, 51);

  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.setFontSize(18);
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(20);
  doc.text(`${productPackage?.weight} [kg]`, 40, 62);
  doc.setFontSize(12);
  doc.text(`KW ${kw}/${deliveryYear}`, 32, 76);
  doc.text(orderIndex, 50, 92);
  doc.text(textileName, 32, 101);
  doc.text(paramValue, 32, 33);

  doc.addPage();
};
