import React, { useContext, useEffect } from "react";
import { ProductVariantRow } from "./ProductVariantRow";
import { useCollection } from "../../../api/useCollection";
import { CollectionContext } from "../../../contexts/CollectionContext";

export const ProductVariantTable = () => {
  const context = useContext(CollectionContext);
  const { fetch, collection } = useCollection();

  useEffect(() => {
    context?.editCollection?.id && fetch(context?.editCollection?.id || 0);
  }, [context?.editCollection?.id]);

  return (
    <div className="mb-2">
      {context?.editCollection?.id &&
        collection &&
        collection.productVariants.map((collection) => (
          <ProductVariantRow
            productVariant={collection}
            key={collection.id}
            refetchProduct={() => fetch(context?.editCollection?.id || 0)}
          />
        ))}
    </div>
  );
};
