import { array, object, string } from "yup";

export const validationSchema = object().shape({
  name: string()
    .max(100, "Maksymalna ilość znaków musi przekraczać 100")
    .required("Pole wymagane"),
  options: array()
    .when("type", {
      is: "select",
      then: () =>
        array()
          .of(
            string()
              .max(100, "Maksymalna ilość znaków musi przekraczać 100")
              .required("Pole wymagane")
          )
          .min(2, "Dodaj minimum dwie opcje"),
    })
    .when("type", {
      is: "Multiselect",
      then: () =>
        array()
          .of(
            string()
              .max(100, "Maksymalna ilość znaków musi przekraczać 100")
              .required("Pole wymagane")
          )
          .min(2, "Dodaj minimum dwie opcje"),
    }),
});
