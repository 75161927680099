import React, { Dispatch } from "react";
import { HiMiniXMark, HiOutlineXMark } from "react-icons/hi2";
import { FieldArray, Form, FormikProps, FormikProvider } from "formik";
import { Button } from "../../components/Button";
import { Textile, Textile as TextileType } from "../../types/Textile";
import { Category } from "../../types/Category";
import { Param, Param as ParamType } from "../../types/Param";
import { TextileRow } from "./TextileRow";
import { ImageUpload } from "../../components/ImageUpload";
import { DropdownList } from "../../components/DropdownList";
import { ParamRow } from "./ParamRow";
import { InputField } from "../../components/InputField";
import { Package } from "../../types/Package";
import { resizeImage } from "../../utils/resizeImage";

type SidebarType = {
  formik: FormikProps<any>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<boolean>;
  images: any;
  setImages: Dispatch<any>;
  textiles: Textile[];
  categories: Category[];
  params: Param[];
  selectedCategory: any;
  setSelectedCategory: any;
  setIsEditingSidebarOpen: Dispatch<boolean>;
  isEditingSidebarOpen: boolean;
};

export function Sidebar({
  formik,
  isSidebarOpen,
  setIsSidebarOpen,
  images,
  setImages,
  textiles,
  categories,
  params,
  selectedCategory,
  setSelectedCategory,
  setIsEditingSidebarOpen,
  isEditingSidebarOpen,
}: SidebarType) {
  const handleCloseBtn = () => {
    setIsSidebarOpen(false);
    setIsEditingSidebarOpen(false);
    formik.resetForm();
    setImages([]);
  };

  const onImageChange = (imageList: any) => {
    !!imageList.length
      ? resizeImage(imageList[0].data_url).then((res) => {
          setImages(
            imageList.map((image: any) => ({
              ...image,
              data_url: res,
            }))
          );
        })
      : setImages([]);
    formik.setFieldTouched("image", true);
    formik.setFieldValue("image", imageList);
  };

  const handleOnChange = formik.handleChange;
  const handleChange = (e: any) => {
    formik.setFieldTouched(e.target.name, true);
    formik.handleChange(e);
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form>
          <div
            className={`transition ease-out delay-500 fixed w-[50%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
              isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
            }`}
          >
            <div className="p-10 pl-9">
              <div>
                <HiOutlineXMark
                  className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
                  onClick={handleCloseBtn}
                />
              </div>
              <div className="pt-5 pl-1 grid grid-cols-5">
                <div className="col-span-5 mb-6">
                  <h1 className="text-3xl font-semibold mb-1">
                    {isEditingSidebarOpen ? "Edytuj produkt" : "Dodaj produkt"}
                  </h1>
                  {isEditingSidebarOpen && (
                    <p className="text-gray-400">
                      Zmień informacje, aby edytować dany produkt
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-2 col-span-3 mr-4">
                  <div className="col-span-2 mb-4 relative mr-2">
                    <InputField
                      label="Nazwa produktu"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder="Nazwa produktu"
                    />
                  </div>

                  <div className="col-span-1 mb-4 relative mr-2 pt-4">
                    <DropdownList
                      formik={formik}
                      name="categoryId"
                      options={categories}
                      selected={selectedCategory}
                      setSelected={setSelectedCategory}
                      label="Kategoria"
                      pricegroups={false}
                      categories
                    />
                  </div>
                  <div className="col-span-1 ml-2 mr-2 mb-4 relative">
                    <InputField
                      label="Indeks/SKU"
                      name="sku"
                      onChange={handleChange}
                      value={formik.values.sku}
                      placeholder="Indeks/SKU"
                    />
                  </div>
                  <div className="col-span-1 mr-2 mb-4 relative">
                    <InputField
                      label="Zużycie tkaniny [mb]"
                      name="textile_qty"
                      onChange={handleChange}
                      value={formik.values.textile_qty}
                      placeholder="Zużycie tkaniny"
                    />
                  </div>
                  <div className="col-span-1 mr-2 mb-4 relative">
                    <InputField
                      type="number"
                      label="Minimalna produkcja [szt.]"
                      name="production_minimum"
                      onChange={handleChange}
                      value={formik.values.production_minimum}
                      placeholder="0"
                    />
                  </div>
                  <div className="col-span-2 mb-4 relative">
                    <FieldArray
                      name="packages"
                      render={(arrayHelpers: any) => (
                        <div>
                          <div className="col-span-1">
                            {formik.values.packages &&
                              formik.values.packages.length > 0 &&
                              formik.values.packages.map(
                                (item: Package, index: number) => (
                                  <div
                                    className="relative"
                                    key={`package-0${index}`}
                                  >
                                    <label
                                      htmlFor="description"
                                      className="block text-xs font-medium text-gray-400 my-2 ml-4"
                                    >
                                      Paczka{" "}
                                      {(index + 1).toLocaleString("en-US", {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                      })}
                                    </label>
                                    <div className="grid grid-cols-4 col-span-3 gap-x-4 bg-gray-50 rounded-2xl px-4 border-gray-200 border-[1px]">
                                      <div className="flex h-full justify-between flex-col">
                                        <InputField
                                          label="Waga [kg]"
                                          name={`packages.${index}.weight`}
                                          value={item.weight}
                                          onChange={handleChange}
                                          placeholder="10"
                                          type="number"
                                        />
                                      </div>
                                      <div className="col-span-1 mr-2 relative">
                                        <div className="flex h-full justify-between flex-col">
                                          <InputField
                                            label="Szer. [cm]"
                                            name={`packages.${index}.width`}
                                            value={item.width}
                                            onChange={handleChange}
                                            placeholder="10"
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-span-1 mb-4 relative">
                                        <div className="flex h-full justify-between flex-col">
                                          <InputField
                                            label="Wys. [cm]"
                                            name={`packages.${index}.height`}
                                            onChange={handleChange}
                                            placeholder="10"
                                            value={item.height}
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-span-1 mb-4 mr-2 relative">
                                        <div className="flex h-full justify-between flex-col">
                                          <InputField
                                            label="Gł. [cm]"
                                            name={`packages.${index}.depth`}
                                            onChange={handleChange}
                                            value={item.depth}
                                            placeholder="10"
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <HiMiniXMark
                                      className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 absolute top-[-6px] pb-2 right-0 mr-2"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Usuń paczke"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                )
                              )}
                          </div>

                          <Button
                            onClick={() =>
                              arrayHelpers.insert(
                                formik.values.packages.length + 1,
                                {
                                  weight: null,
                                  width: null,
                                  height: null,
                                  depth: null,
                                }
                              )
                            }
                            type="button"
                            label="Dodaj paczkę"
                            classes={`text-xs py-[5px] px-[12px] my-4`}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <label
                    htmlFor="description"
                    className="col-span-2 block text-xs font-medium text-gray-400 pb-2"
                  >
                    Zdjęcie tkaniny
                  </label>
                  <div className="col-span-2 grid grid-cols-2">
                    <ImageUpload
                      formik={formik}
                      images={images}
                      maxFileSize={4000000}
                      onImageChange={onImageChange}
                      isEditingSidebarOpen={isEditingSidebarOpen}
                    />
                  </div>
                </div>
                <div className="col-span-2 h-full flex flex-col justify-between">
                  <div>
                    <span className="block text-xs font-medium text-gray-400 pb-2">
                      Tkaniny do wyboru
                    </span>
                    <div className="h-[250px] border bg-gray-50 rounded-lg p-4 overflow-y-auto">
                      {textiles &&
                        textiles.map((textile: TextileType, index: number) => (
                          <TextileRow
                            index={`textile_${index}`}
                            id={textile.id}
                            onChange={handleOnChange}
                            checkedTextiles={
                              formik.values.checkedTextiles.includes(
                                textile.id.toString()
                              )!
                            }
                            key={textile.id}
                            textile={textile}
                          />
                        ))}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="block text-xs font-medium text-gray-400 pb-2">
                        Parametry do wyboru
                      </span>
                      <div className="h-[250px] border bg-gray-50 rounded-lg p-4 overflow-y-auto">
                        {params &&
                          params.map((param: ParamType, index: number) => (
                            <ParamRow
                              index={`param_${index}`}
                              id={param.id}
                              onChange={handleOnChange}
                              checkedParams={
                                formik.values.checkedParams.includes(
                                  param.id.toString()
                                )!
                              }
                              key={param.id}
                              param={param}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 mt-4">
                <Button type="submit" label="Zapisz" />
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}
