import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import React, { useEffect, useState } from "react";
import { MercuryOrder } from "../../../../types";
import { useMercuryUploadStore } from "../../../../stores/useMercuryUploadStore";
import { useProducts } from "../../api/useProducts";
import { useTextiles } from "../../api/useTextiles";

// TODO: Po wybraniu pliku odblokuje sie przucisk dalej w steperze

const reNoEmptyLines = new RegExp(/[a-zA-Z]+/);

export const ImportDatFile = () => {
  const reader = new FileReader();

  const mercuryStep = useMercuryUploadStore();
  const { products } = useProducts();
  const { textiles } = useTextiles();
  const [mercuryOrders, setMercuryOrders] = useState<MercuryOrder[]>([]);
  const unblockNextStep = () => {
    useMercuryUploadStore.setState({
      ...mercuryStep,
      orders: mercuryOrders,
      isNextStepBlocked: false,
    });
  };

  useEffect(() => {
    mercuryOrders.length && unblockNextStep();
  }, [mercuryOrders.length]);

  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="col-span-2 pt-4">
          <p className="text-gray-400">Wgraj plik DAT z systemu Mercury</p>
        </div>
      </div>{" "}
      <Formik
        enableReinitialize
        initialValues={{
          file: undefined,
        }}
        onSubmit={(values, { resetForm }) => {
          resetForm();
        }}
      >
        {({ errors }) => (
          <Form>
            <div className="max-w-md">
              <InputField
                label="Plik"
                name="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    useMercuryUploadStore.setState({
                      orderName: event.target.files[0].name,
                    });
                    reader.onload = (e) => {
                      // @ts-ignore
                      const text = e.target.result as string;

                      const [titles, ...lines] = text?.split("\n");

                      const mercuries = lines
                        ?.filter((tex) => reNoEmptyLines.test(tex))
                        .map((tex, index) => {
                          const order = tex.split(/\s{2,}|\t/);
                          const product = products.find((product) =>
                            order[5]
                              .toLowerCase()
                              .includes(product.name.toLowerCase())
                          );
                          const textile = textiles.find((textile) =>
                            order[5]
                              .toLowerCase()
                              .includes(textile.name.toLowerCase())
                          );
                          return {
                            zd_number: Number(order[2]),
                            quantity: Number(order[3]),
                            product_id: product?.id,
                            textile_id: textile?.id,
                            batch_number: Number(order[6]),
                            productFullName: order[5],
                          };
                        });

                      setMercuryOrders(mercuries);
                    };
                    reader.readAsText(event.target.files[0], "ISO-8859-2");
                  }
                }}
                type="file"
                error={errors.file}
                accept=".dat"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
