import { countPrints } from "../../../api";
import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";

export const useCountPrints = () => {
  const { selectedOrders, refetch } = useContext(OrdersContext);

  const handleCount = () => {
    countPrints({ orders: selectedOrders.map(({ id }) => id) })
      .then(() => {
        refetch();
      })
      .catch((e) => console.error(e));
  };

  return { countPrints: handleCount };
};
