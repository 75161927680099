import { Form, FormikProps, FormikProvider } from "formik";
import { InputField } from "../../../../components/InputField";
import { UserRoleNames } from "../../../../types/User";
import React, { useEffect } from "react";
import { useUserStore } from "../../../../stores/useUserStore";
import { useUser } from "../../api/useUser";
import { useUserOption } from "../../api/useUserOptions";
import { InputSelect } from "../../../../components/InputSelect";
import { MercuryOrdersFormik } from "./types";

type MercuryDetailsFormProps = {
  formik: FormikProps<MercuryOrdersFormik>;
};
export const MercuryDetailsForm = ({ formik }: MercuryDetailsFormProps) => {
  const { id } = useUserStore();
  const { user: detailUser, fetch: fetchUser } = useUser();

  const { userOptions } = useUserOption();

  useEffect(() => {
    id && fetchUser(id);
  }, [id]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <InputSelect
          label="Użytkownik"
          name="user"
          onChange={(option: any) => {
            formik.setFieldValue("user", option);
          }}
          options={userOptions}
          value={
            userOptions.find(
              (user) => user.value == formik.values.user.value
            ) || userOptions.find((user) => user.value == id)
          }
          placeholder="Jan"
          isDisabled={detailUser?.roles
            ?.map((role) => role.name)
            .includes(UserRoleNames.CUSTOMER)}
        />
        <InputField
          label="Prefix zamowien"
          onChange={formik.handleChange}
          name="po_number"
          value={formik.values.po_number}
        />
      </Form>
    </FormikProvider>
  );
};
