import { useEffect, useState } from "react";
import { getParams } from "../../../api";
import { Param } from "../../../types/Param";

export const useParams = () => {
  const [params, setParams] = useState<Param[]>([]);
  const fetch = () =>
    getParams()
      .then((res: Param[]) => setParams(res))
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { params, refetch: fetch };
};
