import React from "react";
import { Layout } from "../../../layout/Layout";
import { UploadOrderButton } from "./OrderForm/UploadOrderButton";
import { AgataOrderForm } from "./OrderForm/AgataOrderForm";
import { OrdersTable } from "./OrdersTable";
import { OrdersContextProvider } from "../contexts/OrdersContext";
import { OrderForm } from "./OrderForm/OrderForm";
import { useStatusOptions } from "../api/useStatusOptions";
import { MercuryOrder } from "./MercuryOrder";
import { CollectionOrder } from "./CollectionOrder";
import { useUsers } from "../../users/api/useUsers";

export const OrdersAgata = () => {
  useStatusOptions();
  useUsers();
  return (
    <OrdersContextProvider>
      <Layout>
        <AgataOrderForm />
        <OrderForm />
        <MercuryOrder />
        <CollectionOrder />
        <div className="flex justify-between">
          <div>
            <h1 className="text-4xl font-semibold">Zamówienia</h1>
            <h2 className="pt-2 text-md text-gray-500 mb-6">
              Lista dodanych zamówień
            </h2>
          </div>
          <UploadOrderButton />
        </div>
        <OrdersTable />
      </Layout>
    </OrdersContextProvider>
  );
};
