import { useEffect, useState } from "react";
import { getTextiles } from "../../../api";

export const useTextileOptions = () => {
  const [textileOptions, setTextileOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const fetch = () =>
    getTextiles()
      .then((res) =>
        setTextileOptions(
          res.map(({ id, name }) => ({ label: name, value: id }))
        )
      )
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { textileOptions, refetch: fetch };
};
