import React, { useContext } from "react";
import { StoreRow } from "./StoreRow";
import { StoreTableHeaderRow } from "./StoreTableHeaderRow";
import { StoresContext } from "../contexts/StoresContext";

export const StoreTable = () => {
  const context = useContext(StoresContext);
  return (
    <>
      <StoreTableHeaderRow />
      {context?.addresses &&
        context?.addresses.map((address) => (
          <StoreRow address={address} key={address.id} />
        ))}
    </>
  );
};
