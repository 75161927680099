import axios from "axios";
import Cookie from "js-cookie";
import { camelizeKeys, decamelizeKeys } from "humps";
import { useUserStore } from "./stores/useUserStore";
import { Textile } from "./types/Textile";
import { Product } from "./types/Product";
import { Param } from "./types/Param";
import { Category } from "./types/Category";
import { PriceGroup } from "./types/PriceGroup";
import { FormikValues } from "formik";
import { UserRoleNames } from "./types/User";
import { Address } from "./types/Address";
import { OrderType } from "./types/OrderType";
import { Status } from "./types";

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

type Params = {
  url: string;
  method?: "GET" | "POST" | "DELETE" | "PUT";
  data?: any;
};
export const request = async ({ url, method = "GET", data }: Params) => {
  const token = Cookie.get("token");

  try {
    const response = await axios.request({
      data: data && method !== "GET" ? decamelizeKeys(data) : null,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method,
      url: `${API_URL}${url}`,
    });

    return camelizeKeys(response.data) as any;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      useUserStore.setState({ role: "anonymous" });
      Cookie.remove("token");
    }

    throw error;
  }
};

export const requestWithFile = async ({
  url,
  method = "GET",
  data,
}: Params) => {
  const token = Cookie.get("token");

  try {
    const response = await axios.post(`${API_URL}${url}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    return camelizeKeys(response.data) as any;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      useUserStore.setState({ role: "anonymous" });
      Cookie.remove("token");
    }

    throw error;
  }
};

export const signIn = async (email: string, password: string) =>
  axios.post(`${API_URL}/login`, {
    email,
    password,
  });
export const getTextiles = async (): Promise<Textile[]> =>
  request({ url: "/textiles" });
export const storeTextile = async (values: FormikValues): Promise<Textile> =>
  request({ url: "/textiles", method: "POST", data: values });
export const deleteTextile = async (id: number): Promise<Response> =>
  request({ url: `/textiles/${id}`, method: "DELETE" });
export const updateTextiles = async (values: FormikValues): Promise<Textile> =>
  request({
    url: `/textiles/${values.textileId}`,
    method: "PUT",
    data: values,
  });
export const getPriceGroups = async (): Promise<PriceGroup[]> =>
  request({ url: "/price-groups" });

export const getCategories = async (): Promise<Category[]> =>
  request({ url: "/categories" });
export const storeCategory = async (values: FormikValues): Promise<Category> =>
  request({ url: "/categories", method: "POST", data: values });
export const updateCategory = async (values: FormikValues): Promise<Category> =>
  request({
    url: `/categories/${values.categoryId}}`,
    method: "PUT",
    data: values,
  });
export const deleteCategory = async (id: number): Promise<Response> =>
  request({ url: `/categories/${id}`, method: "DELETE" });
export const getParams = async (): Promise<Param[]> =>
  request({ url: "/params" });
export const storeParam = async (values: FormikValues): Promise<Param> =>
  request({ url: "/params", method: "POST", data: values });
export const updateParam = async (values: FormikValues): Promise<Param> =>
  request({ url: `/params/${values.id}`, method: "PUT", data: values });
export const deleteParam = async (id: number): Promise<Response> =>
  request({ url: `/params/${id}`, method: "DELETE" });
export const getProducts = async (): Promise<Product[]> =>
  request({ url: "/products" });
export const getProduct = async (id: number): Promise<Product> =>
  request({ url: `/products/${id}` });
export const storeProduct = async (values: FormikValues): Promise<Product> =>
  request({ url: "/products", method: "POST", data: values });
export const updateProduct = async (values: FormikValues): Promise<Product> =>
  request({
    url: `/products/${values.productId}`,
    method: "PUT",
    data: values,
  });
export const deleteProduct = async (id: number): Promise<Response> =>
  request({ url: `/products/${id}`, method: "DELETE" });

export const getCollections = async (): Promise<any[]> =>
  request({ url: "/collections" });
export const getCollection = async (id: number): Promise<any> =>
  request({ url: `/collection/${id}` });
export const getAgatasStores = async (): Promise<Address[]> =>
  request({ url: `/agata-addresses` });
export const deleteCollection = async (id: number): Promise<Response> =>
  request({ url: `/collections/${id}`, method: "DELETE" });
export const storeCollection = async (values: FormikValues): Promise<Param> =>
  request({ url: "/collections", method: "POST", data: values });
export const storeAddress = async (values: FormikValues): Promise<Param> =>
  request({ url: "/agata-addresses", method: "POST", data: values });
export const updateCollection = async (
  values: FormikValues
): Promise<Product> =>
  request({
    url: `/collections/${values.id}`,
    method: "PUT",
    data: values,
  });

export const updateAddress = async (values: FormikValues): Promise<Product> =>
  request({
    url: `/agata-addresses/${values.id}`,
    method: "PUT",
    data: values,
  });

export const storeProductVariant = async (
  values: FormikValues
): Promise<Param> =>
  request({ url: "/product-variants", method: "POST", data: values });
export const deleteProductVariant = async (id: number): Promise<Response> =>
  request({ url: `/product-variants/${id}`, method: "DELETE" });
export const deleteStore = async (id: number): Promise<Response> =>
  request({ url: `/agata-addresses/${id}`, method: "DELETE" });
export const deleteOrder = async (id: number): Promise<Response> =>
  request({ url: `/orders/${id}`, method: "DELETE" });
export const getUsers = async (role: UserRoleNames): Promise<any> =>
  request({ url: `/users/${role}` });
export const getOrders = async (): Promise<any> => request({ url: "/orders" });
export const getOrdersByStatus = async (id: number): Promise<any> =>
  request({ url: `/orders/filter/${id}` });

export const getOrderTypes = async (): Promise<OrderType[]> =>
  request({ url: "/order-types" });
export const getOrder = async (id: number): Promise<any> =>
  request({ url: `/orders/${id}` });

export const getUser = async (id: number): Promise<any> =>
  request({ url: `/users/${id}` });
export const uploadOrder = async (values: FormikValues): Promise<Param> =>
  requestWithFile({ url: "/agata-orders", method: "POST", data: values });

export const storeOrder = async (values: FormikValues): Promise<any> =>
  requestWithFile({ url: "/orders", method: "POST", data: values });

export const updateOrder = async (values: FormikValues): Promise<Product> =>
  request({
    url: `/orders/${values.id}`,
    method: "PUT",
    data: values,
  });

export const storeUser = async (values: FormikValues): Promise<Param> => {
  const { ordersTypes, ...rest } = values;
  return request({
    url: "/users",
    method: "POST",
    data: rest,
  });
};

export const deleteUser = async (id: number): Promise<Response> =>
  request({ url: `/users/${id}`, method: "DELETE" });
export const getUserTextile = async (id: number): Promise<any> =>
  request({ url: `/textiles/users/${id}` });

export const updateUser = async (values: FormikValues): Promise<Product> =>
  request({
    url: `/users/${values.id}`,
    method: "PUT",
    data: values,
  });

export const countPrints = async (data: FormikValues): Promise<Param> => {
  return request({
    url: "/count-print",
    method: "POST",
    data,
  });
};

export const getStatuses = async (): Promise<Status[]> =>
  request({ url: `/statuses` });
