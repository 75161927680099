import { deleteProductVariant as deleteItem } from "../../../api";

export const useDeleteProductVariant = (): {
  deleteProductVariant: (id: number) => void;
} => {
  const deleteProductVariant = (id: number) => {
    deleteItem(id).catch((e: any) => console.error(e));
  };

  return { deleteProductVariant };
};
