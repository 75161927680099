import React from "react";

export const StoreTableHeaderRow = () => {
  return (
    <div className="px-4 mb-2 grid grid-flow-col grid-cols-8 gap-2">
      <div className="col-span-2">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Nazwa
        </span>
      </div>
      <div className="col-span-3">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Kod
        </span>
      </div>
    </div>
  );
};
