import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Button } from "../../components/Button";
import { HiPlus } from "react-icons/hi2";
import { deleteParam, getParams } from "../../api";
import { Sidebar } from "./Sidebar";
import { ParamRow } from "./ParamRow";
import { Param as ParamType } from "../../types/Param";
import { inputTypes } from "../../data/inputTypes";
import { Modal } from "../../components/Modal";

export const Params = () => {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<ParamType[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedParam, setSelectedParam] = useState<ParamType>();
  const [isEditingSidebarOpen, setIsEditingSidebarOpen] = useState(false);
  const [modalObject, setModalObject] = useState<ParamType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  useEffect(() => {
    (async () => {
      const params = await getParams();
      setLoading(false);
      setParams(params);
    })();
  }, []);

  if (loading) {
    return <Layout>Wczytywanie...</Layout>;
  }

  const handleShowSidebar = () => {
    setIsSidebarOpen(true);
  };
  const handleEditInSidebar = (param: ParamType) => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(true);
    setSelectedParam(param);
  };
  const showDeleteModal = (param: ParamType) => {
    setModalObject(param);
    setIsModalOpen(true);
  };
  const handleDelete = async (id: number) => {
    await deleteParam(id);
    const updatedParams = await getParams();
    setParams(updatedParams);
  };

  return (
    <Layout>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        inputTypes={inputTypes}
        selectedParam={selectedParam}
        setSelectedParam={setSelectedParam}
        setParams={setParams}
        isEditingSidebarOpen={isEditingSidebarOpen}
        setIsEditingSidebarOpen={setIsEditingSidebarOpen}
        setHasBeenTouched={setHasBeenTouched}
        hasBeenTouched={hasBeenTouched}
      />
      <h1 className="text-4xl font-semibold">Parametry produktów</h1>
      <h2 className="pt-2 text-md text-gray-500 mb-6">
        Lista dodanych parametrów
      </h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="col-start-2 ml-auto">
          <Button
            onClick={handleShowSidebar}
            icon={<HiPlus />}
            label="Dodaj parametr"
          />
        </div>
      </div>
      <div className="px-4 mb-2">
        <span className="block text-xs font-medium text-gray-400">Nazwa</span>
      </div>
      <div className="mb-6">
        {params &&
          params.map((param) => (
            <ParamRow
              key={param.id}
              param={param}
              handleEdit={handleEditInSidebar}
              showDeleteModal={showDeleteModal}
            />
          ))}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Potwierdź usunięcie parametru"
        content={`Czy napewno chcesz usunąć parametr ${
          modalObject?.name || ""
        }?`}
        buttonLabel="Usuń"
        onConfirm={() => {
          if (modalObject !== undefined) {
            handleDelete(modalObject.id);
          }
        }}
      />
    </Layout>
  );
};
