import { Order } from "../../types/Order";
import moment from "moment";
import { Package } from "../../types/Package";
import { rollerLogo } from "../roller/rollerLogo";

export const printRollerBelegnummerTemplate = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package,
  textileName: string,
  paramValue: string,
  orderIndex: string
) => {
  const product = order.product;
  const kw = moment(order.deliveryAt, "YYYYMMDD").isoWeek();

  // Header
  // Nazwa produktu
  doc.setFont("IBMPlexSans-Bold", "normal", "normal");
  doc.setFontSize(50);
  doc.text(product?.name, 10, 45);
  // Roller Logo
  doc.addImage(rollerLogo, "PNG", 85, 12, 60, 32);

  // Body
  // Material
  doc.setFontSize(18);
  doc.text(`MATERIAL: ${textileName}`, 10, 57);
  // Parametry
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(22);
  doc.text(paramValue, 10, 70);
  // Numer zgodnosci
  doc.setFontSize(24);
  doc.text(`Belegnummmer: ${order.poNumber}`, 10, 85);

  // Footer
  // Paczka
  doc.setFont("IBMPlexSans-Bold", "normal", "normal");
  doc.setFontSize(24);
  doc.text(`PACZKA: ${parcelIndex + 1} z ${packagesQty}`, 10, 100);
  // Tydzien dostawy
  doc.text(`KW ${kw}`, 100, 100);

  doc.addPage();
};
