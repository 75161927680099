import React, { useContext } from "react";

import { OrdersContext } from "../../contexts/OrdersContext";
import { Modal } from "../../../../components/Modal";
import { useDeleteOrder } from "../../api/useDeleteOrder";
import { useNavigate } from "react-router-dom";

type DeleteModalProps = {
  name: string;
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const DeleteModal = ({
  name,
  id,
  isModalOpen,
  setIsModalOpen,
}: DeleteModalProps) => {
  const { refetch } = useContext(OrdersContext);
  const { deleteOrder } = useDeleteOrder(refetch);
  const navigate = useNavigate();
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={`Czy napewno chcesz usunąć zamówienie ${name}?`}
      buttonLabel="Usuń"
      title="Potwierdź usunięcie zamówienia"
      onConfirm={() => {
        deleteOrder(id);
        setTimeout(() => {
          navigate(0);
        }, 500);
      }}
    />
  );
};
