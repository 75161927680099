import { OrderForm as OrderFormType, OrderFormik } from "../types";

export const castToOrder = (
  {
    collection_id,
    product_id,
    textile_id,
    params,
    user_id,
    ...rest
  }: OrderFormik,
  multiParams: number[]
): OrderFormType => {
  return {
    ...rest,
    user_id: Number(user_id?.value),
    order_type_id: 3,
    collection_id: Number(collection_id?.value) || null,
    product_id: Number(product_id?.value),
    textile_id: Number(textile_id?.value),
    params: params
      .filter((item: any) => !!item)
      .map((param: any) => ({
        id: param.id || param[0].id,
        paramValues:
          typeof param.value === "string"
            ? [param.value]
            : typeof param.value === "undefined" &&
              Object.entries(param)
                .map(([key, _]) => {
                  return param[key].value;
                })
                .filter((item) => !!item),
      }))
      .reduce(
        (a: any, item: any) => ({
          ...a,
          [item.id]:
            multiParams.includes(Number(item.id)) &&
            typeof item.paramValues === "string"
              ? [item.paramValues]
              : item.paramValues,
        }),
        {}
      ),
  };
};
