import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { StatusOption } from "../types";

type StatusOptionsStore = {
  clearStore(): void;
  options: StatusOption[];
  selectedOption?: number | string;
};

const getInitialState = (
  set: SetState<StatusOptionsStore>,
  get: GetState<StatusOptionsStore>
): StatusOptionsStore => ({
  options: [],
  selectedOption: undefined,
  clearStore: () => {
    set({ options: [] });
  },
});

export const useStatusOptionsStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "statusOptions",
  })
);
