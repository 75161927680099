import { generateAgataTemplate } from "./generateAgataTemplate";
import { generateMercuryTemplate } from "./merkury/generateMercuryTemplate";
import { generateRollerTemplate } from "./roller/generateRollerTemplate";
import { generateRollerBelegnummerTemplate } from "./rollerBelegnummer/generateRollerBelegnummerTemplate";
import { generateBasicTemplate } from "./basic/generateBasicTemplate";
import { generateUaTemplate } from "./ua/generateUaTemplate";
import { generateFsmTemplate } from "./fsm/generateFsmTemplate";

export const generatePdf = {
  agataTemplate: generateAgataTemplate,
  mercuryTemplate: generateMercuryTemplate,
  rollerTemplate: generateRollerTemplate,
  rollerTemplateBelegnummer: generateRollerBelegnummerTemplate,
  basic: generateBasicTemplate,
  ua: generateUaTemplate,
  fsm: generateFsmTemplate
};

export type GeneratePdf = "agataTemplate" | "mercuryTemplate" | "rollerTemplate" | "rollerTemplateBelegnummer" | "basic" | "ua" | "fsm"