import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import { Button } from "../../../../components/Button";
import React, { useContext, useEffect, useState } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { useUserOption } from "../../api/useUserOptions";
import { FormWrapper } from "../../../../components/FormWrapper";
import { InputSelect } from "../../../../components/InputSelect";
import { useUserStore } from "../../../../stores/useUserStore";
import { useCollectionOptions } from "../../api/useCollectionOptions";
import { useProductOptions } from "../../../collections/api/useProductOptions";
import { useProduct } from "../../api/useProduct";
import { useParamOptions } from "../../../collections/api/useParamOptions";
import { useCreateOrder } from "../../api/useCreateOrder";
import { useOrder } from "../../api/useOrder";
import { OrderFormik } from "../../types";
import { generateValidationSchema } from "../../../../schemas/Order.schema";
import { castToOrder } from "../../utils/castToOrder";
import { useUpdateOrder } from "../../api/useUpdateOrder";
import { useUser } from "../../api/useUser";
import { UserRoleNames } from "../../../../types/User";

export const OrderForm = () => {
  const { order, fetch } = useOrder();
  const {
    isEditSidebarOpen,
    setIsEditSidebarOpen,
    editOrderId,
    setEditOrderId,
  } = useContext(OrdersContext);
  const { createOrder } = useCreateOrder();
  const { userOptions } = useUserOption([UserRoleNames.CUSTOMER]);
  const { id } = useUserStore();
  const { user: detailUser, fetch: fetchUser } = useUser();
  const currentUser = userOptions.find((user) => user.value === id);
  const { collectionOptions } = useCollectionOptions();
  const { productOptions } = useProductOptions();
  const { productFetch, product } = useProduct();
  const { updateOrder } = useUpdateOrder();
  const [validationSchema, setValidationSchema] = useState(
    generateValidationSchema(order?.product?.productionMinimum)
  );

  const {
    paramOptions,
    textileOptions,
    textFieldsParamsOptions,
    fetch: fetchProductVariants,
  } = useParamOptions();

  useEffect(() => {
    setValidationSchema(
      generateValidationSchema(
        product?.productionMinimum,
        !!product?.textiles.length
      )
    );
  }, [product?.id]);
  const fetchProduct = async (id: number) => {
    productFetch(id);
    fetchProductVariants(id);
  };

  useEffect(() => {
    id && fetchUser(id);
  }, [id]);
  useEffect(() => {
    editOrderId && fetch(editOrderId);
  }, [editOrderId]);

  useEffect(() => {
    order?.productId && fetchProductVariants(order?.productId);

    order?.productId && fetchProduct(order?.productId);
  }, [order?.productId]);
  const handleCloseBtn = () => {
    setIsEditSidebarOpen(false);
    setEditOrderId(undefined);
  };

  const initialValues: OrderFormik = {
    user_id: userOptions.find((user) => user.value === order?.userId) ||
      userOptions.find((user) => user.value === currentUser?.value) || {
        label: "",
        value: 0,
      },
    order_type_id: { label: "form", value: 3 },
    collection_id:
      collectionOptions.find(
        ({ value }) => parseInt(value) === order?.collectionId
      ) || undefined,
    po_number: order?.poNumber || undefined,
    product_id:
      productOptions.find(({ value }) => value === order?.productId) ||
      undefined,
    textile_id:
      textileOptions.find(({ value }) => value === order?.textileId) ||
      undefined,
    params: Object.entries(order?.params || {})
      .map(([k, v]): any => {
        if (typeof v === "string") {
          return [
            {
              id: k,
              value: v,
              label: v,
            },
          ];
        }
        // @ts-ignore
        return v.map((par: any) => ({
          id: k,
          value: par,
          label: par,
        }));
      })
      .flat(2),
    quantity: order?.quantity || undefined,
    unit: order?.unit || undefined,
  };

  const multiParams = paramOptions
    .filter((par) => par.type === "Multiselect")
    .map((par) => par.value);

  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-10 right-0 shadow-2xl ${
          isEditSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <FormWrapper
          handleCloseBtn={handleCloseBtn}
          title="Zamówienia"
          subtitle={order ? "Edytuj zamówienie" : "Dodaj nowe zamówienie"}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const castedOrder = castToOrder(
                {
                  ...values,
                  params: [...values.params, ...textFieldsParamsOptions],
                },
                multiParams
              );
              order
                ? updateOrder({ ...castedOrder, id: order.id }, resetForm)
                : createOrder(castedOrder, resetForm);
            }}
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <InputSelect
                  name="user"
                  label="Użytkownik"
                  options={userOptions}
                  value={values.user_id}
                  onChange={(option) => {
                    setFieldValue("user_id", option);
                  }}
                  placeholder="Jan"
                  isDisabled={detailUser?.roles
                    ?.map((role) => role.name)
                    .includes(UserRoleNames.CUSTOMER)}
                />

                <InputField
                  label="Numer zamówienia klienta"
                  name="po_number"
                  onChange={handleChange}
                  value={values.po_number}
                />
                <InputSelect
                  name="product_id"
                  label="Produkt"
                  options={productOptions}
                  onChange={(option) => {
                    setFieldValue("product_id", option);
                    setFieldValue("params", []);
                    setFieldValue("quantity", product?.productionMinimum);
                    // @ts-ignore
                    fetchProduct(option.value);
                  }}
                  value={values.product_id}
                  placeholder="Select"
                />

                {!!product?.textiles.length && (
                  <InputSelect
                    name="textile_id"
                    label="Tekstylia"
                    options={product?.textiles.map((textile) => {
                      return textileOptions.find(
                        (textileOption) => textileOption.value === textile.id
                      );
                    })}
                    onChange={(option) => {
                      setFieldValue("textile_id", option);
                    }}
                    value={values.textile_id}
                    placeholder="Select"
                  />
                )}
                {paramOptions.length !== 0 && (
                  <label
                    htmlFor="parma_values"
                    className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                  >
                    Parametry
                  </label>
                )}

                {!!paramOptions.length &&
                  paramOptions.map((item: any, index) => {
                    return (
                      <div key={`input-${index}`}>
                        {typeof item.options === "string" ? (
                          <InputField
                            name={item.value.toString()}
                            label={item.label}
                            onChange={() =>
                              setFieldValue(
                                `param_values.${item.value}`,
                                item.options
                              )
                            }
                            value={item.options}
                            disabled
                          />
                        ) : (
                          <>
                            <InputSelect
                              label={item.label}
                              name={item.value.toString()}
                              isMulti={item.type === "Multiselect"}
                              onChange={(option: any) => {
                                setFieldValue(`params.${index}`, option);
                              }}
                              value={values.params[index]}
                              options={item.options
                                .map((opt: any) => ({
                                  label: opt,
                                  value: opt,
                                  id: item.value,
                                  type: item.type,
                                }))
                                .flat()}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}

                <InputField
                  label="Ilość"
                  name="quantity"
                  onChange={handleChange}
                  type="number"
                  value={values.quantity}
                />
                <Button type="submit" label="Zapisz" classes="my-3 ml-auto" />
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </div>
    </div>
  );
};
