import React from "react";
import { Form, Formik } from "formik";
import { useCreateStore } from "../api/useCreateStore";
import { useUpdateStore } from "../api/useUpdateStore";
import { Address } from "../../../types/Address";
import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";

type AddressFormProps = {
  address: Address;
};

export const AddressForm = ({ address }: AddressFormProps) => {
  const { createStore } = useCreateStore();
  const { updateStore } = useUpdateStore();
  const isEditMode = !!address.id;

  const handleSubmit = (values: Address) => {
    isEditMode ? updateStore(values, address.id || 0) : createStore(values);
  };
  return (
    <div className="pt-5 pl-1">
      <div className="grid grid-cols-2">
        <div className="col-span-2 mb-6">
          <h1 className="text-3xl font-semibold mb-1">
            {isEditMode ? "Edytuj sklep" : "Dodaj nowy sklep"}
          </h1>
          <p className="text-gray-400">
            {!isEditMode && "Dodaj informacje, aby utworzyć nowy sklep"}
          </p>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={address}
        // validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ values, errors, handleChange }) => (
          <Form>
            <InputField
              label="Nazwa"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              placeholder="Agata Meble"
            />
            <InputField
              label="Kod"
              name="code"
              value={values.code}
              onChange={handleChange}
              error={errors.code}
              placeholder="PLK"
            />
            {/* TODO: Zamienic na textarea*/}
            <InputField
              label="Opis"
              name="description"
              value={values.description}
              onChange={handleChange}
              error={errors.description}
              placeholder="Opis sklepu..."
            />
            <Button type="submit" label="Zapisz" classes="my-3 ml-auto" />
          </Form>
        )}
      </Formik>
    </div>
  );
};
