import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Button } from "../../components/Button";
import { HiPlus } from "react-icons/hi2";
import { Product as ProductType } from "../../types/Product";
import { Textile as TextileType } from "../../types/Textile";
import { Category as CategoryType } from "../../types/Category";
import { Param as ParamType } from "../../types/Param";
import {
  deleteProduct,
  getCategories,
  getParams,
  getProduct,
  getProducts,
  getTextiles,
  storeProduct,
  updateProduct,
} from "../../api";
import { ProductRow } from "./ProductRow";
import { Sidebar } from "./Sidebar";
import { useFormik } from "formik";
import { validationSchema } from "../../schemas/Product.schema";
import { Modal } from "../../components/Modal";
import { ModalObject } from "../../types/ModalObject";

type Images = {
  data_url: string;
};

export const Products = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [textiles, setTextiles] = useState<TextileType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [params, setParams] = useState<ParamType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [images, setImages] = useState<Images[]>([]);
  const [isEditingSidebarOpen, setIsEditingSidebarOpen] = useState(false);
  const [modalObject, setModalObject] = useState<ModalObject>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    (async () => {
      const products = await getProducts();
      const textiles = await getTextiles();
      const categories = await getCategories();
      const params = await getParams();
      setLoading(false);
      setProducts(products);
      setTextiles(textiles);
      setCategories(categories);
      setParams(params);
      setSelectedCategory(categories[0].name);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      sku: "",
      textile_qty: 0,
      image: "",
      checkedTextiles: [] as string[],
      checkedParams: [] as string[],
      categoryId: "",
      packages: [{}],
      production_minimum: 0,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isEditingSidebarOpen) {
          await updateProduct(values);
        } else {
          await storeProduct(values);
        }
        const updatedProducts = await getProducts();
        setProducts(updatedProducts);
        setIsSidebarOpen(false);
        resetForm();
        setImages([]);
        setSelectedCategory(categories[0].name);
      } catch (error) {
        console.log("API error");
      }
    },
    validationSchema,
  });
  if (loading) {
    return <Layout>Wczytywanie...</Layout>;
  }

  const handleShowSidebar = () => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(false);
    formik.setFieldValue("categoryId", categories[0].id);
  };

  const handleEditInSidebar = async (product: ProductType) => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(true);
    const productToEdit = await getProduct(product.id);
    await formik.setFieldValue("categoryId", productToEdit.categoryId);
    await formik.setFieldValue("productId", productToEdit.id);
    await formik.setFieldValue("name", productToEdit.name);
    await formik.setFieldValue("sku", productToEdit.sku);
    await formik.setFieldValue("packages", productToEdit.packages);
    await formik.setFieldValue("textile_qty", productToEdit.textileQty);
    await formik.setFieldValue(
      "production_minimum",
      productToEdit.productionMinimum
    );
    await formik.setFieldValue("image", [{ data_url: productToEdit.image }]);
    setImages([
      {
        data_url: `${process.env.REACT_APP_API_URL}/storage/images/products/${productToEdit.image}`,
      },
    ]);
    let checkedTextiles: string[] = [];
    productToEdit?.textiles.forEach((textile: TextileType) => {
      checkedTextiles.push(textile.id.toString());
    });
    let checkedParams: string[] = [];
    productToEdit?.params?.forEach((param: ParamType) => {
      checkedParams.push(param.id.toString());
    });
    await formik.setFieldValue("checkedTextiles", checkedTextiles);
    await formik.setFieldValue("checkedParams", checkedParams);
  };
  const handleDelete = async (id: number) => {
    await deleteProduct(id);
    const updatedProducts = await getProducts();
    setProducts(updatedProducts);
  };
  return (
    <Layout>
      <Sidebar
        formik={formik}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        images={images}
        setImages={setImages}
        textiles={textiles}
        categories={categories}
        params={params}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setIsEditingSidebarOpen={setIsEditingSidebarOpen}
        isEditingSidebarOpen={isEditingSidebarOpen}
      />
      <h1 className="text-4xl font-semibold">Produkty</h1>
      <h2 className="pt-2 text-md text-gray-500 mb-6">
        Lista dodanych produktów
      </h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="col-start-2 ml-auto">
          <Button
            onClick={handleShowSidebar}
            icon={<HiPlus />}
            label="Dodaj produkt"
          />
        </div>
      </div>
      <div className="px-4 mb-2 grid grid-flow-col grid-cols-7 gap-2">
        <div className="col-span-2">
          <span className="block text-xs font-medium text-gray-400 pb-2">
            Nazwa
          </span>
        </div>
        <div className="col-span-1">
          <span className="block text-xs font-medium text-gray-400 pb-2">
            Indeks / SKU
          </span>
        </div>
        <div className="col-span-1">
          <span className="block text-xs font-medium text-gray-400 pb-2">
            Zużycie tkaniny [mb]
          </span>
        </div>
      </div>
      <div className="mb-6">
        {products &&
          products.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
              handleEditInSidebar={handleEditInSidebar}
              setModalObject={setModalObject}
              setIsModalOpen={setIsModalOpen}
            />
          ))}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Potwierdź usunięcie produktu"
        content={`Czy napewno chcesz usunąć produkt ${
          modalObject?.name || ""
        }?`}
        buttonLabel="Usuń"
        onConfirm={() => {
          if (modalObject !== undefined) {
            handleDelete(modalObject.id);
          }
        }}
      />
    </Layout>
  );
};
