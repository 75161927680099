import React, { ReactNode } from "react";
import { Modal } from "../../../../components/Modal";

type OrderModalProps = {
  children: ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const StatusModal = ({
  children,
  isModalOpen,
  setIsModalOpen,
}: OrderModalProps) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={children}
      title="Status"
    />
  );
};
