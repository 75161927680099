import React, { Dispatch } from "react";
import { Category } from "../../types/Category";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";

type CategoryRowProps = {
  category: Category;
  handleClick: (category: Category) => void;
  setIsModalOpen: Dispatch<boolean>;
  setModalObject: (category: Category) => void;
};

export const CategoryRow = ({
  category,
  handleClick,
  setIsModalOpen,
  setModalObject,
}: CategoryRowProps) => {
  const handleModal = () => {
    setIsModalOpen(true);
    setModalObject(category);
  };
  return (
    <div className="rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-8 gap-2 leading-8 text-gray-400">
      <div className="col-span-5 grid grid-flow-col auto-cols-max py-2">
        <div className="mr-1 flex items-center">
          <span className="font-medium text-gray-900">{category.name}</span>
        </div>
      </div>
      <div className="col-span-7 py-2  hidden group-hover:flex justify-end items-end">
        <HiOutlinePencil
          className=" h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
          onClick={() => handleClick(category)}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
          onClick={handleModal}
        />
      </div>
    </div>
  );
};
