import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { ImportDatFile } from "./ImportDatFile";
import { useMercuryUploadStore } from "../../../../stores/useMercuryUploadStore";
import { MercuryValidationForm } from "./MercuryValidationForm";
import { useFormik } from "formik";
import { useUserOption } from "../../api/useUserOptions";
import { MercuryDetailsForm } from "./MercuryDetailsForm";
import { useUserStore } from "../../../../stores/useUserStore";
import { useCreateOrders } from "../../api/useCreateOrders";

const steps = ["Wybierz plik", "Zwaliduj dane", "Dodatkowe informacje"];

export const MercuryStepper = () => {
  const mercuryStore = useMercuryUploadStore();
  const { userOptions } = useUserOption();
  const { id } = useUserStore();
  const { createOrders } = useCreateOrders();
  const formik = useFormik({
    initialValues: {
      orders: mercuryStore.orders,
      po_number: mercuryStore.orderName.split(".dat")[0],
      user: userOptions.find((user) => user.value == id),
    },
    onSubmit: (values) => {
      const orderProducts = values.orders.map((order) => ({
        ...order,
        order_type_id: 2,
        param_values: order.param_values
          ? order.param_values
              .filter((item: any) => !!item)
              .map((param: any) => ({
                id: param.id || param[0].id,
                paramValues:
                  typeof param.value === "string"
                    ? [param.value]
                    : typeof param.value === "undefined" &&
                      Object.entries(param)
                        .map(([key, _]) => {
                          return param[key].value;
                        })
                        .filter((item) => !!item),
              }))
              .reduce(
                (a: any, item: any) => ({
                  ...a,
                  [item.id]:
                    order.multiParams?.includes(Number(item.id)) &&
                    typeof item.paramValues === "string"
                      ? [item.paramValues]
                      : item.paramValues,
                }),
                {}
              )
          : [],
      }));
      const newOrders = orderProducts.map((order) => ({
        ...order,
        user_id: values.user?.value,
        po_number: `${values.po_number}-${order.zd_number}`,
      }));

      createOrders(newOrders);
    },
    enableReinitialize: true,
  });
  const setActiveStep = (step: number) =>
    useMercuryUploadStore.setState({ ...mercuryStore, currentStep: step });

  const handleNext = () => {
    if (mercuryStore.currentStep === 2) {
      formik.submitForm();
      return;
    }
    useMercuryUploadStore.setState({
      ...mercuryStore,
      isNextStepBlocked: true,
      currentStep: mercuryStore.currentStep + 1,
    });
  };

  const handleBack = () => {
    setActiveStep(mercuryStore.currentStep - 1);
  };

  return (
    <Box sx={{ width: "100%", height: "500px" }}>
      <Stepper activeStep={mercuryStore.currentStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {mercuryStore.currentStep < steps.length && (
        <React.Fragment>
          <Box>
            {mercuryStore.currentStep === 0 && <ImportDatFile />}
            {mercuryStore.currentStep === 1 && (
              <MercuryValidationForm formik={formik} />
            )}
            {mercuryStore.currentStep === 2 && (
              <MercuryDetailsForm formik={formik} />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={mercuryStore.currentStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              onClick={handleNext}
              disabled={
                mercuryStore.isNextStepBlocked && mercuryStore.currentStep === 0
              }
            >
              {mercuryStore.currentStep === steps.length - 1
                ? "Zapisz"
                : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
