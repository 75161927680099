import React, { useContext, useState } from "react";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";
import { OrdersContext } from "../../contexts/OrdersContext";
import { DeleteModal } from "../DeleteModal";

type OrderOverviewProps = {
  id: number;
};
export const OrderOverview = ({ id }: OrderOverviewProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { setEditOrderId, setIsEditSidebarOpen } = useContext(OrdersContext);

  const handleDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSidebar = () => {
    setEditOrderId(id);
    setIsEditSidebarOpen(true);
  };

  return (
    <>
      <div className="col-span-3 py-2 hidden group-hover:flex justify-center align-middle items-end">
        <HiOutlinePencil
          className="h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleSidebar}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleDeleteModal}
        />
      </div>
      <DeleteModal
        id={id}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        name=""
      />
    </>
  );
};
