import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";
import { uploadOrder } from "../../../api";
import { ToastMessageContext } from "../../../components/ToastMessage";
import { useNavigate } from "react-router-dom";

export const useUploadOrder = (): {
  createOrder: (order: any) => void;
} => {
  const { setIsSidebarOpen } = useContext(OrdersContext);
  const { setToastMessage } = useContext(ToastMessageContext);
  const navigate = useNavigate();

  const createOrder = (order: any) => {
    uploadOrder(order)
      .then(() => {
        setIsSidebarOpen(false);
        setToastMessage({
          type: "success",
          message: "Zaimportowano plik",
          data: [],
        });
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch((e: any) => {
        setToastMessage({
          type: "error",
          message: e.message,
          data: [],
        });
      });
  };

  return { createOrder };
};
