import React, { useContext } from "react";
import { CollectionRow } from "./CollectionRow";
import { CollectionTableHeaderRow } from "./CollectionTableHeaderRow";
import { CollectionContext } from "../../contexts/CollectionContext";

export const CollectionTable = () => {
  const context = useContext(CollectionContext);
  return (
    <>
      <CollectionTableHeaderRow />
      {context?.collections &&
        context?.collections.map((collection) => (
          <CollectionRow collection={collection} key={collection.id} />
        ))}
    </>
  );
};
