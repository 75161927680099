import React, { FC } from "react";
import { Textile as TextileType } from "../../types/Textile";

type TextileRowType = {
  index: string;
  id: number;
  onChange: any;
  checkedTextiles: boolean;
  textile: TextileType;
};

export const TextileRow: FC<TextileRowType> = ({
  index,
  id,
  onChange,
  checkedTextiles,
  textile,
}) => {
  return (
    <div>
      <input
        className="h-5 w-5 rounded border-gray-200 mr-2 checked:bg-gray-900 checked:hover:bg-gray-500 checked:focus:bg-gray-500"
        type="checkbox"
        name="checkedTextiles"
        checked={checkedTextiles}
        id={`row${index}`}
        value={id}
        onChange={onChange}
      />
      <label
        htmlFor={`row${index}`}
        className={
          checkedTextiles
            ? "font-semibold text-gray-900"
            : "font-normal text-gray-500"
        }
      >
        {textile.name}
      </label>
    </div>
  );
};
