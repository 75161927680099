import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import Cookie from "js-cookie";
import { OrderType, UserRoleNames } from "../types/User";

type UserStore = {
  clearUser(): void;
  role: string | UserRoleNames[];
  orderTypes: string | OrderType[];
  id: number | undefined;
};

const getInitialState = (
  set: SetState<UserStore>,
  get: GetState<UserStore>
): UserStore => ({
  role: "anonymous",
  orderTypes: [],
  id: undefined,
  clearUser: () => {
    set({ role: "anonymous", orderTypes: [], id: undefined });
    Cookie.remove("token");
  },
});

export const useUserStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "user",
  })
);
