import React from "react";
import { Textile } from "../../types/Textile";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";

type TextileRowProps = {
  textile: Textile;
  handleOpenModal: any;
  setModalObject: any;
  handleEditInSidebar: (textile: Textile) => void;
};

export const TextileRow = ({
  textile,
  handleOpenModal,
  setModalObject,
  handleEditInSidebar,
}: TextileRowProps) => {
  const handleModal = () => {
    setModalObject(textile);
    handleOpenModal();
  };

  return (
    <div className="rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-5 gap-2">
      <div className="col-span-2 grid grid-flow-col auto-cols-max py-2 ">
        <div className="mr-3">
          <img
            src={`${process.env.REACT_APP_API_URL}/storage/images/textiles/${textile.image}`}
            alt={textile.name}
            className="w-8 rounded-full mr-2 h-8 object-cover"
          />
        </div>
        <div className="my-auto">{textile.name}</div>
      </div>

      <div className="col-span-1 my-auto">{textile.manufacturer}</div>
      <div className="col-span-1 my-auto">{textile.priceGroup}</div>
      <div className="col-span-1 hidden group-hover:flex justify-end items-center">
        <div className="flex flex-row">
          <HiOutlinePencil
            className="h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
            onClick={() => handleEditInSidebar(textile)}
          />
          <HiOutlineArchiveBoxXMark
            className="ml-auto h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
            onClick={handleModal}
          />
        </div>
      </div>
    </div>
  );
};
