import React, { useContext, useState } from "react";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";

import { StoresContext } from "../contexts/StoresContext";
import { Address } from "../../../types/Address";
import { DeleteModal } from "../DeleteModal";

type AddressRowProps = {
  address: Address;
};

export const StoreRow = ({ address }: AddressRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setEditAddress, setIsSidebarOpen } = useContext(StoresContext);
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenEditSidebar = () => {
    setEditAddress(address);
    setIsSidebarOpen(true);
  };

  return (
    <div className="h-14 rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-8 gap-2 leading-8 text-gray-400">
      <div className="col-span-2 py-2">
        <span className="font-medium text-gray-900">{address.name}</span>
      </div>
      <div className="col-span-3 py-2">{address.code}</div>

      <div className="col-span-3 py-2 hidden group-hover:flex justify-end items-end">
        <HiOutlinePencil
          className="h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleOpenEditSidebar}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleModal}
        />
      </div>
      {address.id && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          name={address.name}
          id={address.id}
        />
      )}
    </div>
  );
};
