import React from "react";
import {
  HiArrowLeftOnRectangle,
  HiOutlineAdjustmentsHorizontal,
  HiOutlineBattery100,
  HiOutlineBuildingStorefront,
  HiOutlineCog8Tooth,
  HiOutlineCube,
  HiOutlineHome,
  HiOutlinePlusSmall,
  HiOutlineSquaresPlus,
  HiOutlineSwatch,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
  HiOutlineWallet,
} from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useUserStore } from "../stores/useUserStore";
import "react-tooltip/dist/react-tooltip.css";
import { UserRoleNames } from "../types/User";

export const Layout = ({ children }: { children: any }) => {
  const { clearUser, role } = useUserStore();

  const handleLogout = (e: any) => {
    e.preventDefault();
    clearUser();
  };

  return (
    <div className="flex flex-row">
      <div className="flex h-screen flex-col w-64 justify-between border-r bg-white">
        <div className="px-4 py-6 text-xl text-gray-800">
          <span className="font-bold">Sagan</span>
          Admin
          <nav className="mt-6 flex flex-col space-y-1 text-gray-800">
            <Link
              to="/"
              className="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-2"
            >
              <HiOutlineHome />
              <span className="text-sm font-medium">Dashboard</span>
            </Link>

            <Link
              to="/orders"
              className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
            >
              <HiOutlineWallet />
              <span className="text-sm font-medium">Zamówienia</span>
            </Link>
            <Link
              to="/users/edit"
              className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
            >
              <HiOutlineUserCircle />
              <span className="text-sm font-medium">Edytuj profil</span>
            </Link>

            {!role.includes(UserRoleNames.CUSTOMER) && (
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 hover:bg-gray-100">
                  <div className="flex items-center gap-2">
                    <HiOutlineCog8Tooth />
                    <span className="text-sm font-medium">Ustawienia</span>
                  </div>
                  <span className="shrink-0 transition duration-300 group-open:-rotate-135">
                    <HiOutlinePlusSmall />
                  </span>
                </summary>

                <nav className="mt-2 flex flex-col px-4 text-gray-500">
                  <Link
                    to="/categories"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineSquaresPlus />
                    <span className="text-sm font-medium">Kategorie</span>
                  </Link>
                  <Link
                    to="/products"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineCube />
                    <span className="text-sm font-medium">Produkty</span>
                  </Link>
                  <Link
                    to="/textiles"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineSwatch />
                    <span className="text-sm font-medium">Tkaniny</span>
                  </Link>
                  <Link
                    to="/params"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineAdjustmentsHorizontal />
                    <span className="text-sm font-medium">Parametry</span>
                  </Link>
                  <Link
                    to="/collections"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineBattery100 />
                    <span className="text-sm font-medium">Kolekcje</span>
                  </Link>
                  <Link
                    to="/users"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineUserGroup />
                    <span className="text-sm font-medium">Użytkownicy</span>
                  </Link>
                  <Link
                    to="/store/agata"
                    className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <HiOutlineBuildingStorefront />
                    <span className="text-sm font-medium">Salony Agata</span>
                  </Link>
                </nav>
              </details>
            )}
          </nav>
        </div>

        <div className="sticky bottom-0">
          <nav className="mb-2 flex flex-col px-4 text-gray-800">
            <span
              onClick={handleLogout}
              className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 cursor-pointer hover:text-gray-900"
            >
              <HiArrowLeftOnRectangle />
              <span className="text-sm font-medium">Wyloguj</span>
            </span>
          </nav>
        </div>
      </div>
      <div className="w-full h-screen overflow-y-auto">
        <div className="p-7 ">{children}</div>
      </div>
    </div>
  );
};
