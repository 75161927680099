import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { useFormik } from "formik";
import { HiPlus } from "react-icons/hi2";
import { Textile as TextileType } from "../../types/Textile";
import { validationSchema } from "../../schemas/Textile.schema";
import {
  deleteTextile,
  getPriceGroups,
  getTextiles,
  storeTextile,
  updateTextiles,
} from "../../api";
import { TextileRow } from "./TextileRow";
import { Button } from "../../components/Button";
import { Sidebar } from "./Sidebar";
import { PriceGroup as PriceGroupType } from "../../types/PriceGroup";
import { Modal } from "../../components/Modal";
import { ModalObject } from "../../types/ModalObject";
import { TextileTableHeader } from "./TextileTableHeader";

export const Textiles = () => {
  const [loading, setLoading] = useState(true);
  const [textiles, setTextiles] = useState<TextileType[]>([]);
  const [priceGroups, setPriceGroups] = useState<PriceGroupType[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [images, setImages] = useState<{ data_url: string }[]>([]);
  const [selectedPriceGroup, setSelectedPriceGroup] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalObject, setModalObject] = useState<ModalObject>();
  const [isEditingSidebarOpen, setIsEditingSidebarOpen] = useState(false);
  const [hasBeenTouched, setHasBeenTouched] = useState({
    name: false,
    manufacturer: false,
  });
  useEffect(() => {
    (async () => {
      const textiles = await getTextiles();
      const priceGroups = await getPriceGroups();
      setLoading(false);
      setTextiles(textiles);
      setPriceGroups(priceGroups);
      setSelectedPriceGroup(priceGroups[0].name);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      manufacturer: "",
      priceGroup: "",
      image: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isEditingSidebarOpen) {
          await updateTextiles(values);
        } else {
          await storeTextile(values);
        }
        const updatedTextiles = await getTextiles();
        setTextiles(updatedTextiles);
        setIsSidebarOpen(false);
        setIsEditingSidebarOpen(false);
        setHasBeenTouched({ name: false, manufacturer: false });
        resetForm();
        setImages([]);
        setSelectedPriceGroup(priceGroups[0].name);
      } catch (error) {
        console.log("API error");
      }
    },
    validationSchema,
  });

  const handleDelete = async (id: number) => {
    await deleteTextile(id);
    const updatedTextiles = await getTextiles();
    setTextiles(updatedTextiles);
  };

  const handleShowSidebar = () => {
    setIsSidebarOpen(true);
    formik.setFieldValue("priceGroup", priceGroups[0].id);
  };
  const handleEditInSidebar = (textile: TextileType) => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(true);
    formik.setFieldValue("name", textile.name);
    formik.setFieldValue("manufacturer", textile.manufacturer);
    formik.setFieldValue("priceGroup", textile.priceGroup);
    formik.setFieldValue("textileId", textile.id);
    formik.setFieldValue("image", [
      { data_url: `./storage/images/textiles/${textile.image}` },
    ]);
    // @ts-ignore
    setImages([
      {
        data_url: `${process.env.REACT_APP_API_URL}/storage/images/textiles/${textile.image}`,
      },
    ]);
    setSelectedPriceGroup(JSON.stringify(textile.priceGroup));
  };
  if (loading) {
    return <Layout>Wczytywanie...</Layout>;
  }

  return (
    <Layout>
      <Sidebar
        formik={formik}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        priceGroups={priceGroups}
        images={images}
        setImages={setImages}
        selectedPriceGroup={selectedPriceGroup}
        setSelectedPriceGroup={setSelectedPriceGroup}
        isEditingSidebarOpen={isEditingSidebarOpen}
        setIsEditingSidebarOpen={setIsEditingSidebarOpen}
        setHasBeenTouched={setHasBeenTouched}
        hasBeenTouched={hasBeenTouched}
      />
      <h1 className="text-4xl font-semibold">Tkaniny</h1>
      <h2 className="pt-2 text-md text-gray-500 mb-6">
        Lista dostępnych tkanin
      </h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="col-start-2 ml-auto">
          <Button
            onClick={handleShowSidebar}
            icon={<HiPlus />}
            label="Dodaj tkaninę"
          />
        </div>
      </div>
      <TextileTableHeader />
      <div className="mb-6">
        {textiles &&
          textiles.map((textile) => (
            <TextileRow
              textile={textile}
              key={textile.id}
              handleOpenModal={() => {
                setIsModalOpen(true);
              }}
              setModalObject={setModalObject}
              handleEditInSidebar={handleEditInSidebar}
            />
          ))}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Potwierdź usunięcie tkaniny"
        content={`Czy napewno chcesz usunąć tkaninę ${
          modalObject?.name || ""
        }?`}
        buttonLabel="Usuń"
        onConfirm={() => {
          if (modalObject !== undefined) {
            handleDelete(modalObject.id);
          }
        }}
      />
    </Layout>
  );
};
