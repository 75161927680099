import { useEffect, useState } from "react";
import { getCollections } from "../../../api";

type Option = {
  label: string;
  value: string;
};
export const useCollectionOptions = () => {
  const [collectionOptions, setCollectionOptions] = useState<Option[]>([]);
  const fetch = () =>
    getCollections()
      .then((res) =>
        setCollectionOptions(
          !!res.length
            ? res.map((item) => {
                return {
                  label: item.sku,
                  value: item.id,
                };
              })
            : []
        )
      )
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return {
    collectionOptions,
  };
};
