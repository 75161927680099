import React from "react";
import { Modal } from "../../../../components/Modal";
import { useDeleteProductVariant } from "../../api/useDeleteProductVariant";

type DeleteModalProps = {
  name: string;
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  refetchProduct: () => void;
};

export const DeleteModal = ({
  name,
  id,
  isModalOpen,
  setIsModalOpen,
  refetchProduct,
}: DeleteModalProps) => {
  const { deleteProductVariant } = useDeleteProductVariant();
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={`Czy napewno chcesz usunąć wariant ${name}?`}
      buttonLabel="Usuń"
      title="Potwierdź usunięcie wariantu"
      onConfirm={() => {
        deleteProductVariant(id);
        refetchProduct();
      }}
    />
  );
};
