import { useStatusOptionsStore } from "../../../../stores/useStatusOptionsStore";
import { Form, Formik } from "formik";
import { InputSelect } from "../../../../components/InputSelect";
import { Button } from "../../../../components/Button";
import { StatusOption } from "../../../../types";
import { useChangeOrderStatus } from "../../api/useChangeOrderStatus";

type StatusFormProps = {
  orderStatusId: number;
  orderId: number;
  closeModal: () => void;
};

const initialValues: StatusOption = {
  value: 0,
  label: "",
  color: "",
};

export const StatusForm = ({
  orderStatusId,
  orderId,
  closeModal,
}: StatusFormProps) => {
  const { options } = useStatusOptionsStore();
  const { updateOrder } = useChangeOrderStatus();
  const handleSubmit = ({ status }: { status: StatusOption }) => {
    updateOrder(status.value, orderId);
    closeModal();
  };

  return (
    <Formik
      initialValues={{
        status:
          options.find((option) => option.value === orderStatusId) ||
          initialValues,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex flex-col justify-between h-[300px]">
          <div>
            <InputSelect
              label="Status"
              name="status"
              value={values.status}
              options={options}
              onChange={(option) => setFieldValue("status", option)}
            />
          </div>

          <Button label="Zapisz" type="submit" classes="w-[90px]" />
        </Form>
      )}
    </Formik>
  );
};
