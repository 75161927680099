import React, { useState } from "react";
import { useFormik } from "formik";
import { validationSchema } from "../../schemas/Login.schema";
import Cookie from "js-cookie";
import { useUserStore } from "../../stores/useUserStore";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { signIn } from "../../api";

import kv from "../../images/kv.jpg";
import { HiCheck, HiXMark } from "react-icons/hi2";
import { User } from "../../types";

const getUserRoles = (user: User) => {
  return user.roles?.map((role) => role.name);
};

export const Login = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ email, password }) => {
      try {
        const {
          data: { token, user },
        } = await signIn(email, password);

        Cookie.set("token", token);
        useUserStore.setState({
          role: getUserRoles(user),
          id: user.id,
          orderTypes: user.orders_types,
        });
        navigate("/admin");
      } catch (error) {
        setErrorMessage("Dane logowania nie zgadzają się");
      }
    },
    validationSchema,
  });

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <section className="relative hidden lg:flex lg:h-full lg:col-span-6">
          <img
            src={kv}
            alt="Keyvisual"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </section>

        <main className="flex items-center justify-center lg:col-span-6">
          <div className="max-w-xl">
            <form
              noValidate
              className="mt-8 grid grid-cols-6 gap-6"
              onSubmit={formik.handleSubmit}
            >
              <div className="col-span-12 relative">
                <h1 className="text-4xl text-gray-800">
                  <span className="font-bold">Sagan</span>
                  Admin
                </h1>
              </div>
              <div className="col-span-12 relative">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>

                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    placeholder="Adres e-mail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                  />
                  <div className="absolute right-2 bottom-2">
                    {formik.errors.email ? (
                      <>
                        <HiXMark
                          className="h-6 w-6 text-rose-600"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={formik.errors.email}
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    ) : formik.values.email !== "" ? (
                      <HiCheck className="h-6 w-6 text-emerald-700" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="relative">
                  <label
                    htmlFor="Password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Hasło
                  </label>

                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    placeholder="Hasło"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ""}
                  />
                  <div className="absolute right-2 bottom-2">
                    {formik.errors.password ? (
                      <>
                        <HiXMark
                          className="h-6 w-6 text-rose-600"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={formik.errors.password}
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    ) : (
                      formik.values.password && (
                        <HiCheck className="h-6 w-6 text-emerald-700" />
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-12 relative pb-6">
                <button
                  type="submit"
                  className="block w-full shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                >
                  {Object.values(formik.errors).length > 0 ||
                  !formik.touched.email
                    ? "Uzupełnij wszystkie pola"
                    : "Zaloguj się"}
                </button>
                <div className="text-rose-600 text-xs absolute bottom-0">
                  {errorMessage}
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
};
