import moment from "moment";
import type { Collection } from "../../types";
import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import { Button } from "../../../../components/Button";
import { validationSchema } from "../../../../schemas/Collection.schema";
import { useCreateCollection } from "../../api/useCreateCollection";
import { useUpdateCollection } from "../../api/useUpdateCollection";
import { ProductVariantContext } from "../../contexts/ProductVariantContext";
import { Modal } from "../../../../components/Modal";
import { ProductVariantForm } from "../ProductVariantForm";

type CollectionFormProps = {
  collection: Collection;
};

export const CollectionForm = ({ collection }: CollectionFormProps) => {
  const {
    setIsCreateProductVariantModalOpen,
    isCreateProductVariantModalOpen,
  } = useContext(ProductVariantContext);
  const { createCollection } = useCreateCollection();
  const { updateCollection } = useUpdateCollection();
  const isEditMode = !!collection.id;

  const handleSubmit = (values: Collection) => {
    isEditMode
      ? updateCollection(values, collection.id || 0)
      : createCollection(values);
  };
  return (
    <div className="pt-5 pl-1">
      <div className="grid grid-cols-2">
        <div className="col-span-2 mb-6">
          <h1 className="text-3xl font-semibold mb-1">
            {isEditMode ? "Edytuj dodaną kolekcje" : "Dodaj nową kolekcje"}
          </h1>
          <p className="text-gray-400">
            {!isEditMode && "Dodaj informacje, aby utworzyć nową kolekcje"}
          </p>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={collection}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ values, errors, handleChange }) => (
          <Form>
            <InputField
              label="SKU"
              name="sku"
              value={values.sku}
              onChange={handleChange}
              error={errors.sku}
              placeholder="SKU/01/2050"
            />
            <InputField
              label="Tydzień dostawy"
              name="shipping_at"
              value={moment(values.shipping_at).format("YYYY-MM-DD")}
              onChange={handleChange}
              error={errors.shipping_at}
              type="date"
            />
            {isEditMode && (
              <Button
                classes="mt-2"
                label="Dodaj wariant produktu"
                onClick={() => setIsCreateProductVariantModalOpen(true)}
              />
            )}
            <Button type="submit" label="Zapisz" classes="my-3 ml-auto" />
          </Form>
        )}
      </Formik>
      <Modal
        title="Dodaj wariant produktu"
        isModalOpen={isCreateProductVariantModalOpen}
        setIsModalOpen={() => setIsCreateProductVariantModalOpen(false)}
        size="md"
      >
        <ProductVariantForm />
      </Modal>
    </div>
  );
};
