import React, { useContext } from "react";
import { Modal } from "../../../../components/Modal";
import { useDeleteUser } from "../../api/useDeleteUser";
import { UserContext } from "../../contexts/UserContext";

type DeleteModalProps = {
  name: string;
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const DeleteUser = ({
  name,
  id,
  isModalOpen,
  setIsModalOpen,
}: DeleteModalProps) => {
  const { refetch } = useContext(UserContext);
  const { deleteUser } = useDeleteUser(refetch);
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={`Czy napewno chcesz usunąć użytkownika ${name}?`}
      buttonLabel="Usuń"
      title="Potwierdź usunięcie użytkownika"
      onConfirm={() => {
        deleteUser(id);
      }}
    />
  );
};
