import { Order } from "../../types/Order";
import moment from "moment";
import { Package } from "../../types/Package";
import autoTable from "jspdf-autotable";
import { fsmLogo } from "./fsmLogo";

const MIN_TABLE_WIDTH = 120;
const COLOR_BLACK = 0;
const TABLE_LINE_WIDTH = 0.65;
const MERGE_COLUMN_SPAN = 2;

export const printFsmLabel = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package,
  textileName: string,
  paramValue: string,
  orderIndex: string
) => {

  autoTable(doc, {
    theme: "plain",
    head: [[{ content: "        ", colSpan: MERGE_COLUMN_SPAN }]],
    tableWidth: MIN_TABLE_WIDTH,
    startY: 1,
    margin: { left: 20, bottom: 0 },
    tableLineColor: COLOR_BLACK,
    tableLineWidth: TABLE_LINE_WIDTH,
    styles: {
      lineColor: 0,
      lineWidth: 2,
      minCellHeight: 100,
    },
  });

  const product = order.product;
  const kw = moment(order.deliveryAt, "YYYYMMDD").isoWeek();
  const deliveryYear = moment(order.deliveryAt, "YYYYMMDD").year();

  // Header
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(26);
  doc.text(`${product?.name.toUpperCase()}`, 24, 35);
  doc.text(`100% Polyester`, 24, 45);



  // Footer
  doc.addImage(fsmLogo, "PNG", 108, 72, 30, 27);
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.setFontSize(16);
  doc.text(`S-No. ${order.poNumber}`, 24, 85);
  doc.text(`KW ${kw}/${deliveryYear}`, 24, 92);


  doc.addPage();
};
