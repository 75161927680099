import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";
import { storeOrder } from "../../../api";
import { ToastMessageContext } from "../../../components/ToastMessage";
import { useNavigate } from "react-router-dom";

export const useCreateOrder = (): {
  createOrder: (order: any, resetForm: () => void) => void;
} => {
  const { setIsEditSidebarOpen } = useContext(OrdersContext);
  const navigate = useNavigate();
  const { setToastMessage } = useContext(ToastMessageContext);
  const createOrder = (order: any, resetForm: () => void) => {
    storeOrder({orders: [order]})
      .then(() => {
        setIsEditSidebarOpen(false);
        setToastMessage({
          type: "success",
          message: "Dodano zamówienie",
          data: [],
        });
        setTimeout(() => {
          navigate(0);
        }, 1000);
        resetForm();
      })
      .catch((e: any) => {
        setToastMessage({
          type: "error",
          message: e.message,
          data: [],
        });
      });
  };

  return { createOrder };
};
