import { useContext } from "react";
import { storeUser } from "../../../api";
import { UserContext } from "../contexts/UserContext";

export const useCreateUser = (): {
  createUser: (user: any) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(UserContext);
  const createUser = (user: any) => {
    storeUser({ ...user, orders_types: ["form"] })
      .then(() => {
        refetch();
        setIsSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { createUser };
};
