import React from "react";
import { Layout } from "../../../layout/Layout";
import { UserTable } from "./UserTable";
import { UserContextProvider } from "../contexts/UserContext";
import { Sidebar } from "./UserSidebar/Sidebar";
import { AddUserButton } from "./UserSidebar/AddUserButton";

export const Users = () => {
  return (
    <Layout>
      <UserContextProvider>
        <Sidebar />
        <div className="flex justify-between	">
          <div>
            <h1 className="text-4xl font-semibold">Użytkownicy</h1>
            <h2 className="pt-2 text-md text-gray-500 mb-6">
              Lista dodanych użytkowników
            </h2>
          </div>
          <AddUserButton />
        </div>

        <UserTable />
      </UserContextProvider>
    </Layout>
  );
};
