import { useContext } from "react";
import { storeAddress } from "../../../api";
import { Address } from "../../../types/Address";
import { StoresContext } from "../contexts/StoresContext";

export const useCreateStore = (): {
  createStore: (address: Address) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(StoresContext);
  const createStore = (address: Address) => {
    storeAddress(address)
      .then(() => {
        refetch();
        setIsSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { createStore };
};
