import React, { useContext } from "react";
import { HiPlus } from "react-icons/hi2";
import { Button } from "../../../../components/Button";
import { OrdersContext } from "../../contexts/OrdersContext";
import { useCountPrints } from "../../api/useCountPrints";
import { useUserStore } from "../../../../stores/useUserStore";
import { OrderType } from "../../../../types";
import { ChangeOrdersStatus } from "../OrdersTable/ChangeOrdersStatus";
import { generatePdf } from "../../../../orderTemplates";
import { useTextiles } from "../../api/useTextiles";
import { useMercuryUploadStore } from "../../../../stores/useMercuryUploadStore";
import { useCollectionOrderStore } from "../../../../stores/useCollectionOrderStore";
import jsPDF from "jspdf";
import { addCustomFont } from "../../../../orderTemplates/addCustomFont";
import { UserRoleNames } from "../../../../types/User";

export const UploadOrderButton = () => {
  const {
    setIsSidebarOpen,
    selectedOrders,
    setSelectedOrders,
    setIsEditSidebarOpen,
    setIsMercuryModalOpen,
    setIsCollectionModalOpen,
  } = useContext(OrdersContext);

  const { countPrints } = useCountPrints();
  const { orderTypes } = useUserStore();
  const { textiles } = useTextiles();
  const { clearUpload } = useMercuryUploadStore();
  const { clearStorage } = useCollectionOrderStore();
  const { role } = useUserStore();

  const generatePDF = () => {
    addCustomFont();
    const doc = new jsPDF("landscape", "mm", [160, 105]);
    selectedOrders.map((templateOrders, orderIndex) => {
      switch (templateOrders.labelTemplate) {
        case "agataTemplate": {
          generatePdf.agataTemplate(templateOrders, doc);
          break;
        }
        case "mercuryTemplate": {
          generatePdf.mercuryTemplate(
            templateOrders,
            orderIndex,
            textiles,
            doc
          );
          break;
        }
        case "rollerTemplate": {
          generatePdf.rollerTemplate(templateOrders, orderIndex, textiles, doc);
          break;
        }
        case "rollerTemplateBelegnummer": {
          generatePdf.rollerTemplateBelegnummer(
            templateOrders,
            orderIndex,
            textiles,
            doc
          );
          break;
        }
        case "basic": {
          generatePdf.basic(templateOrders, orderIndex, textiles, doc);
          break;
        }
        case "ua": {
          generatePdf.ua(templateOrders, orderIndex, textiles, doc);
          break;
        }
        case "fsm": {
          generatePdf.fsm(templateOrders, orderIndex, textiles, doc);
          break;
        }
      }
    });
    doc.output("dataurlnewwindow");
    countPrints();
    setSelectedOrders([]);
  };

  const handleOpenBtn = () => setIsSidebarOpen(true);

  const handleOpenMercuryModalBtn = () => {
    clearUpload();
    setIsMercuryModalOpen(true);
  };

  const handleOpenCollectionStepper = () => {
    clearStorage();
    setIsCollectionModalOpen(true);
  };

  return (
    <div className="grid mb-6">
      <div className="col-start-2 ml-auto flex flex-row h-12">
        <ChangeOrdersStatus />
        {!role.includes(UserRoleNames.CUSTOMER) && !!selectedOrders.length && (
          <Button
            onClick={generatePDF}
            classes="bg-rose-500"
            label="Drukuj etykiety"
          />
        )}
        {orderTypes.includes(OrderType.CollectionSet) && (
          <Button
            onClick={() => handleOpenCollectionStepper()}
            icon={<HiPlus />}
            label="Import kolekcje"
            classes="ml-3"
          />
        )}
        {orderTypes.includes(OrderType.Individual) && (
          <Button
            onClick={() => setIsEditSidebarOpen(true)}
            icon={<HiPlus />}
            label="Dodaj zamówienie"
            classes="ml-3"
          />
        )}
        {orderTypes.includes(OrderType.ImportSet) && (
          <>
            <Button
              onClick={handleOpenBtn}
              classes="ml-3 bg-blue-500"
              icon={<HiPlus />}
              label="Agata Import"
            />
            <Button
              onClick={handleOpenMercuryModalBtn}
              classes="ml-3 bg-blue-500"
              icon={<HiPlus />}
              label="Mercury Import"
            />
          </>
        )}
      </div>
    </div>
  );
};
