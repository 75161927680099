import React, { InputHTMLAttributes } from "react";
import { HiCheck, HiXMark } from "react-icons/hi2";
import { Tooltip } from "react-tooltip";

type TextareaFieldProps = {
  label: string;
  error?: string;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const TextareaField = ({
  label,
  error,
  ...props
}: TextareaFieldProps) => {
  return (
    <>
      <label
        htmlFor="description"
        className="block text-xs font-medium text-gray-400 pb-2 pt-4"
      >
        {label}
      </label>
      <div className="relative">
        <textarea
          {...props}
          className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600"
        />
        <div className="absolute right-2 bottom-2">
          {!props.disabled ? (
            error ? (
              <>
                <HiXMark
                  className="h-6 w-6 text-rose-600"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={error}
                />
                <Tooltip id="my-tooltip" />
              </>
            ) : (
              <HiCheck className="h-6 w-6 text-emerald-700" />
            )
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  );
};
