import { array, number, object, string } from "yup";

const packageRow = object().shape({
  width: number().required("Pole wymagane").min(0, "Podaj wartość"),
  height: number().required("Pole wymagane").min(0, "Podaj wartość"),
  depth: number().required("Pole wymagane").min(0, "Podaj wartość"),
  weight: number().required("Pole wymagane").min(0, "Podaj wartość"),
});

export const validationSchema = object().shape({
  name: string().max(100).required("Pole wymagane"),
  sku: string().max(100).required("Pole wymagane"),
  textile_qty: number().max(100).required("Pole wymagane"),
  image: array().required("Pole wymagane"),
  categoryId: number().required("Pole wymagane"),
  packages: array().of(packageRow).min(1, "Minimum jedna paczka"),
  production_minimum: number(),
});
