import React, { useContext, useState } from "react";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";
import { User } from "../../../../types";
import { DeleteUser } from "../DeleteModal/DeleteUser";
import { UserContext } from "../../contexts/UserContext";

type UserRowProps = {
  user: User;
};

export const UserRow = ({ user }: UserRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setEditUser, setIsSidebarOpen } = useContext(UserContext);
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenEditSidebar = () => {
    setEditUser(user);
    setIsSidebarOpen(true);
  };

  return (
    <div className="h-14 rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-10 gap-2 leading-8 text-gray-400">
      <div className="col-span-3  my-auto">
        <span className="font-medium text-gray-900">{user.name}</span>
      </div>
      <div className="col-span-4 my-auto">{user.email}</div>
      <div className="col-span-1 my-auto">
        {user.roles?.map((role, index) => (
          <span className="mx-2" key={`${role}-${index}`}>
            {role.name}
          </span>
        ))}
      </div>
      <div className="col-span-2 my-auto hidden group-hover:flex justify-end items-end">
        <HiOutlinePencil
          className="h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleOpenEditSidebar}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer mt-1 hover:bg-gray-100 hover:rounded-lg"
          onClick={handleModal}
        />
      </div>
      {user.id && (
        <DeleteUser
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          name={user.name}
          id={user.id}
        />
      )}
    </div>
  );
};
