import React, { createContext, ReactNode, useState } from "react";
import { Address } from "../../../types/Address";
import { useAddresses } from "../api/useAddresses";

export const INITIAL_ADDRESS: Address = {
  name: "",
  description: "",
  code: "",
};
export const StoresContext = createContext<{
  addresses: Address[];
  refetch: () => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  editAddress: Address;
  setEditAddress: (address: Address) => void;
}>({
  addresses: [],
  refetch: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: (isSidebarOpen) => {},
  editAddress: INITIAL_ADDRESS,
  setEditAddress: (address) => {},
});

type StoresContextProviderProps = {
  children: ReactNode;
};

export const StoresContextProvider = ({
  children,
}: StoresContextProviderProps) => {
  const { addresses, fetch } = useAddresses();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [editAddress, setEditAddress] = useState<Address>(INITIAL_ADDRESS);

  return (
    <StoresContext.Provider
      value={{
        addresses: addresses,
        refetch: fetch,
        isSidebarOpen,
        setIsSidebarOpen,
        editAddress,
        setEditAddress,
      }}
    >
      {children}
    </StoresContext.Provider>
  );
};
