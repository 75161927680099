import React, { ReactNode } from "react";

type PageHeaderProps = {
  title: string;
  subtitle: string | ReactNode;
};

export const PageHeader = ({ title, subtitle }: PageHeaderProps) => (
  <div className="flex justify-between">
    <div>
      <h1 className="text-4xl font-semibold">{title}</h1>
      <h2 className="pt-2 text-md text-gray-500 mb-6">{subtitle}</h2>
    </div>
  </div>
);
