import { useState } from "react";
import { getUser } from "../../../api";
import { User } from "../../../types";

export const useUser = () => {
  const [user, setUser] = useState<User>();
  const fetch = (id: number) =>
    getUser(id)
      .then((res: User) => {
        setUser(res);
      })
      .catch((e: any) => console.error(e));

  return { user, fetch };
};
