import { useContext } from "react";
import { storeCollection } from "../../../api";
import { Collection } from "../types";
import { CollectionContext } from "../contexts/CollectionContext";

export const useCreateCollection = (): {
  createCollection: (collection: Collection) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(CollectionContext);
  const createCollection = (collection: Collection) => {
    storeCollection(collection)
      .then(() => {
        refetch();
        setIsSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { createCollection };
};
