import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Button } from "../../components/Button";
import { HiPlus } from "react-icons/hi2";
import {
  deleteCategory,
  getCategories,
  storeCategory,
  updateCategory,
} from "../../api";
import { Sidebar } from "./Sidebar";
import { CategoryRow } from "./CategoryRow";
import { useFormik } from "formik";
import { validationSchema } from "../../schemas/Category.schema";
import { Category as CategoryType } from "../../types/Category";
import { Modal } from "../../components/Modal";
import { ModalObject } from "../../types/ModalObject";

export const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingSidebarOpen, setIsEditingSidebarOpen] = useState(false);
  const [modalObject, setModalObject] = useState<ModalObject>();

  useEffect(() => {
    (async () => {
      const categories = await getCategories();
      setLoading(false);
      setCategories(categories);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isEditingSidebarOpen) {
          await updateCategory(values);
        } else {
          await storeCategory(values);
        }
        const updatedCategories = await getCategories();
        setCategories(updatedCategories);
        setIsSidebarOpen(false);
        setIsEditingSidebarOpen(false);
        resetForm();
      } catch (error) {
        console.log("API error");
      }
    },
    validationSchema,
  });

  const handleDelete = async (id: number) => {
    await deleteCategory(id);
    const updatedCategories = await getCategories();
    setCategories(updatedCategories);
  };

  if (loading) {
    return <Layout>Wczytywanie...</Layout>;
  }

  const handleShowSidebar = () => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(false);
    formik.setFieldValue("name", "");
    formik.setFieldValue("categoryId", categories[0].id);
  };

  const handleEditInSidebar = (category: CategoryType) => {
    setIsSidebarOpen(true);
    setIsEditingSidebarOpen(true);
    formik.setFieldValue("name", category.name);
    formik.setFieldValue("categoryId", category.id);
  };

  return (
    <Layout>
      <Sidebar
        formik={formik}
        isSidebarOpen={isSidebarOpen}
        isEditingSidebarOpen={isEditingSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setIsEditingSidebarOpen={setIsEditingSidebarOpen}
      />
      <h1 className="text-4xl font-semibold">Kategorie</h1>
      <h2 className="pt-2 text-md text-gray-500 mb-6">
        Lista dodanych kategorii
      </h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="col-start-2 ml-auto">
          <Button
            onClick={handleShowSidebar}
            icon={<HiPlus />}
            label="Dodaj kategorię"
          />
        </div>
      </div>
      <div className="px-4 mb-2">
        <span className="block text-xs font-medium text-gray-400">Nazwa</span>
      </div>
      <div className="mb-6">
        {categories &&
          categories.map((category) => (
            <CategoryRow
              key={category.id}
              category={category}
              handleClick={handleEditInSidebar}
              setIsModalOpen={setIsModalOpen}
              setModalObject={setModalObject}
            />
          ))}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Potwierdź usunięcie kategorii"
        content={`Czy napewno chcesz usunąć kategorię ${
          modalObject?.name || ""
        }?`}
        buttonLabel="Usuń"
        onConfirm={() => {
          if (modalObject !== undefined) {
            handleDelete(modalObject.id);
          }
        }}
      />
    </Layout>
  );
};
