import { useState } from "react";
import { getProduct } from "../../../api";

export const useParamOptions = () => {
  const [paramOptions, setParamOptions] = useState<
    { label: string; value: number; options: string | string[]; type: string }[]
  >([]);
  const [textileOptions, setTextileOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [textFieldsParamsOptions, setTextFieldParamsOptions] = useState<any[]>(
    []
  );

  const fetch = (id: number) =>
    getProduct(id)
      .then((res) => {
        setParamOptions(
          res.params.map(({ id, name, options, type }) => ({
            label: name,
            value: id,
            options,
            type,
          }))
        );
        setTextileOptions(
          res.textiles.map(({ id, name }) => ({ label: name, value: id }))
        );
        const textFieldsParams = res.params
          .filter((param) => param.type === "input")
          .map((param) => ({ value: param.options, id: param.id }));
        setTextFieldParamsOptions(textFieldsParams);
      })
      .catch((e) => console.error(e));

  return { paramOptions, textFieldsParamsOptions, textileOptions, fetch };
};
