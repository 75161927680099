import {
  FONT_BOLD,
  FONT_REGULAR,
} from "../pages/orders/components/OrderForm/utils";
import jsPDF from "jspdf";

export const addCustomFont = () => {
  (function (jsPDFAPI) {
    const callAddFont = function (this: any) {
      this.addFileToVFS("IBMPlexSans-Bold.ttf", FONT_BOLD);
      this.addFileToVFS("IBMPlexSans-Regular.ttf", FONT_REGULAR);
      this.addFont("IBMPlexSans-Regular.ttf", "IBMPlexSans-Regular", "normal");
      this.addFont("IBMPlexSans-Bold.ttf", "IBMPlexSans-Bold", "bold");
    };
    jsPDFAPI.events.push(["addFonts", callAddFont]);
  })(jsPDF.API);
};
