import React from "react";
import { Layout } from "../../layout/Layout";

export const Dashboard = () => {
  return (
    <Layout>
      <h1 className="text-6xl font-semibold">Cześć!</h1>
      <h2 className="pt-2 text-xl text-gray-500">Oto Twój dashboard</h2>
    </Layout>
  );
};
