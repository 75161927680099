import React from "react";
import { Param } from "../../types/Param";
import { HiOutlineArchiveBoxXMark, HiOutlinePencil } from "react-icons/hi2";

type ParamRowProps = {
  param: Param;
  handleEdit: (param: Param) => void;
  showDeleteModal: (param: Param) => void;
};

export const ParamRow = ({
  param,
  handleEdit,
  showDeleteModal,
}: ParamRowProps) => {
  return (
    <div className="rounded-lg bg-gray-100 hover:bg-white group border-2 border-gray-100 px-4 mb-2 grid grid-flow-col grid-cols-8 gap-2 leading-8 text-gray-400">
      <div className="col-span-2 py-2">
        <span className="font-medium text-gray-900">{param.name}</span>
      </div>
      <div className="col-span-7 py-2  hidden group-hover:flex justify-end items-end">
        <HiOutlinePencil
          className=" h-8 w-8 p-1 bg-white text-xl text-amber-400 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
          onClick={() => handleEdit(param)}
        />
        <HiOutlineArchiveBoxXMark
          className="h-8 w-8 p-1 bg-white text-xl text-rose-600 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
          onClick={() => showDeleteModal(param)}
        />
      </div>
    </div>
  );
};
