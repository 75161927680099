import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Layout } from "../../layout/Layout";
import { Product as ProductType } from "../../types/Product";
import { getProduct } from "../../api";
import { RouteType } from "../../types/RouteType";

export const Product = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<RouteType>();
  const [product, setProduct] = useState<ProductType>();

  if (!id) return <Navigate replace to="products" />;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      const product = await getProduct(parseInt(id));
      setLoading(false);
      setProduct(product);
    })();
  }, [id]);

  if (loading || !product) {
    return <Layout>Wczytywanie...</Layout>;
  }

  return (
    <Layout>
      <div className="grid grid-flow-col auto-cols-max">
        <div className="mr-12 bg-gray-100 rounded-full w-64 h-64 overflow-hidden">
          <img
            src={`${process.env.REACT_APP_API_URL}//storage/images/products/${product.image}`}
            alt={product.name}
            className="w-full mr-2 mix-blend-multiply"
          />
        </div>
        <div>
          <h3 className="pt-2 text-xs font-medium text-gray-400 mb-12">
            {product.category.name}
          </h3>
          <h1 className="text-4xl font-semibold">{product.name}</h1>
          <h2 className="pt-2 text-md text-gray-500 mb-6">{product.sku}</h2>
          <div className="bg-gray-100 rounded-lg grid grid-cols-2 divide-x-2 divide-white mb-4">
            <div className="col-span-1 px-4 pt-2 pb-3">
              <div className="grid grid-flow-col auto-cols-max">
                <div>
                  <span className="text-xs font-medium text-gray-400">
                    Paczek:
                  </span>
                  <p className="font-medium">{product.packages.length} szt.</p>
                </div>
              </div>
            </div>
            <div className="col-span-1 px-4 pt-2 pb-3">
              <div className="grid grid-flow-col auto-cols-max">
                <div>
                  <span className="text-xs font-medium text-gray-400">
                    Zużycie tkaniny:
                  </span>
                  <p className="font-medium">{product.textileQty} mb.</p>
                </div>
              </div>
            </div>
          </div>
          <h4 className="text-xl font-semibold pt-4 pb-6">
            Dostępny w tkaninach:
          </h4>
          <div className="grid grid-cols-3">
            {product.textiles &&
              product.textiles.map((textile) => (
                <div className="col-span-1 mr-4">
                  <div className="grid grid-flow-col auto-cols-max mb-2">
                    <div>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/storage/images/textiles/${textile.image}`}
                        alt={textile.name}
                        className="w-8 rounded-full mr-2 h-8 object-cover"
                      />
                    </div>
                    <div className="pt-1">{textile.name}</div>
                  </div>
                </div>
              ))}
          </div>
          <h4 className="text-xl font-semibold pt-4 pb-6">
            Dostępny w parametrach:
          </h4>
          <div className="grid grid-cols-3">
            {product.params &&
              product.params.map((param) => (
                <div className="col-span-1 mr-4">
                  <div className="grid grid-flow-col auto-cols-max mb-2">
                    <div className="pt-1">{param.name}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
