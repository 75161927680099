import React, { useContext } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { UserForm } from "./UserForm";
import { UserContext } from "../../contexts/UserContext";
import { USER_MOCK } from "../../../../mocks/User.mock";

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen, setEditUser, editUser } =
    useContext(UserContext);

  const handleCloseBtn = () => {
    setEditUser(USER_MOCK);
    setIsSidebarOpen(false);
  };
  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div className="p-10 pl-9">
          <div>
            <HiOutlineXMark
              className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
              onClick={handleCloseBtn}
            />
          </div>
          <UserForm user={editUser} />
        </div>
      </div>
    </div>
  );
};
