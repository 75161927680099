import { useEffect, useState } from "react";
import { getStatuses } from "../../../api";

import { Status } from "../../../types";

export const useStatuses = () => {
  const [statuses, setStatuses] = useState<Status[]>();

  useEffect(() => {
    fetch();
  }, []);
  const fetch = () =>
    getStatuses()
      .then((res: Status[]) => {
        setStatuses(res);
      })
      .catch((e: any) => console.error(e));

  return { statuses, fetch };
};
