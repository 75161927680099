import { useEffect, useState } from "react";
import { getUsers } from "../../../api";
import { User } from "../../../types";
import { UserRoleNames } from "../../../types/User";
import { useUsersStore } from "../../../stores/useUsersStore";

export const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [admins, setAdmins] = useState<User[]>([]);
  const [customers, setCustomers] = useState<User[]>([]);
  const fetch = () => {
    getUsers(UserRoleNames.ADMIN)
      .then((res) =>
        setAdmins(res.map((user: any) => ({ ...user, role: "admin" })))
      )
      .catch((e) => console.error(e));
    getUsers(UserRoleNames.CUSTOMER)
      .then((res) =>
        setCustomers(res.map((user: any) => ({ ...user, role: "customer" })))
      )
      .catch((e) => console.error(e));
    getUsers(UserRoleNames.WORKER)
      .then((res) =>
        setUsers(res.map((user: any) => ({ ...user, role: "worker" })))
      )
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    useUsersStore.setState({ users: [...users, ...admins, ...customers] });
  }, [users.length, admins.length, customers.length]);

  return { users: [...users, ...admins, ...customers], refetch: fetch };
};
