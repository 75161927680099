import React from "react";
import { useUserStore } from "../../../../stores/useUserStore";
import { UserRoleNames } from "../../../../types/User";

export const OrdersTableHeaderRow = () => {
  const { role } = useUserStore();

  return (
    <div
      className={`px-4 mb-2 grid grid-cols-${
        !role.includes(UserRoleNames.CUSTOMER) ? 10 : 6
      } gap-1`}
    >
      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-1">
          <span className="block text-xs font-medium text-gray-400 pb-2"></span>
        </div>
      )}
      <div className="col-span-2">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Numer PO
        </span>
      </div>
      <div className="col-span-1">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Dodano
        </span>
      </div>

      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-2">
          <span className="block text-xs font-medium text-gray-400 pb-2">
            KW dostawy
          </span>
        </div>
      )}
      <div className="col-span-1">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Ilość
        </span>
      </div>

      <div className="col-span-1">
        <span className="block text-xs font-medium text-gray-400 pb-2">
          Status
        </span>
      </div>

      {!role.includes(UserRoleNames.CUSTOMER) && (
        <div className="col-span-1">
          <span className="block text-xs font-medium text-gray-400 pb-2">
            Wydruki
          </span>
        </div>
      )}
    </div>
  );
};
