import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import React, { useEffect, useState } from "react";
import { useOrders } from "../../api/useOrders";
import { useUser } from "../../api/useUser";
import { TextareaField } from "../../../../components/TextareaField";
import moment from "moment";
import { useTextiles } from "../../api/useTextiles";
import { useProduct } from "../../api/useProduct";
import { useCollection } from "../../../collections/api/useCollection";
import { Textile } from "../../../../types/Textile";
import { useParams } from "../../api/useParams";
import { useOrderTypes } from "../../api/useOrderTypes";
import { PageHeader } from "../../../../components/PageHeader";
import { StatusBadge } from "../OrdersTable/StatusBadge";
import { Button } from "../../../../components/Button";
import { StatusModal } from "./StatusModal";
import { StatusForm } from "./StatusForm";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../../stores/useUserStore";
import { UserRoleNames } from "../../../../types/User";

type OrderDetailFormProps = {
  id: number;
};
export const OrderDetailForm = ({ id }: OrderDetailFormProps) => {
  const { refetch, orders } = useOrders();
  const { fetch, user } = useUser();
  const { textiles } = useTextiles();
  const { product, productFetch } = useProduct();
  const { collection, fetch: fetchCollection } = useCollection();
  const [textile, setTextile] = useState<Textile>();
  const { params } = useParams();
  const { orderTypes } = useOrderTypes();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { role } = useUserStore();

  useEffect(() => {
    refetch();
  }, []);
  const order = orders.find((order) => order.id === id);

  useEffect(() => {
    order && fetch(order?.userId);
  }, [order?.userId]);

  useEffect(() => {
    order && productFetch(order.productId);
  }, [order?.productId]);

  useEffect(() => {
    order && fetchCollection(order.collectionId);
  }, [order?.collectionId]);

  useEffect(() => {
    order && setTextile(textiles.find((item) => item.id === 1));
  }, [order?.textileId]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="max-w-4xl">
      {order && (
        <>
          <div className="flex justify-between">
            <PageHeader
              title={`Zamówienie ${order.poNumber}`}
              subtitle={
                <div className={"ml-4"}>
                  {!role.includes(UserRoleNames.CUSTOMER) && (
                    <StatusBadge id={order.statusId} />
                  )}
                </div>
              }
            />
            <Button
              label={"Zmień status"}
              classes="h-12"
              onClick={handleOpenModal}
            />
            <StatusModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            >
              <StatusForm
                orderId={order.id}
                orderStatusId={order.statusId}
                closeModal={() => {
                  setIsModalOpen(false);
                  setTimeout(() => {
                    navigate(0);
                  }, 500);
                }}
              />
            </StatusModal>
          </div>

          <Formik
            enableReinitialize
            initialValues={{
              ...order,
              user: user?.name,
              product,
              collection,
              textile,
            }}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <Form>
                {values.poNumber && (
                  <InputField
                    label="Numer zamówienia klienta"
                    name="po_number"
                    value={values.poNumber}
                    disabled
                  />
                )}
                {values.zdNumber && (
                  <InputField
                    label="Numer ZD"
                    name="zdNumber"
                    value={values.zdNumber}
                    disabled
                  />
                )}
                {values.productDescription && (
                  <TextareaField
                    label="Opis produktu"
                    value={values.productDescription}
                    disabled
                  />
                )}
                <div className="grid grid-cols-2 gap-1">
                  <InputField label="Użytkownik" value={values.user} disabled />

                  {values.quantity && (
                    <InputField
                      label="Ilość"
                      value={values.quantity}
                      disabled
                      type="number"
                    />
                  )}
                  {values.unit && (
                    <InputField
                      label="Jednostka"
                      value={values.unit}
                      disabled
                    />
                  )}

                  <InputField
                    label="Liczba wydruków"
                    value={values.countPrints}
                    disabled
                  />

                  {values.createdAt && (
                    <InputField
                      label="Data utworzenia"
                      value={moment(values.createdAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}
                  {values.confirmedAt && (
                    <InputField
                      label="Data potwiedzenia"
                      value={moment(values.confirmedAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}
                  {values.deliveryAt && (
                    <InputField
                      label="Data dostawy"
                      value={moment(values.deliveryAt).format("DD-MM-YYYY")}
                      disabled
                    />
                  )}
                  {values.orderTypeId && (
                    <InputField
                      label="Typ zamówienia"
                      value={
                        orderTypes.find(({ id }) => id === values.orderTypeId)
                          ?.name
                      }
                      disabled
                    />
                  )}

                  {values.buyerCode && (
                    <InputField
                      label="Kod kupującego"
                      value={values.buyerCode}
                      disabled
                    />
                  )}
                  {values.buyerAddress && (
                    <InputField
                      label="Miasto kupującego"
                      value={values.buyerAddress}
                      disabled
                    />
                  )}
                  {values.batchNumber && (
                    <InputField
                      label="Numer batch"
                      value={values.batchNumber}
                      disabled
                    />
                  )}
                  {values.collectionId && (
                    <InputField
                      label="Kolekcja"
                      value={values.collection?.sku}
                      disabled
                    />
                  )}
                </div>
                <div className="grid grid-cols-2 gap-1">
                  {values.params &&
                    Object.entries(values.params).map(([key, value]) => {
                      return (
                        <InputField
                          key={key}
                          label={
                            params
                              .find((item) => item.id.toString() === key)
                              ?.name.toString() || ""
                          }
                          value={(value as string) || ""}
                          disabled
                        />
                      );
                    })}
                </div>
                <div className="grid grid-cols-2 gap-1">
                  {values.productId && (
                    <div className="col-span-1">
                      <InputField
                        label="Produkt"
                        value={product?.name}
                        disabled
                      />

                      <img
                        src={`${process.env.REACT_APP_API_URL}/storage/images/products/${product?.image}`}
                        alt={product?.name}
                        className="rounded-lg w-full mt-2"
                      />
                    </div>
                  )}
                  {values.textileId && (
                    <div className="col-span-1 mr-2">
                      <InputField
                        label="Tkanina"
                        value={values.textile?.name}
                        disabled
                      />
                      <img
                        src={`${process.env.REACT_APP_API_URL}/storage/images/textiles/${textile?.image}`}
                        alt={product?.name}
                        className="rounded-lg w-full mt-2"
                      />
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
