import { useContext } from "react";
import { updateAddress } from "../../../api";
import { Address } from "../../../types/Address";
import { StoresContext } from "../contexts/StoresContext";

export const useUpdateStore = (): {
  updateStore: (store: Address, id: number) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(StoresContext);
  const updateStore = (store: Address, id: number) => {
    updateAddress({ ...store, id })
      .then(() => {
        refetch();
        setIsSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { updateStore };
};
