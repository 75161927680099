import React, { useContext, useEffect } from "react";
import { HiOutlineXMark } from "react-icons/hi2";

import { OrdersContext } from "../../contexts/OrdersContext";
import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import { Button } from "../../../../components/Button";
import Select from "react-select";
import { useUserOption } from "../../api/useUserOptions";
import { useUploadOrder } from "../../api/useUploadOrder";
import { useUserStore } from "../../../../stores/useUserStore";
import { useUser } from "../../api/useUser";
import { UserRoleNames } from "../../../../types/User";

export const AgataOrderForm = () => {
  const formData = new FormData();

  const { createOrder } = useUploadOrder();

  const { isSidebarOpen, setIsSidebarOpen } = useContext(OrdersContext);
  const { id } = useUserStore();
  const { user: detailUser, fetch: fetchUser } = useUser();
  useEffect(() => {
    id && fetchUser(id);
  }, [id]);
  const { userOptions } = useUserOption();
  const handleCloseBtn = () => {
    setIsSidebarOpen(false);
  };
  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div className="p-10 pl-9">
          <div>
            <HiOutlineXMark
              className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
              onClick={handleCloseBtn}
            />
          </div>
          <div className="pt-5 pl-1">
            <div className="grid grid-cols-2">
              <div className="col-span-2 mb-6">
                <h1 className="text-3xl font-semibold mb-1">
                  Importuj zamówienia Agata
                </h1>
                <p className="text-gray-400">
                  Wgraj plik XLSX z systemu Agata oraz wybierz dla jakiego
                  użytkownika chcesz dodać nowe zamówienia
                </p>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                file: undefined,
                user: userOptions.find((user) => user.value === id) || {
                  value: 0,
                  label: "",
                },
              }}
              onSubmit={(values, { resetForm }) => {
                formData.append("user_id", values.user.value.toString() || "");
                createOrder(formData);
                resetForm();
              }}
            >
              {({ values, errors, setFieldValue }) => (
                <Form>
                  <InputField
                    label="Plik"
                    name="file"
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        formData.append(
                          "order",
                          event.target.files[0],
                          event.target.files[0].name
                        );

                        setFieldValue("file", formData);
                      }
                    }}
                    type="file"
                    error={errors.file}
                    accept=".xlsx, .xls"
                  />
                  <div>
                    <label
                      htmlFor="orderType"
                      className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                    >
                      Użytkownik
                    </label>
                    <Select
                      name="user"
                      onChange={(option: any) => {
                        setFieldValue("user", option);
                      }}
                      options={userOptions}
                      value={values.user}
                      placeholder="Jan"
                      isDisabled={detailUser?.roles
                        ?.map((role) => role.name)
                        .includes(UserRoleNames.CUSTOMER)}
                    />
                  </div>
                  <Button type="submit" label="Zapisz" classes="my-3 ml-auto" />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
