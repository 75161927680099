import { mixed, number, object, string } from "yup";

export const generateValidationSchema = (minQuantity = 1, isTextiles = false) =>
  object().shape({
    po_number: string().required("Pole wymagane"),
    product_id: mixed().required("Pole wymagane"),
    textile_id: isTextiles ? mixed().required("Pole wymagane") : mixed(),
    quantity: number()
      .required("Pole wymagane")
      .min(minQuantity, `Minimalna ilość przy zamowieniu to ${minQuantity}`),
  });
