import { useState } from "react";
import { getUserTextile } from "../../../api";

export type UserTextile = {
  textile_id: number;
  price_group: number;
};

export const useUserTextiles = () => {
  const [userTextiles, setUserTextiles] = useState<UserTextile[]>([]);
  const fetch = (id: number) =>
    getUserTextile(id)
      .then((res) => {
        setUserTextiles(
          res.map((item: any) => ({
            textile_id: item.id,
            price_group: item.priceGroup,
          }))
        );
      })
      .catch((e) => console.error(e));

  return { fetch, userTextiles };
};
