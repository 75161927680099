import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";
import { storeOrder } from "../../../api";
import { ToastMessageContext } from "../../../components/ToastMessage";
import { useNavigate } from "react-router-dom";

export const useCreateOrders = (): {
  createOrders: (orders: any) => void;
} => {
  const navigate = useNavigate();
  const { setToastMessage } = useContext(ToastMessageContext);
  const createOrders = (orders: any) => {
    storeOrder({ orders })
      .then(() => {
        setToastMessage({
          type: "success",
          message: "Dodano zamówienia",
          data: [],
        });
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch((e: any) => {
        setToastMessage({
          type: "error",
          message: e.message,
          data: [],
        });
      });
  };

  return { createOrders };
};
