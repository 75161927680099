import React, { Dispatch, Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineXMark } from "react-icons/hi2";
import { Button } from "./Button";

type ModalSizeType = "lg" | "md" | "basic";

type ModalType = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<boolean>;
  handleClose?: () => void;
  title: string;
  content?: string | ReactNode;
  buttonLabel?: string;
  onConfirm?: any;
  children?: ReactNode;
  size?: ModalSizeType;
  isMedium?: boolean;
};

const ModalSize: Record<ModalSizeType, string> = {
  basic: "inherit",
  lg: "1500px",
  md: "1000px",
};

export const Modal = ({
  isModalOpen,
  setIsModalOpen,
  handleClose,
  title,
  content,
  buttonLabel,
  onConfirm,
  children,
  size = "basic",
}: ModalType) => {
  const handleConfirm = () => {
    onConfirm();
    setIsModalOpen(false);
  };
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setIsModalOpen(false);
          handleClose && handleClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div style={{ width: ModalSize[size] }}>
                <Dialog.Panel className="min-w-lg  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  {content && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{content}</p>
                    </div>
                  )}

                  {children && <Dialog.Panel>{children}</Dialog.Panel>}

                  {buttonLabel && (
                    <div className="mt-4">
                      <Button
                        onClick={handleConfirm}
                        icon={<HiOutlineXMark />}
                        label={buttonLabel}
                      />
                    </div>
                  )}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
