import { useEffect, useState } from "react";
import { getProducts } from "../../../api";
import { useProductOptionsStore } from "../../../stores/useProductOptionsStore";

export const useProductOptions = () => {
  const [products, setProducts] = useState<{ label: string; value: number }[]>(
    []
  );
  const fetch = () =>
    getProducts()
      .then((res) => {
        const options = res.map(({ id, name }) => ({ label: name, value: id }));
        setProducts(options);
        useProductOptionsStore.setState({ productOptions: options });
      })
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { productOptions: products, refetch: fetch };
};
