import React from "react";
import { useStatusOptionsStore } from "../../../../stores/useStatusOptionsStore";

type StatusBadgeProps = {
  id: number;
};
export const StatusBadge = ({ id }: StatusBadgeProps) => {
  const { options } = useStatusOptionsStore();

  const status = options?.find((option) => option.value === id);

  return (
    <div className="col-span-1 py-2">
      <span
        style={{ backgroundColor: status?.color, marginLeft: "-16px" }}
        className={`text-white text-xs mr-2  px-3 py-1 rounded-full whitespace-nowrap uppercase`}
      >
        {status?.label}
      </span>
    </div>
  );
};
