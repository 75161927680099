import React, { useContext } from "react";
import { HiPlus } from "react-icons/hi2";
import { StoresContext } from "../contexts/StoresContext";
import { Button } from "../../../components/Button";

export const AddStoreButton = () => {
  const { setIsSidebarOpen } = useContext(StoresContext);

  const handleOpenBtn = () => setIsSidebarOpen(true);

  return (
    <div className="grid grid-cols-2 gap-4 mb-6">
      <div className="col-start-2 ml-auto">
        <Button onClick={handleOpenBtn} icon={<HiPlus />} label="Dodaj sklep" />
      </div>
    </div>
  );
};
