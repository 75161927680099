import { useEffect, useState } from "react";
import { getPriceGroups } from "../../../api";

export const usePriceGroupOptions = () => {
  const [priceGroupOptions, setPriceGroupOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const fetch = () =>
    getPriceGroups()
      .then((res) =>
        setPriceGroupOptions(
          res.map(({ id, name }) => ({ label: name, value: id }))
        )
      )
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { priceGroupOptions, refetch: fetch };
};
