import React, { ReactNode } from "react";
import { HiOutlineXMark } from "react-icons/hi2";

type FormHeaderProps = {
  children: ReactNode;
  handleCloseBtn: () => void;
  title: string;
  subtitle: string;
};

export const FormWrapper = ({
  children,
  handleCloseBtn,
  title,
  subtitle,
}: FormHeaderProps) => {
  return (
    <div className="p-10 pl-9">
      <div>
        <HiOutlineXMark
          className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
          onClick={handleCloseBtn}
        />
      </div>
      <div className="pt-5 pl-1">
        <div className="grid grid-cols-2">
          <div className="col-span-2 mb-6">
            <h1 className="text-3xl font-semibold mb-1">{title}</h1>
            <p className="text-gray-400">{subtitle}</p>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
