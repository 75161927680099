import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { User } from "../types";

type UsersStore = {
  clearStorage(): void;
  users: User[];
};

const getInitialState = (
  set: SetState<UsersStore>,
  get: GetState<UsersStore>
): UsersStore => ({
  users: [],
  clearStorage: () => {
    set({ users: [] });
  },
});

export const useUsersStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "users",
  })
);
