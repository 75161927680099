import { FieldArray, Form, FormikProps, FormikProvider } from "formik";
import React from "react";
import { MercuryOrder } from "../../../../types";
import { MercuryOrdersFormik } from "./types";
import { ValidationFormRow } from "./ValidationFormRow";

type MercuryValidationFormProps = {
  formik: FormikProps<MercuryOrdersFormik>;
};
export const MercuryValidationForm = ({
  formik,
}: MercuryValidationFormProps) => {
  return (
    <div className="pt-4">
      <FormikProvider value={formik}>
        <Form>
          <FieldArray
            name="orders"
            render={(arrayHelpers: any) => {
              return (
                <div>
                  {formik.values.orders.map(
                    (order: MercuryOrder, index: number) => (
                      <ValidationFormRow
                        formik={formik}
                        index={index}
                        arrayHelpers={arrayHelpers}
                        order={order}
                        key={order.productFullName}
                      />
                    )
                  )}
                </div>
              );
            }}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};
