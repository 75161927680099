import React, { createContext, ReactNode, useState } from "react";
import { useCollections } from "../api/useCollections";
import { Collection } from "../types";
import moment from "moment";

export const CollectionContext = createContext<{
  collections: Collection[];
  refetch: () => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  editCollection: Collection;
  setEditCollection: (collection: Collection) => void;
}>({
  collections: [],
  refetch: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: (isSidebarOpen) => {},
  editCollection: { sku: "", shipping_at: moment().format("YYYY-MM-DD") },
  setEditCollection: (collection) => {},
});

type CollectionContextProviderProps = {
  children: ReactNode;
};

export const CollectionContextProvider = ({
  children,
}: CollectionContextProviderProps) => {
  const collections = useCollections();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [editCollection, setEditCollection] = useState<Collection>({
    sku: "",
    shipping_at: moment().format("YYYY-MM-DD"),
  });

  return (
    <CollectionContext.Provider
      value={{
        collections: collections?.collections,
        refetch: collections?.refetch,
        isSidebarOpen,
        setIsSidebarOpen,
        editCollection,
        setEditCollection,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
