import React, { Dispatch } from "react";
import { HiCheck, HiOutlineXMark, HiXMark } from "react-icons/hi2";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "../../components/Button";
import { Tooltip } from "react-tooltip";
import { DropdownList } from "../../components/DropdownList";
import { validationSchema } from "../../schemas/Param.schema";
import { getParams, storeParam, updateParam } from "../../api";
import { Param, ParamType, ParamTypes } from "../../types/Param";

type SidebarType = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<boolean>;
  inputTypes: Array<any>;
  selectedParam?: Param;
  setParams: (params: Param[]) => void;
  setSelectedParam: (param: Param) => void;
  isEditingSidebarOpen: boolean;
  setIsEditingSidebarOpen: Dispatch<boolean>;
  hasBeenTouched: boolean;
  setHasBeenTouched: Dispatch<boolean>;
};

export function Sidebar({
  isSidebarOpen,
  setIsSidebarOpen,
  setSelectedParam,
  inputTypes,
  setParams,
  selectedParam,
  isEditingSidebarOpen,
  setIsEditingSidebarOpen,
  hasBeenTouched,
  setHasBeenTouched,
}: SidebarType) {
  const handleCloseBtn = () => {
    setIsSidebarOpen(false);

    setTimeout(() => {
      setIsEditingSidebarOpen(false);
      setSelectedParam({ name: "", type: "select", options: [], id: 0 });
    }, 1000);
  };

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    try {
      if (isEditingSidebarOpen) {
        if (values.type === 0) {
          values.options = [];
        }
        const payload = { ...values, id: selectedParam?.id };
        await updateParam(payload);
      } else {
        await storeParam(values);
      }
      const updatedParams = await getParams();
      setParams(updatedParams);
      setIsSidebarOpen(false);
      setTimeout(() => {
        setHasBeenTouched(false);
        resetForm();
      }, 1000);
    } catch (error) {
      console.log("API error.");
    }
  };

  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: selectedParam?.name || "",
            type: selectedParam?.type || "input",
            options: selectedParam?.options || ([] as string[]),
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={({
            values,
            setFieldValue,
            handleChange,
            errors,
            resetForm,
          }) => (
            <Form>
              <div className="p-10 pl-9">
                <div>
                  <HiOutlineXMark
                    className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
                    onClick={() => {
                      handleCloseBtn();
                      resetForm();
                    }}
                  />
                </div>
                <div className="pt-5 pl-1">
                  <div className="mb-6">
                    <h1 className="text-3xl font-semibold mb-1">
                      {isEditingSidebarOpen
                        ? "Edytuj parametr"
                        : "Dodaj nowy parametr"}
                    </h1>
                    <p className="text-gray-400">
                      {!isEditingSidebarOpen
                        ? "Dodaj informacje, aby utworzyć nowy parametr"
                        : null}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 col-span-3">
                    <div className="col-span-1 mb-4 relative mr-4">
                      <label
                        htmlFor="name"
                        className="block text-xs font-medium text-gray-400 pb-2"
                      >
                        Nazwa
                      </label>
                      <Field
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder="Nazwa"
                        className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600"
                      />
                      <div className="absolute right-2 bottom-2">
                        {isEditingSidebarOpen ? (
                          hasBeenTouched ? (
                            errors.name ? (
                              <>
                                <HiXMark
                                  className="h-6 w-6 text-rose-600"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={errors.name}
                                />
                                <Tooltip id="my-tooltip" />
                              </>
                            ) : (
                              <HiCheck className="h-6 w-6 text-emerald-700" />
                            )
                          ) : (
                            <HiCheck className="h-6 w-6 text-emerald-700" />
                          )
                        ) : errors.name || !values.name ? (
                          <>
                            <HiXMark
                              className="h-6 w-6 text-rose-600"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={errors.name}
                            />
                            <Tooltip id="my-tooltip" />
                          </>
                        ) : (
                          <HiCheck className="h-6 w-6 text-emerald-700" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-1 mb-4 relative">
                      <DropdownList
                        formik={{ values, setFieldValue }}
                        name="type"
                        pricegroups={false}
                        options={inputTypes}
                        selected={
                          ParamTypes[values.type.toString() as ParamType]
                        }
                        setSelected={(value) =>
                          value === "Tekst" && setFieldValue("options", [])
                        }
                        label="Typ"
                        categories={false}
                      />
                    </div>
                  </div>
                  {values.type !== "input" && (
                    <FieldArray
                      name="options"
                      render={(arrayHelpers: any) => (
                        <div>
                          <Button
                            onClick={() =>
                              arrayHelpers.insert(values.options.length + 1, "")
                            } // insert an empty string at a position
                            type="button"
                            label="Dodaj wartości"
                            classes={`text-xs py-[5px] px-[12px] mb-4 ${
                              values.type === "input" ? "hidden" : ""
                            }`}
                          />

                          {values.options &&
                            values.options.length > 0 &&
                            values.options.map((_, index) => (
                              <div>
                                <div key={index} className="flex">
                                  <Field
                                    placeholder="Wartość"
                                    name={`options.${index}`}
                                    className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600 mt-2"
                                  />

                                  <HiXMark
                                    className="h-6 w-6 text-rose-600 ml-2 mt-1"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={errors.name}
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  />
                                </div>
                                <span className="text-rose-600 text-xs font-medium">
                                  {errors.options &&
                                    typeof errors.options !== "string" &&
                                    errors.options[index]}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                  )}
                  <span className="text-rose-600 text-xs font-medium">
                    {typeof errors.options === "string" &&
                      values.type !== "input" &&
                      errors.options}
                  </span>
                  <div className="col-span-2 mt-4">
                    <Button type="submit" label="Zapisz" />
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}
