import { useContext } from "react";
import { updateCollection as updateItem } from "../../../api";
import { Collection } from "../types";
import { CollectionContext } from "../contexts/CollectionContext";

export const useUpdateCollection = (): {
  updateCollection: (collection: Collection, id: number) => void;
} => {
  const { refetch, setIsSidebarOpen } = useContext(CollectionContext);
  const updateCollection = (collection: Collection, id: number) => {
    updateItem({ ...collection, id })
      .then(() => {
        refetch();
        setIsSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { updateCollection };
};
