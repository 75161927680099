import React, { createContext, ReactNode, useState } from "react";

export const ProductVariantContext = createContext({
  isCreateProductVariantModalOpen: false,
  setIsCreateProductVariantModalOpen: (isOpen: boolean) => {
    console.log(isOpen);
  },
});

type ProductVariantContextProviderProps = {
  children: ReactNode;
};

export const ProductVariantContextProvider = ({
  children,
}: ProductVariantContextProviderProps) => {
  const [isCreateProductVariantModalOpen, setIsCreateProductVariantModalOpen] =
    useState(false);

  return (
    <ProductVariantContext.Provider
      value={{
        isCreateProductVariantModalOpen,
        setIsCreateProductVariantModalOpen,
      }}
    >
      {children}
    </ProductVariantContext.Provider>
  );
};
