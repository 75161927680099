import { deleteStore as deleteItem } from "../../../api";

export const useDeleteStore = (
  refetch: () => void
): {
  deleteStore: (id: number) => void;
} => {
  const deleteStore = (id: number) => {
    deleteItem(id)
      .then(() => {
        refetch();
      })
      .catch((e: any) => console.error(e));
  };

  return { deleteStore };
};
