import { MercuryOrder } from "../../../../types";
import { InputField } from "../../../../components/InputField";
import { HiMiniXMark } from "react-icons/hi2";
import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { MercuryOrdersFormik } from "./types";
import { useProduct } from "../../api/useProduct";
import { Option } from "../../types";
import { InputSelect } from "../../../../components/InputSelect";
import { useProductOptionsStore } from "../../../../stores/useProductOptionsStore";

type ValidationFormRowProps = {
  order: MercuryOrder;
  index: number;
  formik: FormikProps<MercuryOrdersFormik>;
  arrayHelpers: any;
};

export const ValidationFormRow = ({
  order,
  index,
  formik,
  arrayHelpers,
}: ValidationFormRowProps) => {
  const { product, productFetch } = useProduct();
  const [productTextileOptions, setProductTextileOptions] = useState<Option[]>(
    []
  );
  const [paramOptions, setParamOptions] = useState<
    { label: string; value: number; options: string | string[]; type: string }[]
  >([]);
  const [textFieldsParamsOptions, setTextFieldParamsOptions] = useState<
    { value: string; id: number }[]
  >([]);
  const { productOptions } = useProductOptionsStore();

  useEffect(() => {
    order.product_id && productFetch(order.product_id);
    formik.validateForm();
  }, []);

  useEffect(() => {
    const textileOptions = product?.textiles.map((textile) => ({
      value: textile.id,
      label: textile.name,
    }));
    textileOptions && setProductTextileOptions(textileOptions);

    const params = product?.params.map(({ id, name, options, type }) => ({
      label: name,
      value: id,
      options,
      type,
    }));
    params && setParamOptions(params);

    const textFieldsParams = product?.params
      .filter((param) => param.type === "input")
      .map((param) => ({ value: param.options.toString(), id: param.id }));
    textFieldsParams && setTextFieldParamsOptions(textFieldsParams);

    const multiParams =
      params &&
      params
        .filter((par) => par.type === "Multiselect")
        .map((par) => par.value);

    formik.setFieldValue(`orders.${index}.multiParams`, multiParams);
  }, [product?.id]);

  return (
    <div className="border-2 border-gray-400 border-r-2 mb-2 p-2 rounded-2xl min-w-full">
      <span>{order.productFullName}</span>
      <div className="flex flex-row gap-1 justify-between">
        <div className="w-72">
          <InputSelect
            label="Produkt"
            name={`orders.${index}.product_id`}
            onChange={(option: any) => {
              productFetch(option.value);
              formik.setFieldValue(`orders.${index}.product_id`, option.value);
            }}
            options={productOptions}
            value={productOptions.find(
              (product) =>
                product.value === formik.values.orders[index].product_id
            )}
            error={
              !formik.values.orders[index].product_id
                ? "Pole wymagane"
                : undefined
            }
            isForcedError
          />
        </div>
        <div className="w-56">
          <InputSelect
            label="Tekstylia"
            name={`orders.${index}.textile_id`}
            onChange={(option: any) => {
              formik.setFieldValue(`orders.${index}.textile_id`, option.value);
            }}
            options={productTextileOptions}
            value={productTextileOptions.find(
              (textile) =>
                textile.value === formik.values.orders[index].textile_id
            )}
            error={
              !formik.values.orders[index].textile_id
                ? "Pole wymagane"
                : undefined
            }
            isForcedError
          />
        </div>
        <InputField
          label="Kod Paskowy"
          name={`orders.${index}.batch_number`}
          onChange={formik.handleChange}
          value={formik.values.orders[index].batch_number}
          error={
            !formik.values.orders[index].batch_number
              ? "Pole wymagane"
              : undefined
          }
          isForcedError
        />
        <InputField
          label="Ilosc"
          type="number"
          name={`orders.${index}.quantity`}
          onChange={formik.handleChange}
          value={formik.values.orders[index].quantity}
          error={
            !formik.values.orders[index].quantity ? "Pole wymagane" : undefined
          }
          isForcedError
        />
        <InputField
          label="Index"
          name={`orders.${index}.zd_number`}
          onChange={formik.handleChange}
          value={formik.values.orders[index].zd_number}
          error={
            !formik.values.orders[index].zd_number ? "Pole wymagane" : undefined
          }
          isForcedError
        />
        <div>
          {paramOptions.map((item: any) => {
            return typeof item.options === "string" ? (
              <InputField
                name={item.value.toString()}
                label={item.label}
                onChange={() =>
                  formik.setFieldValue(
                    `orders.${index}.param_values.${item.value}`,
                    item.options
                  )
                }
                key={`${item.value}-${product?.id}`}
                value={item.options}
                disabled
              />
            ) : (
              <div key={`${item.value}-${product?.id}`}>
                <InputSelect
                  label={item.label}
                  name={item.value.toString()}
                  isMulti={item.type === "Multiselect"}
                  onChange={(option: any) => {
                    formik.setFieldValue(
                      `orders.${index}.param_values.${item.value}`,
                      {
                        ...option,
                        id: item.value,
                      }
                    );
                  }}
                  options={item.options.map((opt: any) => ({
                    label: opt,
                    value: opt,
                  }))}
                  isForcedError
                  error={
                    typeof formik.values.orders[index].param_values?.[
                      item.value
                    ] === "undefined"
                      ? "Pole wymagane"
                      : undefined
                  }
                />
              </div>
            );
          })}
        </div>
        <div>
          <div className="mt-4">
            <HiMiniXMark
              className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 sticky pb-2 right-0 mr-2 justify-self-center "
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Usuń zamowienie"
              onClick={() => arrayHelpers.remove(index)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
