import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import Select from "react-select";
import { useProductOptions } from "../../api/useProductOptions";
import { useParamOptions } from "../../api/useParamOptions";
import { useCreateProductVariant } from "../../api/useCreateProductVariant";
import { Button } from "../../../../components/Button";

export const ProductVariantForm = () => {
  const [productId, setProductId] = useState<number>(0);

  const { productOptions } = useProductOptions();
  const {
    paramOptions,
    textileOptions,
    textFieldsParamsOptions,
    fetch: fetchProductVariants,
  } = useParamOptions();
  const { createProductVariant } = useCreateProductVariant();

  useEffect(() => {
    productId !== 0 && fetchProductVariants(productId);
  }, [productId]);

  return (
    <Formik
      initialValues={{
        sku: "",
        product: { value: 0, label: "" },
        textile: { value: 0, label: "" },
        param_values: [],
      }}
      onSubmit={(values) => {
        createProductVariant({
          ...values,
          param_values: [...textFieldsParamsOptions, ...values.param_values],
        });
      }}
    >
      {({ values, handleChange, errors, setFieldValue }) => (
        <Form>
          <InputField
            name="sku"
            label="SKU"
            placeholder="SKU/07/2050"
            onChange={handleChange}
            error={errors.sku}
            value={values.sku}
          />
          <label
            htmlFor="product"
            className="block text-xs font-medium text-gray-400 pb-2 pt-4"
          >
            Produkt
          </label>
          <Select
            name="product"
            value={values.product}
            onChange={(option) => {
              setFieldValue("product", option);
              setProductId(option?.value || 0);
              setFieldValue("textile", null);
              setFieldValue("param_values", []);
            }}
            options={productOptions}
            isSearchable
          />
          <label
            htmlFor="textile"
            className="block text-xs font-medium text-gray-400 pb-2 pt-4"
          >
            Tekstylia
          </label>
          <Select
            name="textile"
            value={values.textile}
            options={textileOptions}
            onChange={(option) => setFieldValue("textile", option)}
            isSearchable
          />
          {paramOptions.length !== 0 && (
            <label
              htmlFor="parma_values"
              className="block text-xs font-medium text-gray-400 pb-2 pt-4"
            >
              Parametry
            </label>
          )}
          {paramOptions.map((item: any) => {
            return typeof item.options === "string" ? (
              <InputField
                name={item.value.toString()}
                label={item.label}
                onChange={() =>
                  setFieldValue(`param_values.${item.value}`, item.options)
                }
                key={`${item.value}-${productId}`}
                value={item.options}
                disabled
              />
            ) : (
              <div key={`${item.value}-${productId}`}>
                <label
                  htmlFor="parma_values"
                  className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                >
                  {item.label}
                </label>
                <Select
                  name={item.value.toString()}
                  isMulti={item.type === "Multiselect"}
                  onChange={(option: any) => {
                    setFieldValue(`param_values.${item.value}`, {
                      ...option,
                      id: item.value,
                    });
                  }}
                  options={item.options.map((opt: any) => ({
                    label: opt,
                    value: opt,
                  }))}
                />
              </div>
            );
          })}
          <div className="pb-40" />
          <Button type="submit" label="Zapisz" />
        </Form>
      )}
    </Formik>
  );
};
