import React, { createContext, ReactNode, useEffect, useState } from "react";
import { ToastMessage } from "../../types";

const initialState: ToastMessage = {
  type: "success",
  message: "",
  data: [],
};
export const ToastMessageContext = createContext<{
  toastMessage: ToastMessage;
  setToastMessage: (toastMessage: ToastMessage) => void;
  handleClose: () => void;
}>({
  toastMessage: initialState,
  setToastMessage: (toastMessage) => {},
  handleClose: () => {},
});

type ToastMessageContextProviderProps = {
  children: ReactNode;
};

export const ToastMessageContextProvider = ({
  children,
}: ToastMessageContextProviderProps) => {
  const [toastMessage, setToastMessage] = useState<ToastMessage>(initialState);
  const handleClose = () => {
    setToastMessage(initialState);
  };

  useEffect(() => {
    if (toastMessage.message) {
      setTimeout(handleClose, 3000);
    }
  }, [toastMessage.message]);

  return (
    <ToastMessageContext.Provider
      value={{
        toastMessage,
        setToastMessage,
        handleClose,
      }}
    >
      {children}
    </ToastMessageContext.Provider>
  );
};
