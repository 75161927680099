import { useEffect, useState } from "react";
import { getUsers } from "../../../api";
import { UserRoleNames } from "../../../types/User";

export const useUserOption = (
  roles: UserRoleNames[] = [
    UserRoleNames.ADMIN,
    UserRoleNames.CUSTOMER,
    UserRoleNames.WORKER,
  ]
) => {
  const [users, setUsers] = useState<{ label: string; value: number }[]>([]);
  const [admins, setAdmins] = useState<{ label: string; value: number }[]>([]);
  const [customers, setCustomers] = useState<
    { label: string; value: number }[]
  >([]);
  const fetch = () => {
    roles.includes(UserRoleNames.ADMIN) &&
      getUsers(UserRoleNames.ADMIN)
        .then((res) =>
          setAdmins(
            res.map(({ id, name }: any) => ({ label: name, value: id }))
          )
        )
        .catch((e) => console.error(e));
    roles.includes(UserRoleNames.CUSTOMER) &&
      getUsers(UserRoleNames.CUSTOMER)
        .then((res) =>
          setCustomers(
            res.map(({ id, name }: any) => ({ label: name, value: id }))
          )
        )
        .catch((e) => console.error(e));
    roles.includes(UserRoleNames.WORKER) &&
      getUsers(UserRoleNames.WORKER)
        .then((res) =>
          setUsers(res.map(({ id, name }: any) => ({ label: name, value: id })))
        )
        .catch((e) => console.error(e));
  };
  useEffect(() => {
    fetch();
  }, []);

  return { userOptions: [...users, ...admins, ...customers], refetch: fetch };
};
