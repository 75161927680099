import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";
import { updateOrder as update } from "../../../api";
import { OrderForm } from "../types";
import { ToastMessageContext } from "../../../components/ToastMessage";
import { useNavigate } from "react-router-dom";

export const useUpdateOrder = (): {
  updateOrder: (order: OrderForm, resetForm: () => void) => void;
} => {
  const { setIsEditSidebarOpen } = useContext(OrdersContext);
  const { setToastMessage } = useContext(ToastMessageContext);
  const navigate = useNavigate();

  const updateOrder = (order: OrderForm, resetForm: () => void) => {
    update(order)
      .then(() => {
        setIsEditSidebarOpen(false);
        setToastMessage({
          type: "success",
          message: "Zamówienie zostało zaktualizowane",
          data: [],
        });
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch((e: any) => {
        setToastMessage({
          type: "error",
          message: e.message,
          data: Object.entries(e.data).map(([_, value]) => value as string),
        });
      });
  };

  return { updateOrder };
};
