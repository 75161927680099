import "./bootstrap";
import "./App.css";

import React, { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import Router from "./routes/Router";
import { ToastMessage } from "./components/ToastMessage/components/ToastMessage";
import { useProductOptions } from "./pages/collections/api/useProductOptions";
import { useUsers } from "./pages/users/api/useUsers";
import { useUsersStore } from "./stores/useUsersStore";

export default function App() {
  useProductOptions();
  const { users } = useUsers();

  useEffect(() => {
    useUsersStore.setState({ users });
  }, [users.length]);
  return (
    <>
      <ToastMessage />
      <HashRouter>
        <Router />
      </HashRouter>
    </>
  );
}
