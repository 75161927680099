import React, { useContext } from "react";
import { UserTableHeaderRow } from "./UserTableHeaderRow";
import { UserContext } from "../../contexts/UserContext";
import { UserRow } from "./UserRow";

export const UserTable = () => {
  const context = useContext(UserContext);

  return (
    <>
      <UserTableHeaderRow />
      {context?.users &&
        context?.users.map((user) => <UserRow user={user} key={user.id} />)}
    </>
  );
};
