import { useEffect, useState } from "react";
import { getOrderTypes } from "../../../api";
import { OrderType } from "../../../types/OrderType";

export const useOrderTypes = () => {
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const fetch = () =>
    getOrderTypes()
      .then((res) => setOrderTypes(res))
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { orderTypes, refetch: fetch };
};
