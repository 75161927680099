import React, { useContext } from "react";
import { Modal } from "../../../../components/Modal";
import { useDeleteCollection } from "../../api/useDeleteCollection";
import { CollectionContext } from "../../contexts/CollectionContext";

type DeleteModalProps = {
  name: string;
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const DeleteModal = ({
  name,
  id,
  isModalOpen,
  setIsModalOpen,
}: DeleteModalProps) => {
  const { refetch } = useContext(CollectionContext);
  const { deleteCollection } = useDeleteCollection(refetch);
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={`Czy napewno chcesz usunąć kolekcjii ${name}?`}
      buttonLabel="Usuń"
      title="Potwierdź usunięcie kolekcji"
      onConfirm={() => {
        deleteCollection(id);
      }}
    />
  );
};
