import { useContext } from "react";
import { OrdersContext } from "../contexts/OrdersContext";
import { updateOrder as update } from "../../../api";

export const useChangeOrderStatus = (): {
  updateOrder: (status: number, id: number) => void;
} => {
  const { setIsEditSidebarOpen, refetch } = useContext(OrdersContext);
  const updateOrder = (status: number, id: number) => {
    update({ status_id: status, id })
      .then(() => {
        refetch();
        setIsEditSidebarOpen(false);
      })
      .catch((e: any) => console.error(e));
  };

  return { updateOrder };
};
