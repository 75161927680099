import { Order } from "../types/Order";
import moment from "moment";
import { Package } from "../types/Package";

export const printAgataLabel = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package
) => {
  const kw = moment(order.deliveryAt, "YYYYMMDD").isoWeek();
  const deliveryYear = moment(order.deliveryAt, "YYYYMMDD").year();

  doc.setFont("IBMPlexSans-Bold", "normal", "bold");

  doc.setFontSize(20);
  doc.text(order.poNumber || "Brak", 5, 10);
  doc.text(order.zdNumber || "Brak", 5, 20);

  doc.setFontSize(27);
  doc.text(`Paczka: ${parcelIndex + 1}/${packagesQty}`, 5, 38);

  doc.setFontSize(18);
  const lines = doc.splitTextToSize(
    "Opis produktu: " + order.productDescription,
    140
  );
  doc.text(lines, 5, 55);

  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.setFontSize(9);
  doc.text("PRODUCENT", 105, 7);
  doc.setFontSize(13);
  doc.setFont("IBMPlexSans-Bold", "normal", "bold");
  doc.text("SAGAN FM", 105, 14);
  doc.setFontSize(11);
  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.text("Gabriela Wąsala Sp.K.", 105, 20);
  doc.text("ul. Poznańska 6", 105, 25);
  doc.text("63-600 Kępno", 105, 30);

  doc.setFont("IBMPlexSans-Regular", "normal", "normal");
  doc.setFontSize(18);
  doc.text(`KW ${kw}/${deliveryYear}`, 5, 90);
  doc.text(order.buyerAddress || "Brak", 5, 98);

  doc.text(
    `Wymiary: ${productPackage?.width} x ${productPackage?.height} x ${productPackage?.depth}`,
    80,
    90
  );
  doc.text(`Waga: ${productPackage?.weight} kg`, 80, 98);

  doc.addPage();
};
