import { Modal } from "../../../../components/Modal";
import React, { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { CollectionStepper } from "./CollectionStepper";
import { useCollectionOrderStore } from "../../../../stores/useCollectionOrderStore";

export const CollectionOrder = () => {
  const { setIsCollectionModalOpen, isCollectionModalOpen } =
    useContext(OrdersContext);
  const { clearStorage } = useCollectionOrderStore();

  return (
    <Modal
      isModalOpen={isCollectionModalOpen}
      setIsModalOpen={setIsCollectionModalOpen}
      handleClose={clearStorage}
      size="lg"
      content={
        <div>
          <CollectionStepper />
        </div>
      }
      title="Import zamowien - kolekcje"
    />
  );
};
