import { useEffect, useState } from "react";
import { getAgatasStores } from "../../../api";
import { Address } from "../../../types/Address";

export const useAddresses = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const fetch = () =>
    getAgatasStores()
      .then((res: Address[]) => setAddresses(res))
      .catch((e: any) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);
  return { fetch, addresses };
};
