export type ParamType = "input" | "select" | "Multiselect";

export type Param = {
  id: number;
  name: string;
  type: ParamType;
  options: string[];
};

export type ParamTypesType = {
  input: string;
  select: string;
  Multiselect: string;
};

export const ParamTypes: Record<ParamType, string> = {
  input: "0",
  select: "1",
  Multiselect: "2",
} as const;
