import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Imię musi zawierać minimum 2 znaki")
    .max(100, "Imię musi zawierać minimum 100 znaków")
    .required("Pole wymagane"),

  email: Yup.string().email("Zły format").required("Pole wymagane"),
  password: Yup.string()
    .required("Hasło wymagane")
    .min(8, "Hasło musi zawierać minimum 8 znaki")
    .max(20, "Hasło musi zawierać maksymalnie 20 znaków"),
  password1: Yup.string().oneOf(
    [Yup.ref("password"), ""],
    "Hasła nie są pasujące"
  ),
});

export const updateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Imię musi zawierać minimum 2 znaki")
    .max(100, "Imię musi zawierać maksymalnie 100 znakow")
    .required("Pole wymagane"),

  email: Yup.string().email("Zły format").required("Pole wymagane"),
  password: Yup.string()
    .min(8, "zawierać musi zawierac minimum 8 znaki")
    .max(20, "zawierać musi zawierac maksymalnie 20 znaków"),
  password1: Yup.string().oneOf(
    [Yup.ref("password"), ""],
    "Hasła nie są pasujące"
  ),
});
