import { StatusModal } from "../OrderOverview/StatusModal";
import React, { useContext, useState } from "react";
import { UserRoleNames } from "../../../../types/User";
import { useUserStore } from "../../../../stores/useUserStore";
import { OrdersContext } from "../../contexts/OrdersContext";
import { Button } from "../../../../components/Button";
import { Form, Formik } from "formik";
import { InputSelect } from "../../../../components/InputSelect";
import { StatusOption } from "../../../../types";
import { useStatusOptionsStore } from "../../../../stores/useStatusOptionsStore";
import { HiStatusOnline } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { updateOrder as update } from "../../../../api";

const initialValues: StatusOption = {
  value: 0,
  label: "",
  color: "",
};
export const ChangeOrdersStatus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedOrders, setSelectedOrders } = useContext(OrdersContext);
  const navigate = useNavigate();
  const { options } = useStatusOptionsStore();

  const { role } = useUserStore();
  return (
    <>
      {!role.includes(UserRoleNames.CUSTOMER) && !!selectedOrders.length && (
        <Button
          onClick={() => setIsModalOpen(true)}
          icon={<HiStatusOnline />}
          label="Zmień statusy"
          classes="mr-3 bg-emerald-600"
        />
      )}
      <StatusModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <div>
          <Formik
            initialValues={{
              status: initialValues,
            }}
            onSubmit={(values, { resetForm }) => {
              Promise.all(
                selectedOrders.map(async ({ id }) => {
                  return update({ status_id: values.status.value, id }).catch(
                    (e: any) => console.error(e)
                  );
                })
              ).then(() => {
                setIsModalOpen(false);
                resetForm();
                navigate(0);
                setSelectedOrders([]);
              });
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="flex flex-col justify-between h-[200px]">
                <div>
                  <InputSelect
                    label="Status"
                    name="status"
                    value={values.status}
                    options={options}
                    onChange={(option) => setFieldValue("status", option)}
                  />
                </div>

                <Button label="Zapisz" type="submit" classes="w-[90px]" />
              </Form>
            )}
          </Formik>
        </div>
      </StatusModal>
    </>
  );
};
