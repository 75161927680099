import React, { useContext, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import { InputField } from "../../../../components/InputField";
import { Button } from "../../../../components/Button";
import {
  createValidationSchema,
  updateValidationSchema,
} from "../../../../schemas/User.schema";
import { OrderType, User } from "../../../../types";
import Select from "react-select";
import { UserRoleNames } from "../../../../types/User";
import { HiXMark } from "react-icons/hi2";
import { usePriceGroupOptions } from "../../api/usePriceGroupOptions";
import { useTextileOptions } from "../../api/useTextileOptions";
import { useCreateUser } from "../../api/useCreateUser";
import { useUserTextiles } from "../../api/useUserTextiles";
import { useUpdateUser } from "../../api/useUpdateUser";
import { UserContext } from "../../contexts/UserContext";
import { USER_MOCK } from "../../../../mocks/User.mock";
import { InputSelect } from "../../../../components/InputSelect";
import { GeneratePdf } from "../../../../orderTemplates";

const PASSWORD_DEFAULT_VALUE = "xxxxxxxxx";

type UserFormProps = {
  user: User & { password1?: string };
  refetch?: () => void;
  isCustomerUpdatingOwnProfile?: boolean;
};

const TEMPLATES: {value: GeneratePdf, label: string}[] = [
  { value: "agataTemplate", label: "Agata" },
  { value: "mercuryTemplate", label: "Mercury" },
  { value: "basic", label: "Podstawowy" },
  { value: "rollerTemplate", label: "Roller" },
  { value: "rollerTemplateBelegnummer", label: "Roller - Belegnummer" },
  { value: "ua", label: "Wersja Rosyjska" },
  { value: "fsm", label: "FSM" },
];
export const UserForm = ({
  user,
  refetch,
  isCustomerUpdatingOwnProfile,
}: UserFormProps) => {
  const { priceGroupOptions } = usePriceGroupOptions();
  const { textileOptions } = useTextileOptions();
  const { createUser } = useCreateUser();
  const { updateUser } = useUpdateUser({ isReload: !!refetch });
  const isEditMode = !!user.id;
  const { setEditUser } = useContext(UserContext);

  const { userTextiles, fetch } = useUserTextiles();

  useEffect(() => {
    user?.id && fetch(user.id);
  }, [user.id]);

  const formUser = {
    ...user,
    password: PASSWORD_DEFAULT_VALUE,
    password1: PASSWORD_DEFAULT_VALUE,
    textiles: userTextiles.map((item) => ({
      textile_id: textileOptions.find(
        (option) => item.textile_id === option.value
      ),
      price_group: priceGroupOptions.find(
        (option) => item.price_group === option.value
      ),
    })),
    role: user.roles?.map((item) => ({ label: item.name, value: item.name })),
    orders_types: user.ordersTypes?.map((item) => ({
      label: item,
      value: item,
    })),
    label_template_id: user.labelTemplateId,
    label_template: TEMPLATES.find(
      (item) => item.value === user.labelTemplate
    ),
  };

  const handleSubmit = (values: any) => {
    if (isEditMode) {
      if (
        values.password === PASSWORD_DEFAULT_VALUE ||
        values.password === ""
      ) {
        const { password, password1, ...rest } = values;
        updateUser(rest, user.id || 0);
      } else {
        updateUser(values, user.id || 0);
      }
    } else {
      createUser(values);
    }
    setEditUser(USER_MOCK);
  };
  return (
    <div className="pt-5 pl-1">
      <div className="grid grid-cols-2">
        <div className="col-span-2 mb-6">
          <h1 className="text-3xl font-semibold mb-1">
            {isEditMode
              ? `Edytuj uzytkownika ${user.name}`
              : "Dodaj uzytkownika"}
          </h1>
          <p className="text-gray-400">
            {!isEditMode && "Dodaj informacje, aby utworzyć nowego uzytkownika"}
          </p>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={formUser}
        validationSchema={
          isEditMode ? updateValidationSchema : createValidationSchema
        }
        onSubmit={({ roles, ...values }, { resetForm }) => {
          handleSubmit({
            ...values,
            role: values.role?.map((item) => item.value)[0],
            orders_types: values.orders_types?.map((item) => item.value),
            textiles: values.textiles.map((item) => ({
              textile_id: item.textile_id?.value || 0,
              price_group: 1,
            })),
            label_template: values.label_template?.value,
          });
          resetForm();
        }}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <InputField
              label="Imię"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              placeholder="Jan Kowalski"
            />
            <InputField
              label="Hasło"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              onFocus={() =>
                values.password === PASSWORD_DEFAULT_VALUE &&
                setFieldValue("password", "")
              }
            />
            <InputField
              label="Powtórz hasło"
              name="password1"
              type="password"
              value={values.password1}
              onChange={handleChange}
              error={errors.password1}
              onFocus={() =>
                values.password === PASSWORD_DEFAULT_VALUE &&
                setFieldValue("password1", "")
              }
            />
            <InputField
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              placeholder="jan.kowalski@example.com"
            />

            {!isCustomerUpdatingOwnProfile && (
              <>
                <div>
                  <label
                    htmlFor="orderType"
                    className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                  >
                    Typ zamównienia
                  </label>
                  <Select
                    name="orders_types"
                    isMulti
                    onChange={(option: any) => {
                      setFieldValue("orders_types", option);
                    }}
                    options={Object.values(OrderType).map((label) => ({
                      label,
                      value: label,
                    }))}
                    value={values.orders_types}
                  />
                </div>

                <div>
                  <label
                    htmlFor="roles"
                    className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                  >
                    Rola
                  </label>
                  <Select
                    name="roles"
                    onChange={(option: any) => {
                      setFieldValue("role", option);
                    }}
                    options={Object.values(UserRoleNames).map((label) => ({
                      label,
                      value: label,
                    }))}
                    value={values.role}
                    isMulti
                  />
                </div>

                <InputSelect
                  label="Template etykiet"
                  name="label_template"
                  options={TEMPLATES}
                  value={values.label_template}
                  onChange={(option) => setFieldValue("label_template", option)}
                />

                <label
                  htmlFor="textiles"
                  className="block text-xs font-medium text-gray-400 pb-2 pt-4"
                >
                  Tekstylia & Grupy cenowe
                </label>
                <FieldArray
                  name="textiles"
                  render={(arrayHelpers: any) => (
                    <div>
                      <Button
                        onClick={() =>
                          arrayHelpers.insert(values.textiles.length + 1, {
                            textile_id: 0,
                            price_group: 0,
                          })
                        }
                        type="button"
                        label="Dodaj wartości"
                        classes={`text-xs py-[5px] px-[12px] mt-2`}
                      />
                      {values.textiles &&
                        values.textiles.length > 0 &&
                        values.textiles.map((value, index) => (
                          <div>
                            <div key={index} className="flex">
                              <div className="grid grid-col-2 gap-1"></div>
                              <Select
                                placeholder="Telstylia"
                                name={`textiles[${index}].textile_id`}
                                options={textileOptions}
                                className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600 mt-2"
                                onChange={(option: any) => {
                                  setFieldValue(
                                    `textiles[${index}].textile_id`,
                                    option
                                  );
                                }}
                                value={value.textile_id}
                              />
                              <Select
                                placeholder="Grupa cenowa"
                                name={`textiles[${index}].price_group`}
                                options={priceGroupOptions}
                                className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600 mt-2"
                                onChange={(option: any) => {
                                  setFieldValue(
                                    `textiles[${index}].price_group`,
                                    option
                                  );
                                }}
                                value={value.price_group}
                              />

                              <HiXMark
                                className="h-6 w-6 text-rose-600 ml-2 mt-1"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={errors.name}
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                />
              </>
            )}
            <Button type="submit" label="Zapisz" classes="my-3 ml-auto" />
          </Form>
        )}
      </Formik>
    </div>
  );
};
