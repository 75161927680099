import { useState } from "react";
import { getCollection } from "../../../api";
import { Collection } from "../types";

export const useCollection = () => {
  const [collection, setCollection] = useState<
    { productVariants: any[] } & Collection
  >();
  const fetch = (id: number) =>
    getCollection(id)
      .then((res) => setCollection(res))
      .catch((e) => console.error(e));

  return { fetch, collection };
};
