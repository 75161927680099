import { Order } from "../../types/Order";
import { Textile } from "../../types/Textile";
import { printBasicTemplate } from "./printBasicLabel";

export const generateBasicTemplate = (
  order: Order,
  orderIndex: number,
  textiles: Textile[],
  doc: any
) => {
  const qty = order?.quantity ? order?.quantity : 1;
  for (let x = 1; x <= qty; x++) {
    if (order !== undefined) {
      const product = order.product;
      product?.packages.map((productPackage, index) => {
        printBasicTemplate(
          order,
          doc,
          index,
          product?.packages.length,
          productPackage,
          textiles.find((textile) => textile.id == order.textileId)?.name || "",
          order.params
            ? Object.values(order.params as any)
                .map((v) => v)
                .toString()
            : "",
          String(orderIndex + x)
        );
      });
    }
  }
};
