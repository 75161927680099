import React, { Dispatch, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiOutlineCheckCircle, HiOutlineChevronDown } from "react-icons/hi2";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type DropdownType = {
  formik: any;
  name: string;
  options: any;
  selected: string;
  setSelected: Dispatch<string>;
  label: string;
  pricegroups?: boolean;
  categories?: boolean;
};

interface OptionType {
  id: number;
  name: string;
}

export const DropdownList = ({
  formik,
  name,
  options,
  selected,
  setSelected,
  label,
  pricegroups,
  categories,
}: DropdownType) => {
  const selectedOption = options.find((option: OptionType) => {
    if (pricegroups) {
      return option.id === formik.values.priceGroup;
    } else if (categories) {
      return option.id === formik.values.categoryId;
    } else {
      return option.id === formik.values.type;
    }
  });
  const selectedName = selectedOption ? selectedOption.name : "";
  return (
    <Listbox
      value={
        pricegroups
          ? formik.values.priceGroup
          : categories
          ? formik.values.categoryId
          : formik.values.type
      }
      onChange={(value: OptionType) => {
        formik.setFieldValue(name, value.id);
        setSelected(value.name);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-xs font-medium text-gray-400 pb-2">
            {label}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-[7px] pl-3 pr-10 text-left text-gray-700 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="block truncate">{selectedName}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <HiOutlineChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((i: any) => (
                  <Listbox.Option
                    key={i.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-gray-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={i}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {i.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-gray-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <HiOutlineCheckCircle
                              className="h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
