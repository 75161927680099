import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { MercuryOrder } from "../types";

type MercuryUploadStore = {
  clearUpload(): void;
  orders: MercuryOrder[];
  currentStep: number;
  isNextStepBlocked: boolean;
  orderName: string;
};

const getInitialState = (
  set: SetState<MercuryUploadStore>,
  get: GetState<MercuryUploadStore>
): MercuryUploadStore => ({
  currentStep: 0,
  orders: [],
  isNextStepBlocked: true,
  orderName: "",
  clearUpload: () => {
    set({ orders: [], currentStep: 0, isNextStepBlocked: true, orderName: "" });
  },
});

export const useMercuryUploadStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "mercuryUpload",
  })
);
