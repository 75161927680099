import React, { useContext } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { INITIAL_ADDRESS, StoresContext } from "../contexts/StoresContext";
import { AddressForm } from "./AddressForm";

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen, editAddress, setEditAddress } =
    useContext(StoresContext);
  const handleCloseBtn = () => {
    setEditAddress(INITIAL_ADDRESS);
    setIsSidebarOpen(false);
  };
  return (
    <div>
      <div
        className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div className="p-10 pl-9">
          <div>
            <HiOutlineXMark
              className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
              onClick={handleCloseBtn}
            />
          </div>
          <AddressForm address={editAddress} />
          {/*<Button*/}
          {/*  label="Dodaj wariant produktu"*/}
          {/*  onClick={() => setIsCreateProductVariantModalOpen(true)}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
};
