import { Form, FormikProps, FormikProvider } from "formik";
import { InputField } from "../../../../components/InputField";
import { UserRoleNames } from "../../../../types/User";
import React, { useEffect } from "react";
import { useUserStore } from "../../../../stores/useUserStore";
import { useUser } from "../../api/useUser";
import { useUserOption } from "../../api/useUserOptions";
import { InputSelect } from "../../../../components/InputSelect";
import { CollectionOrdersFormik } from "./types";

type MercuryDetailsFormProps = {
  formik: FormikProps<CollectionOrdersFormik>;
};
export const CollectionOrderDetailsForm = ({
  formik,
}: MercuryDetailsFormProps) => {
  const { id } = useUserStore();
  const { user: detailUser, fetch: fetchUser } = useUser();
  useEffect(() => {
    id && fetchUser(id);
  }, [id]);
  const { userOptions } = useUserOption();

  return (
    <FormikProvider value={formik}>
      <Form>
        <InputSelect
          label="Użytkownik"
          name="user"
          onChange={(option: any) => {
            formik.setFieldValue("user", option);
          }}
          options={userOptions}
          value={ userOptions.find((user) => formik.values.user.value == user.value) ||userOptions.find((user) => user.value == id)}
          placeholder="Jan"
          isDisabled={detailUser?.roles
            ?.map((role) => role.name)
            .includes(UserRoleNames.CUSTOMER)}
        />
        <InputField
          label="Nazwa zamowienia"
          onChange={formik.handleChange}
          value={formik.values.po_number}
          name="po_number"
        />
      </Form>
    </FormikProvider>
  );
};
