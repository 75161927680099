import React, { useContext } from "react";

import { StoresContext } from "../contexts/StoresContext";
import { Modal } from "../../../components/Modal";
import { useDeleteStore } from "../api/useDeleteStore";

type DeleteModalProps = {
  name: string;
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const DeleteModal = ({
  name,
  id,
  isModalOpen,
  setIsModalOpen,
}: DeleteModalProps) => {
  const { refetch } = useContext(StoresContext);
  const { deleteStore } = useDeleteStore(refetch);
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      content={`Czy napewno chcesz usunąć sklep ${name}?`}
      buttonLabel="Usuń"
      title="Potwierdź usunięcie sklepu"
      onConfirm={() => {
        deleteStore(id);
      }}
    />
  );
};
