import { useState } from "react";
import { getProduct } from "../../../api";

import { Product } from "../../../types/Product";

export const useProduct = () => {
  const [product, setProduct] = useState<Product>();
  const fetch = (id: number) =>
    getProduct(id)
      .then((res: Product) => {
        setProduct(res);
      })
      .catch((e: any) => console.error(e));

  return { product, productFetch: fetch };
};
