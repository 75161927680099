import { printAgataLabel } from "../pdfs/AgataLabel";
import { Order } from "../types/Order";

export const generateAgataTemplate = (order: Order, doc: any) => {
  const qty = order?.quantity ? order?.quantity : 1;

  for (let x = 1; x <= qty; x++) {
    if (order !== undefined) {
      const product = order.product;
      product?.packages.map((productPackage, index) => {
        printAgataLabel(
          order,
          doc,
          index,
          product?.packages.length,
          productPackage
        );
      });
    }
  }
};
