import React from "react";
import { HiOutlineXCircle } from "react-icons/hi2";
import ImageUploading from "react-images-uploading";

type ImageUploadType = {
  formik: any;
  images: any;
  onImageChange: any;
  resolutionHeight?: number;
  resolutionWidth?: number;
  maxFileSize?: number;
  isEditingSidebarOpen?: boolean;
};

export const ImageUpload = ({
  formik,
  images,
  onImageChange,
  resolutionHeight,
  resolutionWidth,
  maxFileSize,
  isEditingSidebarOpen,
}: ImageUploadType) => {
  return (
    <ImageUploading
      multiple={false}
      value={images}
      onChange={onImageChange}
      dataURLKey="data_url"
      resolutionType="absolute"
      resolutionHeight={resolutionHeight}
      resolutionWidth={resolutionWidth}
      maxFileSize={maxFileSize}
      acceptType={["jpg", "jpeg", "gif", "png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        <div className="upload__image-wrapper col-span-2 grid grid-cols-2">
          <div className="col-span-2 grid grid-cols-2">
            <div className="col-span-1 mr-2">
              <div
                style={isDragging ? { color: "#1F2937" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
                className={`w-full cursor-pointer border-gray-200 bg-gray-100 border-dashed text-center p-6 rounded-lg border-4 mb-2 font-medium text-gray-400 ${
                  imageList.length !== 0 && "hidden"
                }`}
              >
                Dodaj zdjęcie
              </div>
            </div>
          </div>
          {imageList.map((image, index) => (
            <div key={index} className="image-item col-span-1 grid grid-cols-2">
              <div className="col-span-1 mr-2">
                <img
                  src={image["data_url"]}
                  alt={"image"}
                  className="rounded-lg w-full"
                />
              </div>
              <div className="image-item__btn-wrapper col-span-1">
                <button
                  onClick={() => {
                    onImageRemove(index);
                    formik.setFieldValue("image", "");
                  }}
                  className="text-2xl text-rose-500 hover:text-rose-700 mt-8"
                >
                  <HiOutlineXCircle />
                </button>
              </div>
            </div>
          ))}
          <div className="col-span-2">
            {isEditingSidebarOpen ? (
              formik.touched.image ? (
                formik.errors.image ? (
                  <span className="text-rose-600 text-xs font-medium">
                    {formik.errors.image as string}
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : formik.errors.image ? (
              <span className="text-rose-600 text-xs font-medium">
                {formik.errors.image as string}
              </span>
            ) : (
              ""
            )}
            {errors && (
              <div>
                {errors.acceptType && (
                  <span className="text-rose-600 text-xs font-medium">
                    Niewłaściwy format zdjęcia
                  </span>
                )}
                {errors.maxFileSize && (
                  <span className="text-rose-600 text-xs font-medium">
                    Przekroczono max. wielkości zdjęcia
                  </span>
                )}
                {errors.resolution && (
                  <span className="text-rose-600 text-xs font-medium">
                    Należy wgrać zdjęcie o wym.: 600 x 600 px
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </ImageUploading>
  );
};
