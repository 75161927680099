export type UserTextile = {
  textile_id: number;
  price_group: number;
};

export enum OrderType {
  Individual = "form",
  ImportSet = "import",
  CollectionSet = "collection",
}

export enum UserRoleNames {
  ADMIN = "admin",
  WORKER = "worker",
  CUSTOMER = "customer",
}

export type UserRole = {
  id: number;
  name: UserRoleNames;
  label?: string;
};

export type User = {
  id?: number;
  name: string;
  email: string;
  role?: UserRole;
  roles?: UserRole[];
  password?: string;
  textiles: UserTextile[];
  orders_types: string[];
  ordersTypes?: OrderType[];
  labelTemplateId: number;
  labelTemplate: string
};
