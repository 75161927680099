import { FieldArray, Form, FormikProps, FormikProvider } from "formik";
import { InputField } from "../../../../components/InputField";
import { HiMiniXMark } from "react-icons/hi2";
import React from "react";
import { CollectionOrdersFormik } from "./types";

type CollectionOrdersValidationFormProps = {
  formik: FormikProps<CollectionOrdersFormik>;
};
export const CollectionOrdersValidationForm = ({
  formik,
}: CollectionOrdersValidationFormProps) => {
  return (
    <div className="pt-4">
      <FormikProvider value={formik}>
        <Form>
          <FieldArray
            name="orders"
            render={(arrayHelpers: any) => {
              return (
                <div>
                  {formik.values.orders.map((order, index) => (
                    <div className="flex flex-row gap-1">
                      <InputField
                        label="SKU"
                        name={`orders.${index}.sku`}
                        onChange={formik.handleChange}
                        disabled
                        value={order.sku}
                      />
                      <InputField
                        label="Ilosc"
                        type="number"
                        name={`orders.${index}.quantity`}
                        onChange={formik.handleChange}
                        value={order.quantity}
                      />
                      <div>
                        <div className="mt-4">
                          <HiMiniXMark
                            className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 sticky pb-2 right-0 mr-2 justify-self-center "
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Usuń zamowienie"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};
