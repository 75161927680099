import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { MercuryOrder } from "../types";
import { Option } from "../pages/orders/types";

type ProductOptionsStore = {
  clearStore(): void;
  productOptions: Option[];
};

const getInitialState = (
  set: SetState<ProductOptionsStore>,
  get: GetState<ProductOptionsStore>
): ProductOptionsStore => ({
  productOptions: [],
  clearStore: () => {
    set({ productOptions: [] });
  },
});

export const useProductOptionsStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "productOptions",
  })
);
