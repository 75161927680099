import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserStore } from "../stores/useUserStore";

import { Login } from "../pages/login/Login";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Textiles } from "../pages/textiles/Textiles";
import { Products } from "../pages/products/Products";
import { Product } from "../pages/products/Product";
import { Params } from "../pages/params/Params";
import { Categories } from "../pages/categories/Categories";
import { Collections } from "../pages/collections/components";
import { Users } from "../pages/users/components";
import { OrdersAgata } from "../pages/orders/components";
import { AgatasStores } from "../pages/stores";
import { EditProfile } from "../pages/editProfile";
import { UserRoleNames } from "../types/User";
import { OrderDetails } from "../pages/orderDetails";

const ALL_ROLES = [
  UserRoleNames.CUSTOMER,
  UserRoleNames.WORKER,
  UserRoleNames.ADMIN,
];
const ADMIN_ROLES = [UserRoleNames.WORKER, UserRoleNames.ADMIN];

export default function Router() {
  const { role } = useUserStore();

  const isLoggedIn = ALL_ROLES.some((item) => role.includes(item));
  const isAdmin = ADMIN_ROLES.some((item) => role.includes(item));

  return (
    <Routes>
      <Route index element={<Navigate replace to="/login" />} />
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate replace to="/dashboard" /> : <Login />}
      />
      <Route
        path="/dashboard"
        element={isLoggedIn ? <Dashboard /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/textiles"
        element={isAdmin ? <Textiles /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/products"
        element={isAdmin ? <Products /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/products/:id"
        element={isAdmin ? <Product /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/categories"
        element={isAdmin ? <Categories /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/params"
        element={isAdmin ? <Params /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/collections"
        element={isAdmin ? <Collections /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/orders"
        element={
          isLoggedIn ? <OrdersAgata /> : <Navigate replace to="/login" />
        }
      />
      <Route
        path="/orders/:id"
        element={
          isLoggedIn ? <OrderDetails /> : <Navigate replace to="/login" />
        }
      />
      <Route
        path="/store/agata"
        element={isAdmin ? <AgatasStores /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/users"
        element={isAdmin ? <Users /> : <Navigate replace to="/login" />}
      />
      <Route
        path="/users/edit"
        element={
          isLoggedIn ? <EditProfile /> : <Navigate replace to="/login" />
        }
      />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
}
