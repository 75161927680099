import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type CollectionOrderStore = {
  clearStorage(): void;
  collectionId: number;
  currentStep: number;
  isNextStepBlocked: boolean;
  orderName: string;
};

const getInitialState = (
  set: SetState<CollectionOrderStore>,
  get: GetState<CollectionOrderStore>
): CollectionOrderStore => ({
  currentStep: 0,
  collectionId: 0,
  isNextStepBlocked: true,
  orderName: "",
  clearStorage: () => {
    set({
      collectionId: 0,
      currentStep: 0,
      isNextStepBlocked: true,
      orderName: "",
    });
  },
});

export const useCollectionOrderStore = create(
  persist(getInitialState, {
    storage: createJSONStorage(() => sessionStorage),
    name: "collectionOrder",
  })
);
