import { Order } from "../../types/Order";
import moment from "moment";
import { Package } from "../../types/Package";
import { rollerLogo } from "../roller/rollerLogo";

export const printBasicTemplate = (
  order: Order,
  doc: any,
  parcelIndex: number,
  packagesQty: number,
  productPackage: Package,
  textileName: string,
  paramValue: string,
  orderIndex: string
) => {
  const product = order.product;
  const kw = moment(order.deliveryAt, "YYYYMMDD").isoWeek();

  // Header
  // Nazwa produktu
  doc.setFont("IBMPlexSans-Bold", "normal", "normal");
  doc.setFontSize(50);
  doc.text(product?.name.toUpperCase(), 15, 30);
  // Roller Logo
  doc.addImage(rollerLogo, "PNG", 80, 7, 80, 40);

  // Body
  // Material
  doc.setFontSize(22);
  doc.text(`${product?.name} ${paramValue}`, 10, 70);
  // Parametry
  doc.text(textileName, 10, 80);

  // Footer
  // Paczka
  doc.setFont("IBMPlexSans-Bold", "normal", "normal");
  doc.setFontSize(24);
  doc.text(`CZESC: ${orderIndex}`, 10, 100);
  doc.text(`/ ${parcelIndex + 1} z ${packagesQty}`, 60, 100);
  // Tydzien dostawy
  doc.text(`KW ${kw}`, 120, 100);

  doc.addPage();
};
