import { deleteOrder as deleteItem } from "../../../api";

export const useDeleteOrder = (
  refetch: () => void
): {
  deleteOrder: (id: number) => void;
} => {
  const deleteOrder = (id: number) => {
    deleteItem(id)
      .then(() => {
        refetch();
      })
      .catch((e: any) => console.error(e));
  };

  return { deleteOrder };
};
