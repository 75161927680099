import React, { useState } from "react";
import Select from "react-select";
import { useField } from "formik";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

type InputSelectProps = {
  label: string;
  error?: string;
  name: string;
  isForcedError?: boolean;
} & StateManagerProps;
export const InputSelect = ({
  label,
  error,
  name,
  isForcedError,
  ...props
}: InputSelectProps) => {
  const [_, meta, { setTouched }] = useField(name);
  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setTouched(true);
    setFocused(true);
  };
  const onBlur = () => setFocused(false);

  const isError = isForcedError
    ? error
    : meta.touched && !props.isDisabled && !focused && (error || meta.error);

  return (
    <div>
      <label
        htmlFor="orderType"
        className="block text-xs font-medium text-gray-400 pb-2 pt-4"
      >
        {label}
      </label>
      <div className="relative">
        <Select
          {...props}
          onFocus={onFocus}
          onBlur={onBlur}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: isError ? "#EF4444" : "grey-100",
              "&:hover": {
                borderColor: isError ? "#EF4444" : "grey",
              },
            }),
          }}
        />
        <div className="text-sm font-bold text-red-500">
          {isError && <>{error || meta.error}</>}
        </div>
      </div>
    </div>
  );
};
