import { Layout } from "../../layout/Layout";
import { useParams } from "react-router-dom";
import { OrderDetailForm } from "../orders/components/OrderOverview/OrderDetailForm";
import { useUserStore } from "../../stores/useUserStore";
import { UserRoleNames } from "../../types/User";
import { CustomerOrderDetailView } from "../orders/components/OrderOverview/CustomerOrderDetailView";

export const OrderDetails = () => {
  const { id } = useParams();
  const { role } = useUserStore();

  return (
    <Layout>
      {id && role.includes(UserRoleNames.CUSTOMER) ? (
        <CustomerOrderDetailView id={Number(id)} />
      ) : (
        <OrderDetailForm id={Number(id)} />
      )}
    </Layout>
  );
};
