import { User } from "../types";

export const USER_MOCK: User = {
  password: undefined,
  email: "",
  name: "",
  textiles: [{ textile_id: 1, price_group: 1 }],
  orders_types: [],
  ordersTypes: [],
  labelTemplateId: 0,
  labelTemplate: ""
};

export const createUserMock = (user?: User) => ({ ...USER_MOCK, ...user });
