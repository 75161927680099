import React from "react";
import { Layout } from "../../../layout/Layout";
import { CollectionContextProvider } from "../contexts/CollectionContext";
import { CollectionTable } from "./CollectionTable";
import { AddCollectionButton } from "./CollectionSidebar/AddCollectionButton";
import { Sidebar } from "./CollectionSidebar/Sidebar";
import { ProductVariantContextProvider } from "../contexts/ProductVariantContext";

export const Collections = () => {
  return (
    <CollectionContextProvider>
      <ProductVariantContextProvider>
        <Layout>
          <Sidebar />
          <div className="flex justify-between	">
            <div>
              <h1 className="text-4xl font-semibold">Kolekcje</h1>
              <h2 className="pt-2 text-md text-gray-500 mb-6">
                Lista dodanych kolekcji
              </h2>
            </div>
            <AddCollectionButton />
          </div>

          <CollectionTable />
        </Layout>
      </ProductVariantContextProvider>
    </CollectionContextProvider>
  );
};
