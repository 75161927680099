import { useEffect, useState } from "react";
import { getOrders, getOrdersByStatus } from "../../../api";
import { Order } from "../../../types/Order";
import { useUserStore } from "../../../stores/useUserStore";
import { UserRoleNames } from "../../../types/User";
import { useUsers } from "../../users/api/useUsers";
import { useUsersStore } from "../../../stores/useUsersStore";

export const useOrders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [nextPage, setNextPage] = useState<string>("");
  const [itemLenght, setItemLength] = useState<number>(0);
  const { users } = useUsersStore();
  const { role, id } = useUserStore();

  useEffect(() => {
    const orderWithLabelTemplate = orders.map((order: Order) => ({
      ...order,
      labelTemplate: users.find((user) => user.id === order.userId)
        ?.labelTemplate,
    }));

    setOrders(orderWithLabelTemplate);
  }, [orders.length]);

  const fetch = (statusId?: number) => {
    statusId
      ? getOrdersByStatus(statusId)
          .then((res: any) => {
            setOrders(
              role.includes(UserRoleNames.CUSTOMER)
                ? res.data.filter((item: Order) => item.userId === id)
                : res.data
            );
            setNextPage(res.next_page_url);
            setItemLength(res.total);
          })
          .catch((e: any) => console.error(e))
      : getOrders()
          .then((res: any) => {
            setOrders(
              role.includes(UserRoleNames.CUSTOMER)
                ? res.data.filter((item: Order) => item.userId === id)
                : res.data
            );
            setNextPage(res.next_page_url);
            setItemLength(res.total);
          })
          .catch((e) => console.error(e));
  };

  useEffect(() => {
    fetch();
  }, []);

  return { orders, refetch: fetch, nextPage, itemLenght };
};
