import React, { createContext, ReactNode, useState } from "react";
import { User } from "../../../types";
import { USER_MOCK } from "../../../mocks/User.mock";
import { useUsers } from "../api/useUsers";

export const UserContext = createContext<{
  users: User[];
  refetch: () => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  editUser: User;
  setEditUser: (user: User) => void;
}>({
  users: [],
  refetch: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: (isSidebarOpen) => {},
  editUser: USER_MOCK,
  setEditUser: (user) => {},
});

type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { users, refetch } = useUsers();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [editUser, setEditUser] = useState<User>(USER_MOCK);

  return (
    <UserContext.Provider
      value={{
        users,
        setIsSidebarOpen,
        isSidebarOpen,
        refetch,
        editUser,
        setEditUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
