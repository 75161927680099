import { useEffect, useState } from "react";
import { getProducts } from "../../../api";
import { Product } from "../../../types/Product";

export const useProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const fetch = () =>
    getProducts()
      .then((res: Product[]) => setProducts(res))
      .catch((e) => console.error(e));

  useEffect(() => {
    fetch();
  }, []);

  return { products, refetch: fetch };
};
