import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useOrders } from "../api/useOrders";
import { Order } from "../../../types/Order";

export const OrdersContext = createContext<{
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  isMercuryModalOpen: boolean;
  setIsMercuryModalOpen: (isMercuryModalOpen: boolean) => void;
  isCollectionModalOpen: boolean;
  setIsCollectionModalOpen: (isCollectionModalOpen: boolean) => void;
  isEditSidebarOpen: boolean;
  setIsEditSidebarOpen: (isSidebarOpen: boolean) => void;
  editOrderId?: number;
  setEditOrderId: (editOrderId: number | undefined) => void;
  selectedOrders: Order[];
  setSelectedOrders: (orders: Order[]) => void;
  refetch: (statusId?: number) => void;
  orders: Order[];
  setNextUrl: (url: string) => void;
  nextUrl: string;
  setItemCount: (count: number) => void;
  itemCount: number;
}>({
  isSidebarOpen: false,
  setIsSidebarOpen: (isSidebarOpen) => {},
  isMercuryModalOpen: false,
  setIsMercuryModalOpen: (isMercuryModalOpen) => {},
  isCollectionModalOpen: false,
  setIsCollectionModalOpen: (isCollectionModalOpen) => {},
  isEditSidebarOpen: false,
  setIsEditSidebarOpen: (isSidebarOpen) => {},
  setEditOrderId: (isSidebarOpen) => {},
  selectedOrders: [],
  setSelectedOrders: (orders: Order[]) => {},
  refetch: (statusId?: number) => {},
  orders: [],
  setNextUrl: (url: string) => {},
  nextUrl: "",
  setItemCount: (count: number) => {},
  itemCount: 0,
});

type OrdersContextProviderProps = {
  children: ReactNode;
};

export const OrdersContextProvider = ({
  children,
}: OrdersContextProviderProps) => {
  const { orders, refetch, nextPage, itemLenght } = useOrders();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [isEditSidebarOpen, setIsEditSidebarOpen] = useState(false);
  const [editOrderId, setEditOrderId] = useState<number>();
  const [itemCount, setItemCount] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState("");
  const [isMercuryModalOpen, setIsMercuryModalOpen] = useState(false);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  useEffect(() => {
    setNextUrl(nextPage);
    setItemCount(itemLenght);
  }, []);

  return (
    <OrdersContext.Provider
      value={{
        isCollectionModalOpen,
        setIsCollectionModalOpen,
        isMercuryModalOpen,
        setIsMercuryModalOpen,
        isSidebarOpen,
        setIsSidebarOpen,
        selectedOrders,
        setSelectedOrders,
        orders,
        refetch,
        isEditSidebarOpen,
        setEditOrderId,
        setIsEditSidebarOpen,
        editOrderId,
        nextUrl,
        setNextUrl,
        itemCount,
        setItemCount,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
