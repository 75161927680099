import { useStatuses } from "./useStatuses";
import { useStatusOptionsStore } from "../../../stores/useStatusOptionsStore";
import { StatusOption } from "../../../types";

export const useStatusOptions = () => {
  const { statuses } = useStatuses();

  const statusOptions: StatusOption[] | undefined = statuses?.map((status) => ({
    value: status.id,
    label: status.name,
    color: status.color,
  }));

  statusOptions && useStatusOptionsStore.setState({ options: statusOptions });
};
