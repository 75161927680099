import React, { Dispatch } from "react";
import { HiCheck, HiOutlineXMark, HiXMark } from "react-icons/hi2";
import { FormikProps } from "formik";
import { DropdownList } from "../../components/DropdownList";
import { Button } from "../../components/Button";
import { PriceGroup } from "../../types/PriceGroup";
import { Tooltip } from "react-tooltip";
import { ImageUpload } from "../../components/ImageUpload";
import { resizeImage } from "../../utils/resizeImage";

type SidebarType = {
  formik: FormikProps<any>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<boolean>;
  priceGroups: PriceGroup[];
  images: any;
  setImages: Dispatch<any>;
  selectedPriceGroup: any;
  setSelectedPriceGroup: Dispatch<any>;
  isEditingSidebarOpen: boolean;
  setIsEditingSidebarOpen: Dispatch<boolean>;
  hasBeenTouched: { name: boolean; manufacturer: boolean };
  setHasBeenTouched: Dispatch<{ name: boolean; manufacturer: boolean }>;
};

export function Sidebar({
  formik,
  isSidebarOpen,
  setIsSidebarOpen,
  priceGroups,
  images,
  setImages,
  selectedPriceGroup,
  setSelectedPriceGroup,
  isEditingSidebarOpen,
  setIsEditingSidebarOpen,
  setHasBeenTouched,
  hasBeenTouched,
}: SidebarType) {
  const handleCloseBtn = () => {
    setIsSidebarOpen(false);
    setTimeout(() => {
      setIsEditingSidebarOpen(false);
      setHasBeenTouched({ name: false, manufacturer: false });
      setImages([]);
      formik.resetForm();
    }, 1000);
  };

  const onImageChange = (imageList: any) => {
    !!imageList.length
      ? resizeImage(imageList[0].data_url).then((res) => {
          setImages(
            imageList.map((image: any) => ({
              ...image,
              data_url: res,
            }))
          );
        })
      : setImages([]);
    formik.setFieldValue("image", imageList);
  };
  const handleChange = (e: any) => {
    formik.handleChange(e);
    if (e.target.name === "name") {
      setHasBeenTouched({ ...hasBeenTouched, name: true });
    }
    if (e.target.name === "manufacturer") {
      setHasBeenTouched({ ...hasBeenTouched, manufacturer: true });
    }
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={`transition ease-out delay-500 fixed w-[30%] h-screen overflow-y-auto bg-white border-l top-0 z-[999] right-0 shadow-2xl ${
            isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
          }`}
        >
          <div className="p-10 pl-9">
            <div>
              <HiOutlineXMark
                className="h-8 w-8 text-gray-500 cursor-pointer rounded-lg p-1 bg-white hover:bg-gray-100"
                onClick={handleCloseBtn}
              />
            </div>
            <div className="pt-5 pl-1">
              <div className="grid grid-cols-2">
                <div className="col-span-2 mb-6">
                  <h1 className="text-3xl font-semibold mb-1">
                    {isEditingSidebarOpen
                      ? "Edytuj dodaną tkaninę"
                      : "Dodaj nową tkaninę"}
                  </h1>
                  <p className="text-gray-400">
                    {!isEditingSidebarOpen
                      ? "Dodaj informacje, aby utworzyć nową tkaninę"
                      : null}
                  </p>
                </div>
                <div className="col-span-2 mb-4 relative">
                  <label
                    htmlFor="description"
                    className="block text-xs font-medium text-gray-400 pb-2"
                  >
                    Nazwa
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={formik.values.name}
                    placeholder="Nazwa tkaniny"
                    className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600"
                  />
                  <div className="absolute right-2 bottom-2">
                    {isEditingSidebarOpen ? (
                      hasBeenTouched.name ? (
                        formik.errors.name ? (
                          <>
                            <HiXMark
                              className="h-6 w-6 text-rose-600"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={formik.errors.name}
                            />
                            <Tooltip id="my-tooltip" />
                          </>
                        ) : (
                          <HiCheck className="h-6 w-6 text-emerald-700" />
                        )
                      ) : (
                        <HiCheck className="h-6 w-6 text-emerald-700" />
                      )
                    ) : formik.errors.name ? (
                      <>
                        <HiXMark
                          className="h-6 w-6 text-rose-600"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={formik.errors.name}
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    ) : (
                      <HiCheck className="h-6 w-6 text-emerald-700" />
                    )}
                  </div>
                </div>
                <div className="col-span-1 mr-2 mb-4 relative">
                  <label
                    htmlFor="description"
                    className="block text-xs font-medium text-gray-400 pb-2"
                  >
                    Producent
                  </label>
                  <input
                    type="text"
                    name="manufacturer"
                    onChange={handleChange}
                    value={formik.values.manufacturer}
                    placeholder="Producent"
                    className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-lg shadow-sm outline-none appearance-none focus:border-indigo-600"
                  />
                  <div className="absolute right-2 bottom-2">
                    {isEditingSidebarOpen ? (
                      hasBeenTouched.manufacturer ? (
                        formik.errors.manufacturer ? (
                          <>
                            <HiXMark
                              className="h-6 w-6 text-rose-600"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={formik.errors.manufacturer}
                            />
                            <Tooltip id="my-tooltip" />
                          </>
                        ) : (
                          <HiCheck className="h-6 w-6 text-emerald-700" />
                        )
                      ) : (
                        <HiCheck className="h-6 w-6 text-emerald-700" />
                      )
                    ) : formik.errors.manufacturer ? (
                      <>
                        <HiXMark
                          className="h-6 w-6 text-rose-600"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={formik.errors.manufacturer}
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    ) : (
                      <HiCheck className="h-6 w-6 text-emerald-700" />
                    )}
                  </div>
                </div>
                <div className="col-span-1 ml-2">
                  <DropdownList
                    formik={formik}
                    pricegroups={true}
                    name="priceGroup"
                    options={priceGroups}
                    selected={selectedPriceGroup}
                    setSelected={setSelectedPriceGroup}
                    label="Grupa cenowa"
                    categories={false}
                  />
                </div>
                <label
                  htmlFor="description"
                  className="col-span-2 block text-xs font-medium text-gray-400 pb-2"
                >
                  Zdjęcie tkaniny
                </label>
                <div className="col-span-2 grid grid-cols-2">
                  <ImageUpload
                    formik={formik}
                    images={images}
                    onImageChange={onImageChange}
                  />
                </div>
                <div className="col-span-2 mt-4">
                  <Button type="submit" label="Zapisz" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
